import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

// import ITEMS_DATA from './report_with_vendor.json';
// import ITEMS_DATA from './gl-secret-detection-report.json';
import ITEMS_DATA from './report.json';

export default function SastTool(props) {
    let items = ITEMS_DATA.vulnerabilities;
    items = items.filter(x => (x?.name == undefined || x?.name.indexOf('An error occurred during proc') == -1));
    items = items.filter(x => (x?.name == undefined || x?.name.indexOf('No file extension has been fo') == -1));
    items = items.slice(0, 300);

    return (
        <Wrapper>

            {/*<h1>*/}
            {/*    SAST Report*/}
            {/*</h1>*/}

            <h1>
                {/*Secret Detection Report*/}
                Configuration Errors Report
            </h1>



            {items.map((a, i) => {
                let identItems = a?.identifiers || [];
                return (
                    <Item key={a.id}>
                        <NameSection>
                            <h3>
                                Name
                            </h3>
                            {a.name}
                        </NameSection>
                        <NameSection>
                            <h3>
                                Message
                            </h3>
                            {a.message}
                        </NameSection>
                        <NameSection>
                            <h3>
                                Description
                            </h3>
                            {a.description}
                        </NameSection>
                        <div>
                            <h4>
                                Location
                            </h4>
                            <p>
                                File: {a.location.file}
                                <br/>
                                Line: {a.location.start_line}
                            </p>
                        </div>

                        <div>
                            <h4>
                                Scanner
                            </h4>
                            <p>
                                ID: {a.scanner?.id}
                                <br/>
                                Name: {a?.scanner?.name}
                            </p>
                        </div>

                        {identItems.length == 0 ? null :
                            <div>
                                <h4>
                                    Identifiers
                                </h4>
                                {identItems.map((b, j) => {
                                    return (
                                        <div key={j} style={{padding: 15}} >
                                            <p>
                                                Type: {b.type}
                                                <br/>
                                                Name: {b.name}
                                                <br/>
                                                Value: {b.value}
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>
                        }



                    </Item>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Item = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const NameSection = styled.div`
  
  
`;