import { useState, useEffect } from "react";
import styled from "styled-components";
import { Code } from "react-content-loader";
import DoctorAPI from "../../../api/DoctorAPI";
import SimpleBpmSlotsChart from "../../heart_rate/tools/SimpleBpmSlotsChart";

export default function UserWeeklyRecoveryPanel(props) {
  // Legacy
  const { uuid, t } = props;
  console.log("UserWeeklyRecoveryPanel: render: uuid, t = ", uuid, t);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setLoading(true);
    DoctorAPI.getUserWeekRecoveries(uuid, t).then((x) => {
      let xItems = x.map((x) => ({
        ...x,
        recovery:
          x == undefined || x.recovery == undefined
            ? undefined
            : +x.recovery.toFixed(0),
      }));
      console.log("xItems = ", xItems);
      setLoading(false);
      setItems(xItems);
    });
  }, [t, uuid]);

  if (loading == true) {
    return <Code />;
  }

  console.log("UserWeeklyRecoveryPanel: items = ", items);

  return (
    <Wrapper>
      <ComplianceHeading>Weekly recovery</ComplianceHeading>
      <ChartPlaceholder>
        <SimpleBpmSlotsChart slots={items} dataKey={"recovery"} />
      </ChartPlaceholder>
    </Wrapper>
  );
}

const ComplianceHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 30px;
`;

const ChartPlaceholder = styled.div`
  height: 300px;
  width: 100%;
`;

const Wrapper = styled.div``;
