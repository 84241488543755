import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
  Fragment,
} from "react";
import ls from "local-storage";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import DoctorAPI from "../../../api/DoctorAPI";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import BpSquareTool, { AREAS } from "../tools/BpSquareTool";

import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ComposedChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import DrawHelper from "../../../helpers/DrawHelper";
import axios from "axios";
import { download } from "../../spo2/tools/PatientDaySpo2Tool";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let dd = payload[0]?.payload || {};
    let bp = dd["blood pressure"];
    let is_init = dd.is_init;
    let tLabel = dd.recorded_t;
    let hr = dd["heart rate"];
    if (tLabel == "Invalid date") {
      tLabel = ``;
    }
    return (
      <TooltipWrapper>
        {tLabel == "" ? null : (
          <>
            <p>
              <b>{tLabel}</b>
            </p>
            <p>
              {bp == undefined ? null : `Blood pressure: ${bp[0]} - ${bp[1]}`}
            </p>
            <p>{hr == undefined ? null : `Pulse rate: ${hr}`}</p>
            <p>{is_init == undefined ? null : `Init: ${is_init}`}</p>
          </>
        )}
      </TooltipWrapper>
    );
  }

  return null;
};

export default function DayBpAnalysisPanel(props) {
  // getRRIntervalsForDay
  const { uuid, dayTimestamp, isDebugMode } = props;

  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [inits, setInits] = useState([]);
  const [loadingId, setLoadingId] = useState(undefined);
  const [showAll, setShowAll] = useState(false);
  const theme = ls.get("theme");

  useEffect(() => {
    setLoading(true);
    // RawAPI.getInstance().getRRIntervalsForDay(dayTimestamp).then(pld => {
    //     console.log('raw pld = ', pld);
    //     setLoading(false);
    //     setData(pld);
    // })
    // DoctorAPI.getPatientsRawBPSpotsForDay(uuid, dayTimestamp).then(pld => {
    DoctorAPI.getAIBpInitDataMeasurements(uuid).then((arr) => {
      // console.log("setInits getAIBpInitDataMeasurements", arr);
      setInits(arr);
    });
    DoctorAPI.getPatientsRawBPSpotsEnhancedFromZero(uuid, dayTimestamp).then(
      (pld) => {
        // console.log("getPatientsRawBPSpotsEnhancedForDay: raw pld = ", pld);
        setLoading(false);
        setData(pld);

        // DoctorAPI.getPatientsRawBPSpotsEnhancedForDay(uuid, dayTimestamp).then(pld1 => {
        //     console.log('getPatientsRawBPSpotsEnhancedForDay: pld1 = ', pld1);
        // });
      }
    );
  }, [dayTimestamp]);

  if (loading == true) {
    return <Code />;
  }

  if (data == undefined) {
    return <div>no data to display</div>;
  }

  if (data.length === 0) {
    return (
      <SimpleNoDataMessageTool
        loading={loading}
        message={"Sorry, no cuff measurements were found in the history."}
      />
    );
  }

  let enhData = DrawHelper.getBpMonotonePoints(
    data.map((xx) => ({ ...xx, t: xx.timestamp })),
    +dayTimestamp
  );
  let xData = enhData.map((x) => {
    return {
      ...x,
      ["blood pressure"]: [x.dbp, x.sbp],
      t: x.t,
      recorded_t: moment(+x.recorded_t).format("HH:mm:ss"),
      ["heart rate"]: x.hrm,
    };
  });

  let bpSpotsGrouped;
  let groupedByHour;
  let groupedBySession;
  let sessions;

  const sessionIDs = data
    .filter((d) => d.session_code)
    .map((d) => d.session_code);

  const uniqueSessions = new Set(sessionIDs);
  let sessionList = [];
  for (let sessionID of uniqueSessions) {
    sessionList.push(sessionID);
  }

  sessionList = sessionList
    .sort((a, b) => {
      const cuffA = data.filter((d) => d.session_code === a)[0];
      const cuffB = data.filter((d) => d.session_code === b)[0];
      const tsA = cuffA.timestamp;
      const tsB = cuffB.timestamp;
      return tsA - tsB;
    })
    .slice(sessionList.length - 20, sessionList.length);

  if (data !== undefined) {
    let grouped = data.map((d) => {
      let range = mapSbpToArea(d.sbp);
      return {
        ...d,
        recordedHour: moment().diff(moment(d.timestamp), "hours"),
        level: range.name,
        color: range.color,
      };
    });
    groupedByHour = grouped.reduce((acc, obj) => {
      if (!acc[obj.recordedHour]) {
        acc[obj.recordedHour] = { cuffs: [] };
      }
      acc[obj.recordedHour].cuffs.push(obj);
      return acc;
    }, {});

    for (let recordedHour in groupedByHour) {
      let cuffs = groupedByHour[recordedHour].cuffs;
      let _sbp = cuffs.map((c) => c.sbp);
      let _dbp = cuffs.map((c) => c.dbp);
      let _hrm = cuffs.map((c) => c.hrm);
      let _sbpAvg = _sbp.reduce((p, c) => p + c, 0) / _sbp.length;
      let _dbpAvg = _dbp.reduce((p, c) => p + c, 0) / _dbp.length;
      let _hrmAvg = _hrm.reduce((p, c) => p + c, 0) / _hrm.length;

      let range = mapSbpToArea(parseInt(_sbpAvg))[0];

      groupedByHour[recordedHour].avgSbp = _sbpAvg.toFixed();
      groupedByHour[recordedHour].avgDbp = _dbpAvg.toFixed();
      groupedByHour[recordedHour].avgHrm = _hrmAvg.toFixed();
      groupedByHour[recordedHour].color = range.color;
      groupedByHour[recordedHour].level = range.name;
      groupedByHour[recordedHour].firstCuffTime = cuffs[0].timestamp;
    }
    bpSpotsGrouped = grouped;
  } else {
    bpSpotsGrouped = [];
  }

  return (
    <Wrapper>
      <div
        className="normal-text"
        style={{
          marginLeft: "2rem",
          marginBottom: "20px",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          columnGap: "15px",
        }}
      >
        {sessionList.map((sID, j) => {
          let sessionCuffs = data.filter((d) => d.session_code === sID);
          const _raw_res = sessionCuffs.map((c) => {
            const client_timestamp = c.client_timestamp;

            const raw_res = inits.find(
              (x) => x.client_start_timestamp === client_timestamp
            )?._raw_response;
            if (!raw_res) {
              return {};
            }
            let parsed_res = raw_res;
            if (typeof raw_res === "string") {
              try {
                parsed_res = JSON.parse(parsed_res);
              } catch (e) {
                console.error("Invalid JSON string:", raw_res);
              }
            }
            // console.log("raw_res", parsed_res);
            return {
              success: parsed_res.Success,
              message: parsed_res.Message,
            };
          });

          const goodNum = _raw_res.filter((x) => x.success).length;
          const badNum = _raw_res.filter((x) => !x.success).length;

          // console.log("_raw_res", _raw_res, `${goodNum}-${badNum}`);

          const _sbp = sessionCuffs.map((c) => c.sbp);
          const _dbp = sessionCuffs.map((c) => c.dbp);
          const _hrm = sessionCuffs.map((c) => c.hrm);
          const _sbpAvg = (
            _sbp.reduce((p, c) => p + c, 0) / _sbp.length
          ).toFixed(0);
          const _dbpAvg = (
            _dbp.reduce((p, c) => p + c, 0) / _dbp.length
          ).toFixed(0);
          const _hrmAvg = (
            _hrm.reduce((p, c) => p + c, 0) / _hrm.length
          ).toFixed(0);
          const range = mapSbpToArea(_sbpAvg)[0];
          const { color, name } = range;
          const timestamp = sessionCuffs[0].timestamp;

          return (
            <div
              key={j}
              style={{
                display: "grid",
                justifyContent: "center",
                gridTemplateColumns: "auto auto auto",
                alignContent: "center",
                placeContent: "start",
                marginBlock: "2px",
              }}
            >
              <div
                style={{
                  width: "220px",
                }}
              >
                {moment(timestamp).format("ddd, DD MMM YYYY, HH:mm")}
                {showAll ? (
                  <>
                    <BPSpan style={{ color: "red", fontWeight: 700 }}>
                      {badNum > 0 ? `Failed` : ""}
                    </BPSpan>
                  </>
                ) : null}
              </div>
              <BPLevelDot color={color}>{name}</BPLevelDot>
              <div
                style={{
                  width: "200px",
                }}
              >
                <BPSpan>{`${_sbpAvg}`}</BPSpan>
                <span style={{ opacity: "50%" }}>SYS</span>
                <BPSpan>{`${_dbpAvg}`}</BPSpan>
                <span style={{ opacity: "50%" }}>DIA</span>
                <BPSpan>{`${_hrmAvg}`}</BPSpan>
                <span style={{ opacity: "50%" }}>HR</span>
              </div>
              {showAll
                ? sessionCuffs.map((c, _i) => {
                    let { sbp, map, dbp, hrm, timestamp } = c;
                    let range = mapSbpToArea(sbp)[0];

                    return (
                      <Fragment key={_i}>
                        <div
                          key={_i}
                          style={{
                            // display: "inline-flex",
                            fontSize: "12px",
                          }}
                        >
                          ({moment(timestamp).format("HH:mm")})
                          <span style={{ color: range.color }}>
                            {` ${range.name} `}
                          </span>
                          {`S: ${sbp}; `}
                          {`D: ${dbp}; `}
                          {`Hr: ${hrm}`}
                        </div>
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {_raw_res[_i].success
                            ? ""
                            : `Failed: Bad PPG quality`}
                        </div>
                        <div></div>
                      </Fragment>
                    );
                  })
                : null}
            </div>
          );
        })}
        {
          !Object.keys(groupedByHour)
            .sort((a, b) => b - a)
            .slice(
              Object.keys(groupedByHour).length - 20,
              Object.keys(groupedByHour).length
            )
            .map((keyName, j) => {
              // .map((x, i) => {
              let x = groupedByHour[keyName];
              let timestamp = x.firstCuffTime;
              let level = x?.level;
              let color = x.color;
              let { avgDbp, avgSbp, avgHrm } = x;
              let cuffs = x.cuffs;

              return (
                <div
                  key={j}
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    gridTemplateColumns: "auto auto auto",
                    alignContent: "center",
                    placeContent: "start",
                    marginBlock: "2px",
                  }}
                  // onClick={}
                >
                  <div
                    style={{
                      width: "220px",
                    }}
                  >
                    {moment(timestamp).format("ddd, DD MMM YYYY, HH:mm")}
                  </div>
                  <BPLevelDot color={color}>{level}</BPLevelDot>
                  <div
                    style={{
                      width: "200px",
                    }}
                  >
                    <BPSpan>{`${avgSbp}`}</BPSpan>
                    <span style={{ opacity: "50%" }}>SYS</span>
                    <BPSpan>{`${avgDbp}`}</BPSpan>
                    <span style={{ opacity: "50%" }}>DIA</span>
                    <BPSpan>{`${avgHrm}`}</BPSpan>
                    <span style={{ opacity: "50%" }}>HR</span>
                  </div>
                  {showAll
                    ? cuffs.map((c, _i) => {
                        let { sbp, map, dbp, hrm, timestamp } = c;
                        let range = mapSbpToArea(sbp)[0];

                        return (
                          <Fragment key={_i}>
                            <div
                              key={_i}
                              style={{
                                // display: "inline-flex",
                                fontSize: "12px",
                              }}
                            >
                              ({moment(timestamp).format("HH:mm")})
                              <span style={{ color: range.color }}>
                                {` ${range.name} `}
                              </span>
                              {`S: ${sbp}; `}
                              {`D: ${dbp}; `}
                              {`Hr: ${hrm}`}
                            </div>
                            <div></div>
                            <div></div>
                          </Fragment>
                        );
                      })
                    : null}
                </div>
              );
            })
        }
      </div>
      {!isDebugMode() || data.length === 0 ? null : (
        <div
          style={{
            fontSize: "12px",
            opacity: "50%",
            textDecoration: "underline",
            cursor: "pointer",
            position: "relative",
            left: "2rem",
            top: "-1.8em",
          }}
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          {showAll ? "Hide " : "Show "}all cuffs
        </div>
      )}

      {!isDebugMode() ? null : (
        <div>
          <ResponsiveContainer height={320}>
            <ComposedChart data={xData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="t"
                stroke={theme === "dark" ? "#ddd" : ""}
                tickFormatter={(a) => {
                  return moment(a).format("HH:mm");
                }}
              />
              <YAxis />
              <Tooltip
                content={<CustomTooltip />}
                labelFormatter={(t) => moment(t).format("HH:mm")}
              />
              <Legend />
              <Bar
                name="Blood Pressure"
                dataKey="blood pressure"
                fill="#8884d8"
                onClick={async (a, b, c) => {
                  let xId = a?._id;
                  // window.alert(xId);
                  setLoadingId(xId);
                  let pld = (
                    await axios.get(
                      `https://api.study-integration.corsano.com/ds/user/${uuid}/blood-pressure-spots/${xId}/ppg`
                    )
                  ).data;
                  setLoadingId(undefined);
                  let txt = JSON.stringify(pld);
                  download(
                    `${moment(a.t).format(
                      "DD_MM_YYYY_HH_mm_ss"
                    )}_${xId}_raw_ppg2.json`,
                    txt
                  );
                }}
              />
              <Line
                name="Heart Rate"
                type="monotone"
                dataKey="heart rate"
                stroke="#ff7300"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )}
    </Wrapper>
  );
}

function mapSbpToArea(sbp) {
  let range = AREAS.filter((a) => sbp < a.s && sbp >= a.s_from);

  if (sbp >= 200) {
    range = [AREAS[5]];
  }
  if (sbp < 0) {
    return [AREAS[0]];
  }
  return range;
}

const TooltipWrapper = styled.div`
  background: white;
  padding: 5px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
`;

const Wrapper = styled.div`
  margin-top: 10px;
`;

const BPSpan = styled.span`
  margin-left: 0.5em;
  margin-right: 0.1em;
  opaque: 50%;
  font-weight: 500;
`;

const BPLevelDot = styled.div`
  width: 180px;
  color: ${(props) => props.color};
  // margin-left: 1em;
  &:before {
    margin-right: 0.4em;
    height: 10px;
    width: 10px;
    background: ${(props) => props.color};
    content: " ";
    border-radius: 50%;
    display: inline-block;
  }
`;
