import QRCode from "react-qr-code";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { toast } from "react-hot-toast";

export default function SpotMeasurementsTool(props) {
  return <BottomValue>Inputs</BottomValue>;
}

const BottomValue = styled.span`
  font-size: 14px;
  line-height: 32px;
  font-weight: bold;
  color: #8798ad;
  cursor: pointer;
  //   text-decoration: underline;
`;
