import { useState, useEffect } from "react";
import styled from "styled-components";
import DictionaryEditorTool from "../tools/DictionaryEditorTool";
import DictionariesAPI from "../../../api/DictionariesAPI";
import Tabs, { TabItem } from "../../ui/Tabs";
import Select from "react-select";
import ApiKeyTool from "../tools/ApiKeyTool";
import UserDisplayTool from "../tools/UserDisplayTool";
import ComplianceAlertsPanel from "../../alarms/panels/ComplianceAlertsPanel";
import MfaTool from "../../2fa/tools/MfaTool";
import NameEditorTool from "../tools/NameEditorTool";
import MyHcpsTool from "../../hcp/tools/MyHcpsTool";
import MyHcpAdminTool from "../../hcp/tools/MyHcpAdminTool";

const PUSH_OPTIONS = [
  {
    label: "Enabled",
    value: true,
  },
  {
    label: "Disabled",
    value: false,
  },
];

export default function DoctorSettingsPanel(props) {
  const { uuid, isHCPAdmin } = props;

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const [pushEnabled, setPushEnabled] = useState(true);

  useEffect(() => {
    if (uuid == undefined) {
      return;
    }
    setLoading(true);
    DictionariesAPI.getUserItems(uuid).then((x) => {
      setItems(x);
      setLoading(false);
    });
  }, [uuid]);

  return (
    <Wrapper>
      <Tabs
        defaultTabIndex={0}
        isHCPAdmin={isHCPAdmin}
        tabs={[
          window.location.href.indexOf("portal") == -1
            ? undefined
            : {
                label: "HCP",
                content: (
                  <TabItem>
                    <ContentItem>
                      {isHCPAdmin ? (
                        <MyHcpsTool />
                      ) : (
                        <MyHcpAdminTool uuid={uuid} />
                      )}
                    </ContentItem>
                  </TabItem>
                ),
              },
          window.location.href.indexOf("portal") > -1
            ? undefined
            : {
                label: "Hospitals",
                content: (
                  <TabItem>
                    <ContentItem>
                      <DictionaryEditorTool
                        loading={loading}
                        heading={"Hospitals"}
                        items={items
                          .filter((x) => x.type == "doctor_hospitals")
                          .filter((x) => x?.data?.items != undefined)
                          .reduce((arr, x) => arr.concat(x.data.items), [])}
                        addText={"Add"}
                        onChange={async (arr) => {
                          console.log(
                            "DictionaryEditorTool: onChange: arr = ",
                            arr
                          );
                          setLoading(true);
                          let d = {
                            uuid: uuid,
                            type: "doctor_hospitals",
                            data: {
                              items: arr,
                            },
                          };
                          await DictionariesAPI.saveUserItem(d);
                          let a = await DictionariesAPI.getUserItems(uuid);
                          setItems(a);
                          setLoading(false);
                        }}
                      />
                    </ContentItem>
                  </TabItem>
                ),
              },
          window.location.href.indexOf("portal") > -1
            ? undefined
            : {
                label: "Doctors",
                content: (
                  <TabItem>
                    <ContentItem>
                      <DictionaryEditorTool
                        heading={"Doctors"}
                        items={items
                          .filter((x) => x.type == "doctor_staff")
                          .filter((x) => x?.data?.items != undefined)
                          .reduce((arr, x) => arr.concat(x.data.items), [])}
                        addText={"Add"}
                        loading={loading}
                        onChange={async (arr) => {
                          setLoading(true);
                          let d = {
                            uuid: uuid,
                            type: "doctor_staff",
                            data: {
                              items: arr,
                            },
                          };
                          await DictionariesAPI.saveUserItem(d);
                          let a = await DictionariesAPI.getUserItems(uuid);
                          setItems(a);
                          setLoading(false);
                        }}
                      />
                    </ContentItem>
                  </TabItem>
                ),
              },
          window.location.href.indexOf("portal") == -1
            ? undefined
            : {
                label: "Alarms",
                content: (
                  <TabItem>
                    <ContentItem>
                      {/* <TopHeading
                    style={{ marginBottom: 20, marginTop: 0 }}
                    className="setting-top-heading"
                  >
                    Alarm Type
                  </TopHeading>
                  <UserAlarmsTool />
                  <TopHeading
                    style={{ marginBottom: 20, marginTop: 20 }}
                    className="setting-top-heading"
                  >
                    Alarm Period
                  </TopHeading>

                    <GroupsAlarmsSensitivityTool/> */}
                      {/*<UserAlarmPediodsTools />*/}

                      <DictionaryEditorTool
                        nameHeading={"Email"}
                        loading={loading}
                        hasDescription={false}
                        hasTranslations={false}
                        heading={"Alarm Emails"}
                        items={items
                          .filter((x) => x.type == "alert_emails")
                          .filter((x) => x?.data?.items != undefined)
                          .reduce((arr, x) => arr.concat(x.data.items), [])}
                        addText={"Add"}
                        onChange={async (arr) => {
                          console.log(
                            "DictionaryEditorTool: onChange: arr = ",
                            arr
                          );
                          setLoading(true);
                          let d = {
                            uuid: uuid,
                            type: "alert_emails",
                            data: {
                              items: arr,
                            },
                          };
                          await DictionariesAPI.saveUserItem(d);
                          let a = await DictionariesAPI.getUserItems(uuid);
                          setItems(a);
                          setLoading(false);
                        }}
                      />
                    </ContentItem>
                  </TabItem>
                ),
              },
          {
            label: "Compliance",
            content: (
              <TabItem>
                <ContentItem>
                  <ComplianceAlertsPanel />
                  {/*<AlertEmailForm*/}

                  {/*/>*/}
                </ContentItem>
              </TabItem>
            ),
          },
          {
            label: "Push Notifications",
            content: (
              <TabItem>
                <ContentItem>
                  <div>
                    <TopHeading
                      style={{ marginBottom: 20, marginTop: 20 }}
                      className="setting-top-heading"
                    >
                      Push Notifications
                    </TopHeading>

                    <Select
                      classNamePrefix="push-selector"
                      className="push-select"
                      value={
                        PUSH_OPTIONS.filter((x) => x.value == pushEnabled)[0]
                      }
                      options={PUSH_OPTIONS}
                      onChange={(evt) => {
                        setPushEnabled(evt.value);
                      }}
                    />
                  </div>
                </ContentItem>
              </TabItem>
            ),
          },

          {
            label: "Profile",
            content: (
              <TabItem>
                <ContentItem>
                  <div>
                    <TopHeading className="setting-top-heading">
                      HCP Portal Name
                    </TopHeading>
                    <NameEditorTool uuid={uuid} />
                    {isHCPAdmin ? (
                      <div>
                        <TopHeading className="setting-top-heading">
                          API Keys
                        </TopHeading>

                        <ApiKeyTool />
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <TopHeading className="setting-top-heading">
                      Display
                    </TopHeading>
                    <UserDisplayTool />
                  </div>
                  <div>{/* <UserAlarmsTool /> */}</div>
                </ContentItem>
              </TabItem>
            ),
          },
          {
            label: "Security",
            content: (
              <TabItem>
                <ContentItem>
                  <div style={{ marginLeft: 20, marginRight: 20 }}>
                    <TopHeading
                      style={{ marginBottom: 20, marginTop: 5 }}
                      className="setting-top-heading"
                    >
                      2FA
                    </TopHeading>

                    <MfaTool />
                  </div>
                </ContentItem>
              </TabItem>
            ),
          },
        ].filter((x) => x != undefined)}
      />
    </Wrapper>
  );
}

const TopHeading = styled.div`
  color: #000f4b;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 26px;
  line-height: 35px;
  letter-spacing: 1px;
`;

const Wrapper = styled.div``;

const Heading = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

const ContentItem = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid whitesmoke;
`;
