import { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Spin from "arui-feather/spin";
import { Input } from "../ui";
import UiHelper from "../../../helpers/UiHelper";
import CommonHelper from "../../../helpers/CommonHelper";
import checkedIcon from "../../../assets/images/check_circle.svg";

const USER_CLOUD = "https://api.users.cloud.corsano.com";

export default function BasicPasswordRecoveryForm(props) {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reseting, setReseting] = useState(false);

  const [email, setEmail] = useState("");
  const [emailNotExist, setEmailNotExist] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(
    newPassword === confirmPassword
  );

  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [successfulReset, setSuccessfulReset] = useState(false);
  const [successfulMsg, setSuccessfulMsg] = useState("");

  const [passwordValid, setPasswordValid] = useState(false);

  const [passwordRules, setPasswordRules] = useState({
    uppercase: false,
    lowercase: false,
    digit: false,
    length: false,
  });

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    setPasswordsMatch(password === confirmPassword);
    validatePassword(password, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const password = e.target.value;
    setConfirmPassword(password);
    setPasswordsMatch(password === newPassword);
    validatePassword(newPassword, confirmPassword);
  };

  const validatePassword = (password1, password2) => {
    const hasUpperCase = /[A-Z]/.test(password1);
    const hasLowerCase = /[a-z]/.test(password1);
    const hasDigit = /[0-9]/.test(password1);
    const isLongEnough = password1.length >= 8;
    const passwordsMatch = password1 === password2;

    setPasswordValid(
      hasUpperCase && hasLowerCase && hasDigit && isLongEnough && passwordsMatch
    );

    setPasswordRules({
      uppercase: hasUpperCase,
      lowercase: hasLowerCase,
      digit: hasDigit,
      length: isLongEnough,
    });
  };

  const renderIcon = (isMet) => {
    return isMet ? (
      <span style={{ color: "green" }}>✔</span>
    ) : (
      <span style={{ color: "red" }}>✘</span>
    );
  };
  return (
    <Wrapper>
      {step === 0 && (
        <InnerBox>
          <Row>
            <Label>
              Please enter your email to receive your verification code:
            </Label>
            <Input
              autoFocus={true}
              type="email"
              id="email"
              placeholder="Your email"
              value={email}
              onChange={(evt) => {
                let s = evt.target.value;
                setEmail(s.trim());
              }}
            />
          </Row>
          <Button
            //   style={{ width: "50%" }}
            disabled={loading}
            onClick={() => {
              setLoading(true);
              setEmailNotExist(false);
              axios
                .post(`${USER_CLOUD}/auth/forgot-password`, {
                  email: email,
                })
                .then(function (response) {
                  // console.log(response);
                  setCodeSent(true);
                  setLoading(false);
                  setStep(step + 1);
                })
                .catch(function (error) {
                  // console.error(error);
                  setEmailNotExist(true);
                  setLoading(false);
                });
            }}
          >
            <Spin theme={"alfa-on-color"} visible={loading} />
            {loading == true ? null : "Continue"}
            {/* Continue */}
          </Button>
          {emailNotExist ? (
            <div style={{ color: "#ee3333", marginBottom: "1rem" }}>
              We couldn't find an account associated with that email address.
            </div>
          ) : null}
          <Span
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              opacity: "60%",
              cursor: "pointer",
            }}
            onClick={() => CommonHelper.linkTo("/")}
          >
            Cancel resetting password
          </Span>
        </InnerBox>
      )}
      {step === 1 && (
        <InnerBox>
          <Row>
            <Label>One-time code from email:</Label>
            <div style={{ opacity: "70%" }}>
              Enter a different email? Return to{" "}
              <span
                style={{
                  opacity: "90%",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setStep(step - 1);
                }}
              >
                previous step
              </span>
              .
            </div>
            <Input
              // type="text"
              id="token"
              value={token}
              placeholder="6-digit code"
              autocomplete={"off"}
              onChange={(evt) => {
                setToken(evt.target.value);
              }}
            />
          </Row>
          <Row>
            <Label>New password:</Label>
            <Input
              type="password"
              id="password"
              autocomplete={"off"}
              onChange={handlePasswordChange}
              onPaste={handlePasswordChange}
            />
          </Row>
          <Row>
            <Label>Repeat new password:</Label>
            <Input
              id="password-confirm"
              type="password"
              autocomplete={"off"}
              onChange={handleConfirmPasswordChange}
              onPaste={handleConfirmPasswordChange}
            />
          </Row>
          {!passwordsMatch ? (
            <span
              style={{ opacity: "80%", fontSize: "small", color: "#cc3333" }}
            >
              Passwords don't match!
            </span>
          ) : null}
          {!passwordValid && newPassword !== "" ? (
            <>
              <div style={{ border: "none" }}>
                <span>
                  At least 8 characters: {renderIcon(passwordRules.length)}
                </span>
                <br />
                <span>
                  At least one uppercase letter:{" "}
                  {renderIcon(passwordRules.uppercase)}
                </span>
                <br />
                <span>
                  At least one lowercase letter:{" "}
                  {renderIcon(passwordRules.lowercase)}
                </span>
                <br />
                <span>
                  At least one digit: {renderIcon(passwordRules.digit)}
                </span>
                <br />
              </div>
            </>
          ) : null}

          <Button
            disabled={reseting || successfulReset}
            style={{ marginBottom: "1rem" }}
            onClick={() => {
              setReseting(true);
              setApiErrorMsg("");
              setSuccessfulMsg("");
              setSuccessfulReset(false);
              if (reseting || successfulReset) {
                return;
              }
              axios
                .post(`${USER_CLOUD}/auth/set-password`, {
                  token: token,
                  password: newPassword,
                  password_confirmation: confirmPassword,
                })
                .then((response) => {
                  setReseting(false);
                  setApiErrorMsg("");

                  console.log("reset psw success", response);
                  setSuccessfulMsg(
                    "Your password is successfully reset, you will now be directed back to the home page..."
                  );
                  setSuccessfulReset(true);
                  setStep(step + 1);
                })
                .catch((error) => {
                  setReseting(false);
                  setSuccessfulReset(false);
                  setSuccessfulMsg("");
                  console.error(error.response);
                  if (error.response.status === 422) {
                    const errorMsg = error.response.data.message;
                    const errorList = error.response.data.errors;
                    setApiErrorMsg(
                      "Invalid information! Please verify the code sent to your email, and make sure password is at least 8 characters long, including one lowercase letter, one uppercase letter, and one digit."
                    );
                    console.log("List:", errorList);

                    console.log(errorMsg);
                  }
                });
            }}
          >
            Reset password
          </Button>
          {apiErrorMsg === "" ? null : (
            <div style={{ color: "red" }}>{apiErrorMsg}</div>
          )}
          {/* {successfulReset ? (
            <div>
              <span style={{ color: "green" }}>{successfulMsg}</span>
              <div
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  opacity: "60%",
                }}
                onClick={() => {
                  CommonHelper.linkTo("/");
                }}
              >
                Back to login page
              </div>
            </div>
          ) : null} */}
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              textDecoration: "underline",
              marginTop: "1rem",
              cursor: "pointer",
              opacity: "60%",
            }}
            onClick={() => CommonHelper.linkTo("/")}
          >
            Cancel resetting password
          </div>
        </InnerBox>
      )}
      {step === 2 && (
        <InnerBox>
          <div>
            <img height={150} width={150} src={checkedIcon}></img>
          </div>
          <Label style={{ color: "green" }}>Password reset successfull!</Label>
          <Button
            onClick={() => {
              CommonHelper.linkTo("/");
            }}
          >
            Back to Login Page
          </Button>
        </InnerBox>
      )}
    </Wrapper>
  );
}

const Row = styled.div`
  margin-bottom: 10px;
  text-align: left;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 20px;
  opacity: 0.9;
`;

const InnerBox = styled.div`
  padding: 20px;
  box-sizing: border-box;
  width: 520px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 720px) {
    width: 100%;
    padding: 10px;
  }
`;

const Wrapper = styled.div`
  width: 80%;
  // height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
`;

const Span = styled.span`
  text-decoration: underline;
  cursor: pointer;
  opacity: "70%";
`;

const Button = styled.button`
  height: 56px;
  width: 100%;
  box-shadow: none !important;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background: ${(props) =>
    props.background == undefined ? "#147AFF" : props.background};
  border-radius: 8px;
  border: none !important;
  cursor: ${(props) => (props.disabled == true ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled == true ? 0.5 : 1)};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  :hover {
    opacity: ${(props) => (props.disabled == true ? 0.5 : 0.8)};
  }
`;
