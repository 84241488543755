import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import DoctorAPI from "../../../api/DoctorAPI";
import {Code} from "react-content-loader";
import LottieLoader from "../../loaders/LottieLoader";
import SurveyViewerTool from "./SurveyViewerTool";
import {Map, Set} from 'immutable'

let gVal = x => {
    if (typeof x == 'object') {
        return x.join(', ');
    }
    return x;
}

export default function PatientSurveysPanel(props) {
    const {id} = props;
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expandedSet, setExpandedSet] = useState(Set());

    useEffect(() => {
        setLoading(true);
        DoctorAPI.getPatientSurveys(id).then(arr => {
            setSurveys(arr);
            setLoading(false);
        });
    }, [id]);

    const [expandedId, setExpandedId] = useState();

    if (loading == true) {
        return (
            <Code/>
        )
    }

    console.log('render: surveys = ', surveys);

    if (surveys.length == 0) {
        return (
            <div>
                <div style={{width: '100%', height: 320, boxSizing: 'border-box'}}>
                    <LottieLoader/>
                </div>
                <div style={{textAlign: 'center', marginTop: -20}}>
                    No surveys available
                </div>
            </div>
        )
    }

    return (
        <Wrapper>

            {/*{JSON.stringify(surveys)}*/}

            <TopExportPlaceholder>
                <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {
                    let s = ``;
                    let keys = [];
                    for (let i in surveys) {
                        let d = surveys[i];
                        let {morning = {}, evening = {}} = d;
                        keys = keys.concat(Object.keys(morning || {})).concat(Object.keys(evening || {}));
                    }
                    keys = keys.reduce((st, key) => st.add(key), Set()).toArray().sort((a, b) => {
                        if (a > b) {
                            return 1;
                        }
                        if (a < b) {
                            return -1;
                        }
                        return 0;
                    });
                    s = `created_at;type;${keys.join(';')}`;
                    for (let i in surveys) {
                        let surv = surveys[i];
                        let {morning, evening, created_at, date} = surv;
                        if (morning != undefined) {
                            s = `${s}\n${created_at};morning;${keys.map(x => gVal(morning[x])).join(';')}`
                        }
                        if (evening != undefined) {
                            s = `${s}\n${created_at};evening;${keys.map(x => gVal(evening[x])).join(';')}`
                        }
                    }
                    window.open("data:text/csv;charset=utf-8," + escape(s))
                    console.log('s = ', s);
                }}>
                    Export
                </span>
            </TopExportPlaceholder>

            <Table>
                <Thead>
                    <Tr>
                        <Th>
                            Date
                        </Th>
                        <Th>
                            Time
                        </Th>
                        <Th>
                            Type
                        </Th>
                        <Th>
                            Answers
                        </Th>
                    </Tr>
                </Thead>

                <Tbody>
                    {surveys.map((a, i) => {
                        let {morning, evening, created_at, date} = a;
                        let crArr = `${created_at}`.split(' ');
                        let time = (crArr.length < 2) ? undefined : crArr[1];
                        let isExpanded = expandedSet.has(a?._id);
                        return (
                            <React.Fragment key={`${i}_${a._id}`}>
                                {morning == undefined ? null :
                                    <Tr>
                                        <Td>
                                            {date}
                                        </Td>
                                        <Td>
                                            {time}
                                        </Td>
                                        <Td>
                                            <TypeSpan>
                                                Morning
                                            </TypeSpan>
                                        </Td>
                                        <Td>
                                            {isExpanded == false ?
                                                <ExpandSpan onClick={() => {
                                                    setExpandedSet(expandedSet.add(a?._id));
                                                }}>view</ExpandSpan>
                                                :
                                                <SurveyViewerTool {...morning} />
                                            }
                                        </Td>
                                    </Tr>
                                }
                                {evening == undefined ? null :
                                    <Tr>
                                        <Td>
                                            {date}
                                        </Td>
                                        <Td>
                                            {time}
                                        </Td>
                                        <Td>
                                            <TypeSpan>
                                                Evening
                                            </TypeSpan>
                                        </Td>
                                        <Td>
                                            {isExpanded == false ?
                                                <ExpandSpan onClick={() => {
                                                    setExpandedSet(expandedSet.add(a?._id));
                                                }}>view</ExpandSpan>
                                                :
                                                <SurveyViewerTool {...evening} />
                                            }
                                        </Td>
                                    </Tr>
                                }

                            </React.Fragment>
                        )
                    })}
                </Tbody>

            </Table>

        </Wrapper>
    );
}

const ExpandSpan = styled.span`
  cursor: pointer;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`;

const TopExportPlaceholder = styled.div`
  margin-bottom: 10px;
`;

const TypeSpan = styled.span`
  font-weight: bold;
  font-size: 16px;
`;

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr`

`;

const Th = styled.th`
  border: 1px solid lightgrey;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
`;


const Thead = styled.thead`

`;

const Tbody = styled.tbody`

`;