import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import DoctorAPI from "../../../api/DoctorAPI";
import {Code} from "react-content-loader";
import {CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment/moment";

import useDimensions from "react-use-dimensions";



export default function HealthBatteryTool(props) {
    const {
        uuid,
        from,
        to,
        minutesInterval = 10
    } = props;

    const [hrvPoints, setHrvPoints] = useState([]);
    const [activityPoints, setActivityPoints] = useState([]);
    const [ref, {x, y, width, height}] = useDimensions();
    const [aggregatedPoints, setAggregatedPoints] = useState([]);

    useEffect(() => {
        if (uuid == undefined || from == undefined || to == undefined) {
            return;
        }
        setHrvPoints([]);
        setActivityPoints([]);
        DoctorAPI.getHRVPoints(uuid, from, to).then(x => {
            setHrvPoints(x);
        });
        DoctorAPI.getActivityPoints(uuid, from, to).then(x => {
            setActivityPoints(x);
        });
        DoctorAPI.getBatteryAggregatedPoints(uuid, from, to, minutesInterval).then(x => {
            setAggregatedPoints(x);
        });

    }, [uuid, from, to]);

    let batteryItems = [];
    let currentBattery = 6;
    for (let i in aggregatedPoints){
        let t = aggregatedPoints[i].t;
        let battery = currentBattery + +aggregatedPoints[i].totalDrain;
        batteryItems.push({
            t: t,
            battery: battery
        });
        currentBattery = battery;
        // console.log('--->>> batteryItems = ', batteryItems);
    }
    console.log('---<<< batteryItems = ', batteryItems);

    let realFrom = (aggregatedPoints == undefined || aggregatedPoints.length == 0) ? from : aggregatedPoints[0].t;
    let realTo = (aggregatedPoints == undefined || aggregatedPoints.length == 0) ? to : aggregatedPoints[aggregatedPoints.length - 1].t;

    console.log('realFrom, realTo = ', moment(realFrom).format('DD.MM.YYYY HH:mm:ss'), moment(realTo).format('DD.MM.YYYY HH:mm:ss'));

    return (
        <Wrapper>

            {/*{`activityPoints.length = ${activityPoints.length}`}*/}
            {/*<br/>*/}
            {/*{`hrvPoints.length = ${hrvPoints.length}`}*/}
            {/*<br/>*/}

            <h2>
                RMSSD
            </h2>

            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={hrvPoints} connectNulls={false}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="timestamp" tickCount={25}
                           domain={[realFrom, realTo]}
                           type={'number'}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}

                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'rmssd'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>

            <h2>
                SI
            </h2>

            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={hrvPoints} connectNulls={false}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="timestamp" tickCount={25}
                           domain={[realFrom, realTo]}
                           type={'number'}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}

                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'si'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>

            <h2>
                SIn
            </h2>

            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={hrvPoints} connectNulls={false}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="timestamp" tickCount={25}
                           domain={[realFrom, realTo]}
                           type={'number'}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}

                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'sin'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>

            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={activityPoints} connectNulls={false}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="timestamp" tickCount={25}
                           domain={[realFrom, realTo]}
                           type={'number'}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}

                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'steps'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>

            <H2>
                Aggregated points
            </H2>

            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={aggregatedPoints} connectNulls={false}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25}
                           domain={[realFrom, realTo]}
                           type={'timestamp'}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}

                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'steps'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>

            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={aggregatedPoints} connectNulls={false}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25}
                           domain={[realFrom, realTo]}
                           type={'timestamp'}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}

                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'sin'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>

            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={aggregatedPoints} connectNulls={false}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25}
                           domain={[realFrom, realTo]}
                           type={'timestamp'}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}

                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'timeFromEndOfTheSleep'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>


            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={aggregatedPoints} connectNulls={false}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25}
                           domain={[realFrom, realTo]}
                           type={'timestamp'}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}

                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'timeFromStartOfTheSleep'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>

            total drain:

            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={aggregatedPoints} connectNulls={false}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25}
                           domain={[realFrom, realTo]}
                           type={'timestamp'}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}

                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'totalDrain'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>

            activity drain:

            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={aggregatedPoints} connectNulls={false}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25}
                           domain={[realFrom, realTo]}
                           type={'timestamp'}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}

                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'activityDrain'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>

            stress drain:

            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={aggregatedPoints} connectNulls={false}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25}
                           domain={[realFrom, realTo]}
                           type={'timestamp'}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}

                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'stressDrain'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>


            battery:

            <ChartPlaceholder ref={ref}>

                <LineChart width={width} height={height} data={batteryItems} connectNulls={false}
                           margin={{top: 5, right: 0, left: -20, bottom: 5}}>
                    <XAxis dataKey="t" tickCount={25}
                           domain={[realFrom, realTo]}
                           type={'timestamp'}
                           tickFormatter={a => {
                               if (`${a}`.length < 8) {
                                   return a;
                               }
                               return (moment(+a).format('HH:mm'))
                           }}

                    />
                    <YAxis/>
                    <Tooltip/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey={'battery'} stroke="#D92A3E" strokeWidth={2}
                          animationDuration={1}
                          dot={false}/>
                </LineChart>

            </ChartPlaceholder>


        </Wrapper>
    );
}

const ChartPlaceholder = styled.div`
  height: 320px;
`;

const Wrapper = styled.div`

`;

const H2 = styled.h2`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
`;