import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {Map, Set} from 'immutable';
import styled from 'styled-components';

import ch_not_sel from '../../../assets/images/checkbox.svg'
import ch_sel from '../../../assets/images/chbx.svg'
import {isDebugMode} from "../../../helpers/UiHelper";

export const EXPORT_TYPES = [
    {
        label: 'PPGG',
        value: 'ppg',
        isRawData: true
    },

    {
        label: 'PPG G/R/Ir',
        value: 'ppg2',
        isRawData: true
    },


    {
        label: 'PR/BRPM/Activity',
        value: 'activity'
    },


    // {
    //     label: 'Red PPG',
    //     value: 'ppg_red'
    // },

    {
        label: 'Workout',
        value: 'workout'
    },

    {
        label: 'Respiration',
        value: 'respiration'
    },

    // {
    //     label: 'Infrared (IR) PPG',
    //     value: 'ppg_infra_red'
    // },

    {
        label: 'Sleep',
        value: 'sleep'
    },

    {
        label: 'RR intervals',
        value: 'rr'
    },
    {
        label: 'HRV',
        value: 'heart_rate_variabilities'
    },


    {
        label: 'NIBP',
        value: 'ai_measurement_blood_pressure'
    },

    {
        label: 'Temperature',
        value: 'temperature'
    },
    {
        label: 'ECG',
        value: 'ecg',
        isRawData: true
    },
    {
        label: 'BioZ',
        value: 'bioz',
        isRawData: true
    },

    // {
    //     label: 'Surveys',
    //     value: 'surveys'
    // },

    {
        label: 'ACC xyz',
        value: 'acc',
        isRawData: true
    },
    {
        label: 'Raw temperature',
        value: 'heat_flux_sensor_temperature',
        isRawData: true
    },
    {
        label: 'Raw Blood Pressure',
        value: 'blood_pressure_sensor',
        isRawData: true,
        isDebug: true
    },


    {
        label: 'Emography',
        value: 'emography'
    }
]

function renderTypes(xTypes, setSelectedSet, selectedSet, allowedTypes = []) {
    let basicAllowedTypes = ['activity_temp', 'rr_intervals_temp', 'blood_pressure_sensor'];
    return (
        <InnerWrapper>
            {xTypes.map((a, i) => {
                let isSelected = selectedSet.has(a.value);
                let isNotAllowed = (allowedTypes.length > 0 && allowedTypes.indexOf(a.value) == -1 && basicAllowedTypes.indexOf(a.value) == -1);
                return (
                    <Item key={a.value}
                          style={{
                              opacity: (isNotAllowed == true ? 0.5 : 1),
                              backgroundColor: (isNotAllowed == true ? 'lightgray' : 'white')
                    }}
                    >
                        <ChImg src={(isSelected == true ? ch_sel : ch_not_sel)}
                               onClick={() => {

                                   if (isNotAllowed == true) {
                                       window.alert('Not included in your plan. Please contact ademir@corsano.com');
                                       return;
                                   }

                                   if (isSelected == true) {
                                       setSelectedSet(selectedSet.delete(a.value));
                                   } else {
                                       setSelectedSet(selectedSet.add(a.value));
                                   }
                               }}/>
                            <span>
                                {a.label}
                            </span>
                    </Item>
                )
            })}
        </InnerWrapper>
    )
}

export default function TypesSelector(props) {
    const {
        onTypesChange = arr => {

        }
    } = props;
    const [selectedSet, setSelectedSet] = useState(Set());

    useEffect(() => {
        onTypesChange(selectedSet.toArray());
    }, [selectedSet]);

    let xTypes = EXPORT_TYPES;
    if (isDebugMode() == true) {
        xTypes = xTypes.concat([
            {
                label: 'rr_intervals_temp',
                value: 'rr_intervals_temp'
            },
            {
                label: 'activity_temp',
                value: 'activity_temp'
            }
        ]);
    }

    console.log('TypesSelector: render: xTypes = ', xTypes);
    if (isDebugMode() != true){
        xTypes = xTypes.filter(x => (x.isDebug != true));
    }

    let topTypes = xTypes.filter(x => (x.isRawData != true));
    let bottomTypes = xTypes.filter(x => (x.isRawData == true));
    let allowedTypes = (window?.currentSubscription?.allowedTypes || []).concat(['rr_intervals_temp', 'activity_temp', 'blood_pressure_sensor']);
    console.log('types selector: allowedTypes', allowedTypes);
    //
    return (
        <Wrapper>

            <TopPlaceholder>

                <SectionHeading>
                    Parameters
                </SectionHeading>
                <div>
                    {renderTypes(topTypes, setSelectedSet, selectedSet, allowedTypes)}
                </div>

            </TopPlaceholder>

            <BottomPlaceholder>

                <SectionHeading>
                    Raw Data
                </SectionHeading>

                <div>
                    {renderTypes(bottomTypes, setSelectedSet, selectedSet, allowedTypes)}
                </div>

            </BottomPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const SectionHeading = styled.div`
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 15px;
`;

const BottomPlaceholder = styled.div`

`;


const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  margin: 1px;
`;

const ChImg = styled.img`
  width: 16px;
  right: 16px;
  margin-right: 5px;
  cursor: pointer;
`;

const InnerWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  opacity: ${props => (props.loading == true ? 0.5 : 1)};
  display: grid;
  grid-template-columns: 1fr 1fr;
`;