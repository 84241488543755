import {Map, Stack, Set} from 'immutable'
import * as types from '../ActionTypes.js'

const initialState = {
    loading: false,

    braceletsMap: Map(),
    error: undefined

}

const startLoading = (state, action) => {
    return {...state, loading: true, error: undefined}
}

const stopLoading = (state, action) => {
    return {...state, loading: false, error: action.error}
}

const BraceletsReducer = (state = initialState, action = {}) => {

    switch (action.type) {


        case types.CREATE_BRACELET:
        case types.UPDATE_BRACELET:
        case types.DELETE_BRACELET:
        case types.LOAD_BRACELETS:
            return {
                ...state,
                loading: true,
                error: undefined
            }

        case types.CREATE_BRACELET_FAIL:
        case types.UPDATE_BRACELET_FAIL:
        case types.DELETE_BRACELET_FAIL:
        case types.LOAD_BRACELETS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case types.CREATE_BRACELET_SUCCESS:
        case types.UPDATE_BRACELET_SUCCESS:
            return {
                ...state,
                loading: false,
                braceletsMap: state.braceletsMap.set(action.bracelet.id, action.bracelet)
            }


        case types.DELETE_BRACELET_SUCCESS:
            return {
                ...state,
                loading: false,
                braceletsMap: state.braceletsMap.delete(action.id),
            }


        case types.LOAD_BRACELETS_SUCCESS:
            return {
                ...state,
                loading: false,
                braceletsMap: state.braceletsMap
                    .merge(action.bracelets.reduce((map, form) => map.set(form.id, form), Map()))
            }


        default:
            return state;
    }

}

export default BraceletsReducer;
