import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import DoctorAPI from "../../../api/DoctorAPI";
import DrawHelper from "../../../helpers/DrawHelper";
import useDimensions from "react-use-dimensions";
import ls from "local-storage";
import {
  AXES_COLOR,
  AXES_STROKE_WIDTH,
  DOT_STROKE,
  GRID_STROKE_COLOR,
  xaxisTimeFormatter,
  LINE_COLORS,
  GRID_DASH_ARRAY,
  GRID_THICKNESS,
} from "../../ui/templates";
import {
  CartesianGrid,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from "recharts";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import BaselineHelper from "../../../helpers/BaselineHelper";

const SPOTS_PER_DAY = 12 * 24;
const MA_SIZE = 3;
const LINES = [
  { value: "raw", label: "Raw", color: LINE_COLORS.raw, width: 1, show: true },
  {
    value: "weighted",
    label: "Baseline",
    color: LINE_COLORS.baseline,
    width: 2,
    show: true,
  },
  // {
  //   value: "adpt",
  //   label: "Adaptive",
  //   color: LINE_COLORS.adaptive,
  //   width: 1,
  //   show: true,
  // },
  // {
  //   value: "gap",
  //   label: "Gap",
  //   show: true,
  //   color: LINE_COLORS.gap,
  //   bstyle: "dashed",
  // },
];

const calculateMovingAverage = (data, window) => {
  const length = data.length;
  let spotsWithMaAndAdaptive = [];
  for (let i = 0; i <= length - 1; i++) {
    const currentVal = data[i].respiration_rate;
    const prevVal = i > 0 ? data[i - 1].respiration_rate : currentVal;
    const currentQ = data[i].resp_q;
    if (currentQ <= 0) {
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: null,
        adpt: null,
      });
      continue;
    }
    if (currentVal) {
      let hasValid = false;
      let t0 = moment(data[i].timestamp);
      let _arr = [];
      let startInd = i >= 80 ? i - 80 : 0;
      for (let j = startInd; j <= i; j++) {
        let df = t0.diff(moment(data[j].timestamp), "minutes");
        if (df < window) {
          _arr = data.slice(j, i + 1);
          break;
        }
      }

      let _ma = BaselineHelper.calculateMAFromArray(_arr, "respiration_rate");
      let _wma = BaselineHelper.calculateBWMAFromArray(
        _arr,
        "respiration_rate",
        t0,
        currentVal - prevVal
      );
      // console.log(`maa ${i}, ${_ma}`);
      if (_ma === 0) {
        spotsWithMaAndAdaptive.push({
          ...data[i],
          ma: null,
          adpt: null,
        });
        continue;
      }

      let adpt = null;
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: _ma,
        adpt: adpt,
        test: _wma,
      });
    } else {
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: null,
        adpt: null,
      });
    }
  }
  return spotsWithMaAndAdaptive;
};

const PatientDayBRPMBaselineTool = (props) => {
  let {
    uuid,
    date,
    hasNoData,
    theme,
    baselineWindow = 15,
    activityPoints = [],
    loading = false,
  } = props;
  const [ref, { x, y, width, height }] = useDimensions();
  //   const [loading, setLoading] = useState(false);
  const [RrRawData, setRrRawData] = useState([]);
  const [RrProcessedData, setRrProcessedData] = useState([]);

  const [delta, setDelta] = useState(0.03);
  const [kValue, setKValue] = useState(0.2);
  const [ptime, setPtime] = useState(0);
  const [showLine, setShowLine] = useState({
    raw: true,
    ma: true,
    adpt: true,
    gap: true,
    weighted: true,
  });
  const [dataLength, setDataLength] = useState(0);

  const windowSize = 10;
  let windowSec = windowSize;

  let from = moment(date).startOf("day").valueOf();
  let to = moment(date).endOf("day").valueOf();
  let dataFrom = moment(date).startOf("day").subtract(1, "hour").valueOf();
  let dataTo = moment(date).endOf("day").subtract(1, "hour").valueOf();

  useEffect(() => {
    setRrProcessedData([]);
    // setLoading(true);

    // DoctorAPI.getActivityPoints(uuid, dataFrom, dataTo).then((d) => {
    //   if (!d || d.length === 0) {
    //     return;
    //   }

    let _Rr = activityPoints;
    _Rr = _Rr.map((s) => {
      return {
        ...s,
        //   t: moment(s.slot).valueOf(),
        t: moment(s.timestamp).valueOf(),
        slot: moment(s.timestamp).valueOf(),
        date: moment(s.timestamp).format("HH:mm:ss"),
        respiration_rate: s.resp_q > 0 ? s.respiration_rate : null,
      };
    });
    //   console.log("_Rr.map((s) =", _Rr);

    setRrRawData(_Rr);
    setRrProcessedData(_Rr);
    setDataLength(_Rr.filter((x) => x.respiration_rate).length);
    // setLoading(false);
    // });
  }, [date, activityPoints]);

  //   useEffect(() => {
  //     setRrProcessedData([]);
  //     setLoading(true);
  //     // DoctorAPI.getAggregatedPointsWithAbsoluteTime(
  //     DoctorAPI.getAggregatedPoints(
  //       uuid,
  //       "activity",
  //       ["respiration_rate"],
  //       windowSec,
  //       dataFrom,
  //       dataTo
  //     ).then((d) => {
  //       //   console.log(
  //       //     `raw windowSec: ${windowSec} respiration_rate data from api`,
  //       //     d
  //       //   );
  //       setRrRawData(d);
  //       if (!d || d.length === 0) {
  //         return;
  //       }

  //       let _Rr = d;
  //       // const fixedData = fillRrMissingSpot(d, from);
  //       // let _Rr = calculate30MMovingAverageAndAdaptive(
  //       //   d,
  //       //   MA_SIZE,
  //       //   delta,
  //       //   kValue
  //       // );
  //       _Rr = _Rr.map((s) => {
  //         return {
  //           ...s,
  //           t: moment(s.slot).valueOf(),
  //         };
  //       });
  //         setRrProcessedData(_Rr);
  //       setDataLength(_Rr.filter((x) => x.Rr).length);
  //       setLoading(false);
  //     });
  //   }, [date]);

  useEffect(() => {
    // setLoading(true);
    setRrProcessedData([]);
    if (!RrRawData || RrRawData.length === 0) {
      //   setLoading(false);
      return;
    }
    // const fixedData = fillRrMissingSpot(RrRawData, from);
    let _Rr = RrRawData;
    let _t0 = performance.now();

    _Rr = calculateMovingAverage(RrRawData, baselineWindow);
    _Rr = _Rr.map((s) => {
      return {
        ...s,
        t: moment(s.timestamp).valueOf(),
        slot: moment(s.timestamp).valueOf(),
        date: moment(s.timestamp).format("HH:mm:ss"),
        respiration_rate: s.resp_q > 0 ? s.respiration_rate : null,
      };
    });
    let elapsed = performance.now() - _t0;
    setRrProcessedData(_Rr);
    setPtime(elapsed.toFixed(3));
    // setLoading(false);
    console.log("ELAPSED TIME", elapsed, "mm");
  }, [RrRawData, kValue, delta]);

  let extraLines = DrawHelper.getExtraDottedChartsData(
    RrProcessedData,
    "respiration_rate"
  );
  let RrWithGaps = RrProcessedData;

  for (let sp of RrWithGaps) {
    for (let el in extraLines) {
      const slot1 = extraLines[el]["points"][0].slot;
      const slot2 = extraLines[el]["points"][1].slot;
      if (slot1 === sp.slot) {
        sp[`respiration_rate_gap_${el}`] =
          extraLines[el]["points"][0][`respiration_rate_gap_${el}`];
      }
      if (slot2 === sp.slot) {
        sp[`respiration_rate_gap_${el}`] =
          extraLines[el]["points"][1][`respiration_rate_gap_${el}`];
      }
    }
  }

  let xticks = BaselineHelper.getXaxisTicksEachHour(
    moment(date).startOf("day")
  );
  let yticks = [];
  let RrList = RrProcessedData.map((x) => x.Rr).filter((x) => x !== null);

  let maxRr = Math.max(...RrList);
  //   let ymax = Math.floor(maxRr / 10) * 10;
  //   if (ymax >= 30) {
  //     yticks = [0, 8, 16, 24, 30, 30];
  //   } else if (ymax < 90 && ymax >= 80) {
  //     yticks = [80, 85, 90, 95, 100];
  //   } else if (ymax < 80 && ymax >= 70) {
  //     yticks = [70, 80, 90, 100];
  //   } else {
  //     const gap = Math.round((100 - ymax) / 5);
  //     for (let i = ymax; i <= 100; i += gap) {
  //       yticks.push(i);
  //     }
  //     yticks.push(100);
  //   }

  let startOfDay = moment(date).startOf("day").valueOf();
  let startOfNextDay = moment(date).startOf("day").add(1, "day").valueOf();
  const handleLineCheckbox = (evt) => {
    const name = evt.target.name;
    let oldValue = showLine[name];
    const newSetting = { ...showLine, [name]: !oldValue };
    setShowLine(newSetting);
  };
  let _min = moment(startOfDay).subtract(30, "minutes").valueOf();
  let _max = moment(startOfNextDay).subtract(30, "minutes").valueOf();

  //   console.log("RrProcessedData", RrProcessedData);

  if (loading) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"Calculating..."}
        showTopImg={false}
      />
    );
  }

  if (hasNoData) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"No Data"}
        showTopImg={false}
      />
    );
  }

  return (
    <Wrapper ref={ref}>
      {RrProcessedData.length === 0 ? (
        <SimpleNoDataMessageTool
          loadind={true}
          message={"Fetching data..."}
          showTopImg={false}
        />
      ) : (
        <div>
          {/* <div>LOADING:{loading ? "LOADING" : "loaded"}</div> */}
          <ComposedChart
            width={width}
            // style={
            //   theme === "dark"
            //     ? {
            //         filter: "brightness(250%)",
            //       }
            //     : {}
            // }
            height={height}
            data={RrProcessedData}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeDasharray={GRID_DASH_ARRAY}
              strokeWidth={GRID_THICKNESS}
            />
            <XAxis
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              strokeWidth={AXES_STROKE_WIDTH}
              // interval={11}
              // dataKey={"slot"}
              tickSize={10}
              //   verticalCoordinatesGenerator={(props) => {
              dataKey={"t"}
              ticks={xticks}
              type="number"
              allowDataOverflow={true}
              domain={[startOfDay, startOfNextDay]}
              interval={0}
              tickCount={xticks.length}
              tickFormatter={xaxisTimeFormatter}
            />
            <YAxis
              dataKey={"respiration_rate"}
              // ticks={yticks}
              //   domain={([min, max]) => [0, max >= 20 ? max : 20]}
              domain={([min, max]) => [
                0,
                max > 20 ? Math.round(max + 1) + 1 : 20,
              ]}
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              type="number"
              strokeWidth={AXES_STROKE_WIDTH}
            />
            <Tooltip content={<Spo2Tooltip />} />
            <Line
              type="monotone"
              dataKey="ma"
              name="Moving Average"
              stroke={LINE_COLORS.baseline}
              strokeWidth={2}
              activeDot={true}
              connectNulls={true}
              //   hide={showLine["ma"] ? false : true}
              hide={true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="test"
              //   name="Moving Average"
              stroke={LINE_COLORS.baseline}
              strokeWidth={2}
              activeDot={true}
              connectNulls={true}
              hide={showLine["weighted"] ? false : true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="adpt"
              name="Adaptive"
              stroke={LINE_COLORS.adaptive}
              connectNulls={true}
              // strokeDasharray="5 5"
              //   hide={showLine["adpt"] ? false : true}
              hide={true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="respiration_rate"
              name="Raw"
              stroke={LINE_COLORS.raw}
              strokeWidth={0.9}
              connectNulls={false}
              // strokeDasharray="5 5"
              hide={showLine["raw"] ? false : true}
              dot={false}
              //   dot={DOT_STROKE}
              activeDot={true}
              isAnimationActive={false}
            />
            {extraLines.map((l, i) => {
              return (
                <Line
                  key={i}
                  type={"monotone"}
                  dataKey={`respiration_rate_gap_${i}`}
                  isAnimationActive={false}
                  strokeWidth={2}
                  strokeDasharray="3 3"
                  stroke="#a0a0af90"
                  dot={false}
                  name={"D"}
                  legendType="none"
                  connectNulls={true}
                  //   hide={showLine["raw"] ? false : true}
                  hide={true}
                />
              );
            })}
            {/* <Legend /> */}
          </ComposedChart>
          <LineFilterContainer>
            {LINES.map((f, i) => {
              return (
                <LineFilterItem key={i}>
                  <LineFilterCheckbox
                    type="checkbox"
                    name={f.value}
                    checked={showLine[f.value]}
                    onChange={handleLineCheckbox}
                  />
                  <LineLabel
                    color={f.color}
                    bstyle={f.bstyle ? f.bstyle : "solid"}
                    theme={theme}
                  >
                    {f.label}
                  </LineLabel>
                </LineFilterItem>
              );
            })}
          </LineFilterContainer>
          {/* <label>
            Delta
            <input
              value={delta}
              onChange={(e) => {
                setDelta(e.target.value);
              }}
            />
          </label>
          <label>
            k
            <input
              value={kValue}
              onChange={(e) => {
                setKValue(e.target.value);
              }}
            />
          </label> */}
        </div>
      )}
    </Wrapper>
  );
};

export default PatientDayBRPMBaselineTool;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  position: relative;
`;

const Spo2Tooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let rr = payload[0].payload.respiration_rate;
    let baseline = payload[0].payload.test;
    if (rr) {
      rr = rr.toFixed(0);
    }
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format(
          "HH:mm"
        )}`}</TooltipRow>
        <TooltipRow className="label">{`Raw BRPM: ${rr}`}</TooltipRow>
        <TooltipRow className="label">{`Baseline: ${baseline}`}</TooltipRow>
        <TooltipRow className="label">{`Q: ${payload[0].payload.resp_q}`}</TooltipRow>
        {/* <strong className="label">{`Adaptive: ${payload[0].payload.adpt}`}</strong>
        <br /> */}
      </TooltipWrapper>
    );
  }

  return null;
};

const LineFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  // margin-left: 20px;
  justify-content: center;
`;

const LineFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const LineFilterCheckbox = styled.input`
  &[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #1e7efa;
  }
`;

const LineLabel = styled.label`
  font-weight: 500;
  color: ${(props) => (props.theme === "dark" ? "white" : "black")};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid ${(props) => props.color};
    border-style: ${(props) => props.bstyle};
    // border-top: 1rem solid ${(props) => props.color};
    width: 4rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;
