import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios'
import {CORSANO_API_ENDPOINT} from "../../../constants/config";
import StatsToolTable from "./StatsToolTable";

import {Code} from 'react-content-loader'

function formatDateTime(x) {
    if (x == undefined) {
        return '';
    }
    let minutes = Math.round(+x / 60.0);
    let hours = Math.floor(minutes / 60.0);
    let minutes2 = minutes - hours * 60;
    return `${hours}:${`${minutes2}`.padStart(2, '0')}`;
}

const TABLES = [
    {
        title: 'Steps Trending',
        paramName: 'activity.total_steps',
    },
    {
        title: 'Sleep Trending',
        paramName: 'sleep.sleep_duration',
        formatter: x => {
            return formatDateTime(x);
        }
    },
    {
        title: 'Deep Sleep Trending',
        paramName: 'sleep.deep_time',
        formatter: x => {
            return formatDateTime(x);
        }
    },
    {
        title: 'REM Sleep Trending',
        paramName: 'sleep.rem_time',
        formatter: x => {
            return formatDateTime(x);
        }
    },
    {
        title: 'RecoverySteps Trending',
        paramName: 'recovery.value',
    },
    {
        title: 'HRV Nocturnal Trending',
        paramName: 'stress.avg_rmssd',
    },
    {
        title: 'Respiration Rate Trending',
        paramName: 'respiration_rate.avg_respiration_rate',
    }


];

export default function GroupStatsTableTool(props) {
    const {
        tag,
        from,
        to
    } = props;

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (tag == undefined) {
            return;
        }
        let xTo = (to == undefined) ? +moment().startOf('day') : +to
        let xFrom = (from == undefined) ? +moment().startOf('day').add(-61, 'day') : +from;
        setLoading(true);
        let dates = [];
        let t = xFrom;
        while (t < xTo) {
            dates.push(moment(t).format('YYYY-MM-DD'));
            t += 24 * 60 * 60 * 1000;
        }

        axios.post(`${CORSANO_API_ENDPOINT}/studies/${tag}/summaries-by-dates-with-users`, {
            dates: dates
        }).then(d => d.data).then(mp => {
            setUsers(mp);
            setLoading(false);
        });
    }, [tag]);

    if (loading == true) {
        return (
            <div>
                <Code />
                <Code />
            </div>
        )
    }

    return (
        <Wrapper>

            {/*{JSON.stringify(users)}*/}

            {TABLES.map((a, i) => {
                return (
                    <TablePlaceholder>
                        <StatsToolTable
                            paramName={a.paramName}
                            users={users}
                            title={a.title}
                            formatter={a.formatter}
                            tag={tag}
                        />
                    </TablePlaceholder>
                )
            })}


        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TablePlaceholder = styled.div`
  padding: 5px;
  width: 50%;
  box-sizing: border-box;
`;