import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import DoctorAPI from "../../../api/DoctorAPI";
import SimpleBpmSlotsChart from "../../heart_rate/tools/SimpleBpmSlotsChart";
import {Sidebar} from "arui-feather/sidebar";
import axios from "axios";
import {EditButton} from "../../patients/panels/PatientPanel";
import DrawHelper from "../../../helpers/DrawHelper";

export default function PatientDaySpo2Tool(props) {

    const {
        onStartLoading = () => {

        },
        onEndLoading = hasData => {

        }
    } = props;

    const {uuid, dayTimestamp} = props;
    const [points, setPoints] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [recalculating, setRecalculating] = useState(false);

    const [loadingId, setLoadingId] = useState(undefined);

    useEffect(() => {
        let from = +moment(+dayTimestamp).startOf('day');
        let to = +moment(+dayTimestamp).endOf('day');
        setLoading(true);
        setPoints([]);
        onStartLoading();
        DoctorAPI.getAISpo2Data(uuid, from, to).then(arr => {
            setPoints(arr);
            setLoading(false);
            console.log('getAISpo2Data: pld arr = ', arr);
            onEndLoading(arr.length > 0);
        });
    }, [uuid, dayTimestamp]);

    useEffect(() => {
        if (recalculating == true) {
            return;
        }
        let from = +moment(+dayTimestamp).startOf('day');
        let to = +moment(+dayTimestamp).endOf('day');
        setPoints([]);
        DoctorAPI.getAISpo2Data(uuid, from, to).then(arr => {
            setPoints(arr);
        });
    }, [recalculating]);


    if (points.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    if (points.length == 0 && loading == false) {
        return null;
    }

    // let xPoints = points.map(x => ({
    //     t: +x.start_timestamp,
    //     quality: x?._raw_response?.Quality,
    //     spo2: x?._raw_response?.Spo2
    // })).filter(x => (x.spo2 != undefined));

    let xPoints = points.map(x => ({
        t: +x.start_timestamp,
        ...x,
        date: moment(+x.start_timestamp).format('HH:mm')
    })).filter(x => (x.date != undefined)).map(x => ({...x, spo2: (x.spo2 == 0 ? null : x.spo2)}));

    console.log('xPoints = ', xPoints);

    let gridPoints = DrawHelper.getSpo2MonotonePoints(xPoints);

    console.log('gridPoints = ', gridPoints);

    return (
        <Wrapper>

            {/*<Heading>*/}
            {/*    Experimental AI Spo2*/}
            {/*</Heading>*/}

            <ChartPlaceholder>
                <SimpleBpmSlotsChart
                    yDoman={[60, 100]}
                    slots={gridPoints}
                    dataKey={'spo2'}/>
            </ChartPlaceholder>

            <BottomDiv>
                <RecordsSpan onClick={() => {
                    setModalVisible(true);
                }}>
                    records list
                </RecordsSpan>
            </BottomDiv>

            <Sidebar visible={modalVisible} onCloserClick={() => {
                setModalVisible(false);
            }}>

                {modalVisible == false ? null :
                    <div>
                        {points.map((a, i) => {
                            let isLoading = (a._id == loadingId);
                            let hasError = (a.spo2 == undefined || a.spo2 == 0);
                            return (
                                <RowItem key={i}>
                                    <span>
                                        <span>
                                            {`${moment(a.start_timestamp).format('DD.MM.YYYY HH:mm:ss')} - ${a.spo2}`}
                                            <span style={{fontSize: 12, opacity: 0.5, marginLeft: 5}} >{`${a.quality}`}</span>
                                        </span>
                                        <br/>
                                        {hasError == false ? null :
                                            <span
                                                style={{fontSize: 10, fontStyle: 'italic', opacity: 0.5, color: 'red'}}>
                                                {a?._raw_response?.message}
                                            </span>
                                        }
                                    </span>
                                    {isLoading == true ? <RowDownload2>loading...</RowDownload2> :
                                        <RowDownload onClick={async () => {
                                            setLoadingId(a._id);
                                            let pld = (await axios.get(`https://api.study-integration.corsano.com/ds/user/${uuid}/spo2-measurements/${a._id}/download`)).data;
                                            setLoadingId(false);
                                            let txt = JSON.stringify(pld);
                                            download(`${moment(a.start_timestamp).format('DD_MM_YYYY_HH_mm_ss')}_spo2_raw_ppg2.json`, txt);
                                        }}>
                                            download
                                        </RowDownload>
                                    }
                                </RowItem>
                            )
                        })}

                        <div style={{marginTop: 20, marginBottom: 20}}>
                            <EditButton onClick={async () => {
                                if (recalculating == true){
                                    return;
                                }
                                setRecalculating(true);
                                let from = +moment(+dayTimestamp).startOf('day');
                                let to = +moment(+dayTimestamp).endOf('day');
                                await DoctorAPI.recalculateSpo2(uuid, +from, +to);
                                setRecalculating(false);
                                setModalVisible(false);
                            }}>
                                {recalculating == false ? 'Recalculate' : 'Recalculating...'}

                            </EditButton>
                        </div>

                    </div>
                }

            </Sidebar>

        </Wrapper>
    );
}

const RowItem = styled.div`
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RowDownload = styled.a`
  font-size: 10px;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const RowDownload2 = styled.div`
  font-size: 10px;
  opacity: 0.6;
`;

const BottomDiv = styled.div`
  text-align: right;
`;

const RecordsSpan = styled.span`
  font-style: italic;
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  width: 100%;

  .sidebar__inner {
    background: white;
  }

`;

const ChartPlaceholder = styled.div`
  height: 320px;
  width: 100%;
`;

const Heading = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 10px;
  font-size: 12px;
  opacity: 0.8;
`;

export function download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}