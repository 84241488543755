import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
} from "react";
import styled from "styled-components";
import { Code } from "react-content-loader";
import axios from "axios";
import { API_ENDPOINT, CORSANO_API_ENDPOINT } from "../../../constants/config";

export default function PatientComplianceTableTool(props) {
    const { uuid, date } = props;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        if (loading == true) {
            return;
        }
        setLoading(true);
        axios
            .post(`${CORSANO_API_ENDPOINT}/usage/data-amount-report`, {
                uuids: [uuid],
                client_date_from: date,
                client_date_to: date,
                report_wiff_data: 1,
            })
            .then((d) => d.data)
            .then((d) => {
                console.log("111 d = ", d);
                if (d != undefined) {
                    d = d[uuid];
                    console.log("222 d = ", d);
                    if (d != undefined) {
                        d = d[date];
                        console.log("44 d = ", d);
                        setData(d);
                    }
                    setLoading(false);
                }
            });
    }, [uuid, date]);

    let dd = data || {};

    let keys = Object.keys(dd);

    if (loading == true) {
        return (
            <div>
                <div
                    style={{ padding: 10, textAlign: "center" }}
                    className="normal-text"
                >
                    Fetching received datapoints, please wait...This may take 30
                    seconds...
                </div>
                <Code />
            </div>
        );
    }

    if (keys.length == 0 || data == undefined) {
        return null;
    }

    return (
        <Wrapper>
            <Table>
                <Tbody className="normal-text">
                    {keys.map((a, i) => {
                        console.log("AAAAAAA", a);
                        // raw_data_acc : 2079328
                        // raw_data_activity : 1290
                        // raw_data_hrv : 659
                        // raw_data_ppg2 : 1817856
                        // raw_data_rr_interval : 34155
                        // raw_data_sleep : 375
                        // raw_data_temperature : 1297
                        // summary_data_activity_slots : 48
                        // summary_data_heart_rate_slots : 288
                        // summary_data_respiration_rate_slots : 48
                        // summary_data_sleep_slots : 435
                        // summary_data_spo2_slots : 48
                        // summary_data_stress_slots : 87
                        // summary_data_temperature_slots : 48

                        return (
                            <Tr key={a}>
                                <Td>{VerbalMetricsName[a] || a}</Td>
                                <Td>{dd[a]}</Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            <div className="normal-text">
                Datapoints received for the last day.
            </div>
        </Wrapper>
    );
}

const VerbalMetricsName = {
    raw_data_acc: "Raw Accelerator Data (raw_data_acc)",
    raw_data_activity: "Raw Activity Data (raw_data_activity)",
    raw_data_hrv: "Raw Heart Rate Variability Data (raw_data_hrv)",
    raw_data_ppg2: "Raw PPG2 Data (raw_data_ppg2)",
    raw_data_rr_interval: "Raw RR Interval Data (raw_data_rr_interval)",
    raw_data_sleep: "Raw Sleep Data (raw_data_sleep)",
    raw_data_temperature: "Raw Temperature Data (raw_data_temperature)",
    summary_data_activity_slots:
        "Summary Activity Slots (summary_data_activity_slots)",
    summary_data_heart_rate_slots:
        "Summary Heart Rate Slots (summary_data_heart_rate_slots)",
    summary_data_respiration_rate_slots:
        "Summary Respiration Rate Slots (summary_data_respiration_rate_slots)",
    summary_data_sleep_slots: "Summary Sleep Slots (summary_data_sleep_slots)",
    summary_data_spo2_slots: "Summary SpO2 Slots (summary_data_spo2_slots)",
    summary_data_stress_slots:
        "Summary Stress Slots (summary_data_stress_slots)",
    summary_data_temperature_slots:
        "Summary Temperature Slots (summary_data_temperature_slots)",
};

const Wrapper = styled.div``;

const Table = styled.table`
    width: 100%;
    box-sizing: border-box;
    border: 1px solid lightgrey;
    border-collapse: collapse;
`;

const Tr = styled.tr`
    height: 30px;
`;

const Th = styled.th`
    border: 1px solid lightgrey;
    text-align: left;
    font-weight: bold;
    padding-left: 7px;
    opacity: 0.8;
    font-size: 14px;
`;

const Td = styled.td`
    box-sizing: border-box;
    border: 1px solid lightgrey;
    padding: 3px;
    padding-left: 7px;
`;

const SmallTd = styled(Td)`
    font-size: 14px;
`;

const Thead = styled.thead`
    background: #f5f5f5;
`;

const Tbody = styled.tbody``;
