import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import styled from 'styled-components';
import DoctorAPI from "../../../api/DoctorAPI";

const demo_points = require('./demo_compliance.json')

const MAX_DAY_PPG_POINTS = 25 * 86400;

export function perc2color(perc) {
    let r, g, b = 0;
    if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    } else {
        g = 255;
        r = Math.round(510 - 5.10 * perc);
    }
    let h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
}

function getPercFromSlots(slots, isDemo = false) {
    if (slots == undefined || slots.length == 0) {
        return 0;
    }
    let n = slots.length;
    let xSlots = slots.filter(x => (x?.bpm != undefined && +x?.bpm > 0));
    return 100.0 * xSlots.length / n;
}

export default function PatientOptimizedCompliancePanel(props) {
    const {
        monthTimestamp,
        id,
        isDemo = false,
        onDateClick = t => {

        }
    } = props;

    const [summaries, setSummaries] = useState([]);

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(undefined);

    const [slotLoading, setSlotLoading] = useState(false);
    const [slotItems, setSlotItems] = useState([]);

    let selectedSlotDate = (selectedSlot == undefined) ? undefined : selectedSlot.date;

    useEffect(() => {
        if (monthTimestamp == undefined || id == undefined) {
            return null;
        }
        // setLoading(true);
        setItems([]);
        setSelectedSlot(undefined);
        // DoctorAPI.getPatientComplianceItems(id, monthTimestamp, isDemo).then(arr => {
        //     console.log('getPatientComplianceItems: --->>> arr = ', arr);
        //     setLoading(false);
        //     setItems(arr);
        // });
        if (isDemo == true) {
            return;
        }
        DoctorAPI.getMonthSummariesWithSlots(id, monthTimestamp).then(x => {
            console.log('got summaries with slots: x = ', x);
            setSummaries(x);
        })
    }, [monthTimestamp, id]);

    // useEffect(() => {
    //     if (selectedSlot == undefined) {
    //         return;
    //     }
    //     setSlotItems([]);
    //     setSlotLoading(true);
    //     DoctorAPI.getPatientComplianceSlotStats(id, selectedSlot._id, isDemo).then(pld => {
    //         console.log('getPatientComplianceSlotStats: pld = ', pld);
    //         setSlotItems(pld);
    //         setSlotLoading(false);
    //     });
    // }, [selectedSlotDate]);

    let from = +moment(monthTimestamp).startOf('month').startOf('day');
    let to = +moment(monthTimestamp).startOf('month').add(1, 'months').startOf('day');
    let n = (+to - +from) / 86400000;
    let days = Array.from({length: n}).map((a, i) => (+from + i * 86400001));
    let daysWithPerc = days.map((a, i) => {
        let ds = moment(a).format('YYYY-MM-DD');
        let xVal = summaries.filter(z => (z.date == ds))[0];
        let slots = xVal?.heart_rate?.slots || [];
        let perc = getPercFromSlots(slots);
        return {
            perc: perc,
            t: a
        }
    });
    // console.log('render: daysWithPerc = ', daysWithPerc);
    // console.log('render: daysWithPerc = ', JSON.stringify(daysWithPerc));
    if (isDemo == true) {
        daysWithPerc = demo_points;
    }

    return (
        <Wrapper loading={loading}>
            <InnerWrapper>
                {daysWithPerc.map((a, i) => {
                    let bgColor = perc2color(a.perc);
                    let {perc} = a;
                    return (
                        <Day key={a.t} bgColor={perc == undefined ? undefined : bgColor}
                             style={{cursor: (perc == undefined) ? 'default' : 'pointer'}}
                             onClick={() => {
                                 onDateClick(+moment(a.t).startOf('day'));
                                 // if (xVal != undefined) {
                                 //     setSelectedSlot(xVal);
                                 // }
                             }}>
                            <TopDayPlaceholder>
                                {moment(+a.t + 3 * 60 * 60 * 1000).format('D ddd')}
                            </TopDayPlaceholder>
                            <BottomDayPlaceholder>
                                {perc == undefined ? null : `${perc.toFixed(1)}%`}
                            </BottomDayPlaceholder>
                        </Day>
                    )
                })}
            </InnerWrapper>

        </Wrapper>
    );
}


const Day = styled.div`
  background: #FFFFFF;
  border: 1px solid rgba(135, 152, 173, 0.1);
  box-sizing: border-box;
  padding: 5px;
  text-align: center;
  color: #2E384D;
  font-size: 12px;
  height: 40px;
  background: ${props => (props.bgColor == undefined ? 'white' : props.bgColor)};
`;

const TopDayPlaceholder = styled.div`
  text-align: center;
  color: #2E384D;
`;

const BottomDayPlaceholder = styled.div`
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  opacity: 0.475;
`;

const InnerWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  opacity: ${props => (props.loading == true ? 0.5 : 1)};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  @media (max-width: 920px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 840px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 720px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 520px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 420px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;