import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import ls from "local-storage";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  Area,
  ReferenceDot,
  ReferenceLine,
  Bar,
  BarChart,
  Rectangle,
} from "recharts";
import useDimensions from "react-use-dimensions";

const getParamsOptions = (name) => {
  if (name == "activity") {
    return [
      {
        label: "Steps",
        value: "total_steps",
        type: "bar",
      },
      {
        label: "Distance",
        value: "distance",
        type: "bar",
      },
      {
        label: "Calories",
        value: "calories",
        type: "bar",
      },
    ];
  }
  if (name == "sleep") {
    return [
      {
        label: "Sleep duration",
        value: "sleep_duration",
        type: "line",
        transform: (x) => ({
          ...x,
          sleep_duration:
            x.sleep_duration == undefined
              ? null
              : +x.sleep_duration / 60.0 / 60.0,
        }),
      },
      {
        label: "Performance",
        value: "performance",
        type: "line",
      },
      {
        label: "Consistency",
        value: "consistency",
        type: "line",
      },
    ];
  }
  if (name == "respiration_rate") {
    return [
      {
        label: "Average respiration rate",
        value: "avg_respiration_rate",
        type: "line",
      },
    ];
  }
  if (name == "heart_rate") {
    return [
      {
        label: "Average daily heart rate",
        value: "avg_daily_heart_rate",
        type: "line",
      },
      {
        label: "Max daily heart rate",
        value: "max_daily_heart_rate",
        type: "line",
      },
      {
        label: "Resting heart rate",
        value: "rest_daily_heart_rate",
        type: "line",
      }
    ];
  }
  if (name == "stress") {
    return [
      {
        label: "Average HRV",
        value: "avg_rmssd",
        type: "line",
      },
      {
        label: "Readiness",
        value: "readiness",
        type: "bar",
        customColor: true,
        barColor: (v) =>
          +v > 66.0 ? "#019C46" : +v > 33 ? "#F4C142" : "#C40118",
      },
      {
        label: "Average stress index",
        value: "avg_si",
        type: "line",
      },
    ];
  }
  return [];
};

const CustomBar = (props) => {
  const { fill, fillColor } = props;
  // console.log('CustomBar: props = ', props);
  let xFill = fill;
  if (fillColor != undefined) {
    xFill = fillColor;
  }

  //business logic here to update fill color explicitly
  // if(years === 'Current') {
  //     fill = '#NewFillColor';
  // }

  //use explicit fill here, or use the additional css class and make a css selector to update fill there
  return (
    <Rectangle {...props} fill={xFill} className={`recharts-bar-rectangle`} />
  );
};

const DEFAULT_BAR_COLOR = "#FF962A";

export default function SmartTrendsChart(props) {
  const {
    loading = false,
    dataType = "activity",
    points = [],
    type = "line", // one of bar/line
  } = props;

  const [ref, { x, y, width, height }] = useDimensions();
  const theme = ls.get("theme");

  const paramsOptions = getParamsOptions(dataType);
  const [param, setParam] = useState(
    paramsOptions.length == 0 ? undefined : paramsOptions[0]
  );

  if (paramsOptions.length == 0) {
    return null;
  }

  if (points.length == 0 && loading == false) {
    return <NoDataPlaceholder>No data to display</NoDataPlaceholder>;
  }

  let xPoints = JSON.parse(JSON.stringify(points));

  let barColorFun =
    param == undefined ||
    param?.barColor == undefined ||
    typeof param?.barColor != "function"
      ? (x_) => DEFAULT_BAR_COLOR
      : (x_) => (x_ == undefined ? DEFAULT_BAR_COLOR : param?.barColor(x_));

  // console.log("xPoints = ", xPoints);
  if (param?.type == "bar") {
    xPoints = xPoints.map((xx) => ({
      ...xx,
      fillColor: barColorFun(xx[param?.value]),
    }));
  }
  if (param?.transform != undefined) {
    xPoints = xPoints.map((x) => param.transform(x));
    // console.log('xPoints after transform = ', xPoints);
    // transform
  }

  // console.log("render: param = ", param);

  return (
    <Wrapper>
      <ChartPlaceholder ref={ref}>
        {param?.type != "line" ? null : (
          <LineChart
            width={width}
            height={height}
            data={xPoints}
            connectNulls={false}
            margin={{ top: 5, right: 5, left: -20, bottom: 5 }}
          >
            <XAxis
              tick={{ fill: theme === "dark" ? "white" : "" }}
              dataKey="date"
            />
            <YAxis tick={{ fill: theme === "dark" ? "white" : "" }} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey={param?.value}
              strokeWidth={2}
              animationDuration={2}
              dot={false}
            />
          </LineChart>
        )}

        {param?.type != "bar" ? null : (
          <BarChart
            width={width}
            height={height}
            data={xPoints}
            margin={{
              top: 5,
              right: 10,
              left: -20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              tick={{ fill: theme === "dark" ? "white" : "" }}
              dataKey="date"
              tickCount={25}
            />
            <YAxis tick={{ fill: theme === "dark" ? "white" : "" }} />
            <ReferenceLine y={0} stroke="#000" />

            <Bar
              shape={CustomBar}
              {...param}
              customColor={param.customColor}
              dataKey={param?.value}
              fill={DEFAULT_BAR_COLOR}
            />
          </BarChart>
        )}

        {/*{JSON.stringify(points)}*/}
      </ChartPlaceholder>
      {paramsOptions.length < 2 ? null : (
        <BottomSwitcherPlaceholder>
          {paramsOptions.map((a, i) => {
            return (
              <OptItem
              key={i}
                theme={theme}
                selected={param?.value == a.value}
                onClick={(x) => {
                  setParam(a);
                }}
              >
                {a.label}
              </OptItem>
            );
          })}
        </BottomSwitcherPlaceholder>
      )}
    </Wrapper>
  );
}

const NoDataPlaceholder = styled.div`
  width: 100%;
  height: 320px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OptItem = styled.div`
  box-sizing: border-box;
  margin-right: 10px;
  cursor: pointer;
  color: ${(props) =>
    props.selected
      ? props.theme === "dark"
        ? "white"
        : "black"
      : props.theme === "dark"
      ? "#ccc"
      : "black"};
  border-bottom: ${(props) =>
    props.selected == true ? "2px solid #4aa4e3" : "2px solid transparent"};
  font-weight: ${(props) => (props.selected == true ? "bold" : "default")};
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const ChartPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 320px;
`;

const BottomSwitcherPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  justify-content: center;
`;
