import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";

import DateTimePicker from "react-datetime-picker";
import TypesSelector from "../tools/TypesSelector";
import { Button } from "../../trials/panels/StudySessionsPanel";
import NiceModal from "../../modals/NiceModal";
import ExportServiceTool from "../tools/ExportServiceTool";
import OptimizedExportAPI from "../../../api/OptimizedExportAPI";
import { useInterval } from "../../apps/RouterApp";

import Spin from "arui-feather/spin";
import { toast } from "react-hot-toast";
import ProgressTool from "../../progress/tools/ProgressTool";
import { useMappedState } from "redux-react-hook";
import DeliveryTypeSelector from "../tools/DeliveryTypeSelector";

// const MAX_RAW_EXPORT_RANGE = 48; // if range > 2 days, disallow export raw data
const MAX_RAW_EXPORT_RANGE = 24 * 31 * 6; // if range > 6 months, disallow export raw data
const RAW_TYPES = [
  "acc",
  "bioz",
  "ecg",
  "ppg",
  "ppg2",
  "heat_flux_sensor_temperature",
  "blood_pressure_sensor",
];

export function getFolderNameByPatient(patient) {
  let { code, email, uuid, first_name, last_name } = patient;
  if (
    first_name != undefined &&
    first_name != "" &&
    last_name != undefined &&
    last_name != ""
  ) {
    return `${first_name} ${last_name}`;
  }
  if (email != undefined && email != "") {
    return email;
  }
  if (code != undefined && code != "") {
    return code;
  }
  return uuid;
}

export function renderExportProgress(statusData, patients, name) {
  if (statusData == undefined || patients.length == 0) {
    return null;
  }
  let namesMap = patients.reduce(
    (mp, x) => ({ ...mp, [x._id]: getFolderNameByPatient(x) }),
    {}
  );
  let { progress = {}, export_user_ids = [], log = [], status } = statusData;
  let isDone = status == "done" || status == "no_data";
  let revLogs = [].concat(log || []).reverse();

  return (
    <div>
      {isDone == false ? (
        <span>
          <Spin visible={true} /> exporting...
        </span>
      ) : (
        <div>
          <h2>Finished!</h2>
          {status == "no_data" ? (
            "No data to export"
          ) : (
            <p>
              <a
                href={OptimizedExportAPI.getNewDownloadUrl(statusData._id, name)}
                target={"_blank"}
              >
                download archive
              </a>
            </p>
          )}
          {status != "archiving_in_progress" ? null : (
            <div>{"Compressing files..."}</div>
          )}
        </div>
      )}

      <ul>
        {patients.map((a, i) => {
          let xId = export_user_ids[i];
          let name = namesMap[a._id];
          console.log("a._id = ", xId);
          let progData = progress[xId] || {};
          let pKeys = Object.keys(progData);
          return (
            <li key={xId}>
              <div>
                <b>{name}</b>
              </div>
              {progData == undefined ? null : (
                <div>
                  <ul>
                    {pKeys.map((z, j) => {
                      let dd = progData[z];
                      let dKeys = Object.keys(dd);
                      return (
                        <li key={z}>
                          <div>{z}</div>
                          {dKeys.map((w, u) => {
                            return (
                              <div key={w}>
                                {`${moment(+w).format("DD.MM.YYYY")} - ${
                                  dd[w]
                                }`}
                              </div>
                            );
                          })}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </li>
          );
        })}
      </ul>

      {log == undefined ? null : (
        <div>
          {revLogs.map((a, i) => {
            return <LogItem key={`${i}+${a}`}>{a}</LogItem>;
          })}
        </div>
      )}
    </div>
  );
}

export function renderExportProgressOptimized(statusData, patients, name) {
  if (statusData == undefined || patients.length == 0) {
    return null;
  }
  let namesMap = patients.reduce(
    (mp, x) => ({ ...mp, [x._id]: getFolderNameByPatient(x) }),
    {}
  );
  let { progress = {}, export_user_ids = [], log = [], status } = statusData;
  let isDone = status == "done" || status == "no_data";
  let revLogs = [].concat(log || []).reverse();
  let { merging = {} } = progress;
  let exp = progress?.export || {};
  let xFinished = (merging?.finished || 0) + (exp?.finished || 0);
  let xTotal = (merging?.total || 0) + (exp?.total || 0);
  let xProgress =
    xTotal == 0
      ? undefined
      : +(Math.round((10000.0 * xFinished) / xTotal) / 100.0).toFixed(2);

  return (
    <div>
      {isDone == false ? (
        <span>
          <Spin visible={true} /> exporting...
        </span>
      ) : (
        <div>
          <h2>Finished!</h2>
          {status == "no_data" ? (
            "No data to export"
          ) : (
            <p>
              <a
                href={OptimizedExportAPI.getNewDownloadUrl(statusData._id, name)}
                target={"_blank"}
              >
                download archive
              </a>
            </p>
          )}
          {status != "archiving_in_progress" ? null : (
            <div>{"Compressing files..."}</div>
          )}
        </div>
      )}

      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <ProgressTool
          leftText={`Export progress - ${xProgress}%`}
          percent={xProgress}
          color={"blue"}
        />
      </div>

      {log == undefined ? null : (
        <div>
          {revLogs.map((a, i) => {
            return <LogItem key={`${i}+${a}`}>{a}</LogItem>;
          })}
        </div>
      )}
    </div>
  );
}

export function renderNewExportProgressOptimized(statusData, patients, name) {
    if (statusData == undefined || patients.length == 0) {
        return null;
    }
    let namesMap = patients.reduce(
        (mp, x) => ({ ...mp, [x._id]: getFolderNameByPatient(x) }),
        {}
    );
    let { progress = {}, export_user_ids = [], log = [], status } = statusData;
    let isDone = status == "done" || status == "no_data" || status == "finished";
    let revLogs = [].concat(log || []).reverse();
    let { merging = {} } = progress;
    let exportingData = progress.export || {};
    let archivingData = progress.archiving || {};
    let exportingTotal = exportingData.total || 0;
    let exportingFinished = exportingData.finished || 0;
    let archivingTotal = archivingData.total || 0;
    let archivingFinished = archivingData.finished || 0;
    let exportingPercent = (exportingTotal == 0) ? 0 : Math.round(exportingFinished * 100 / exportingTotal);
    let archivingPercent = (archivingTotal == 0) ? 0 : Math.round(archivingFinished * 100 / archivingTotal);

    let exp = progress?.export || {};
    let xFinished = (merging?.finished || 0) + (exp?.finished || 0);
    let xTotal = (merging?.total || 0) + (exp?.total || 0);
    let xProgress =
        xTotal == 0
            ? undefined
            : +(Math.round((10000.0 * xFinished) / xTotal) / 100.0).toFixed(2);

    return (
        <div>
            {isDone == false ? (
                <span>
          <Spin visible={true} /> exporting...
        </span>
            ) : (
                <div>
                    <h2>Finished!</h2>
                    {status == "no_data" ? (
                        "No data to export"
                    ) : (
                        <p>
                            <a
                                href={OptimizedExportAPI.getNewDownloadUrl(statusData._id, name)}
                                target={"_blank"}
                            >
                                download archive
                            </a>
                        </p>
                    )}
                    {status != "archiving_in_progress" ? null : (
                        <div>{"Compressing files..."}</div>
                    )}
                </div>
            )}

            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <ProgressTool
                    leftText={`Export progress - ${exportingPercent}%`}
                    percent={exportingPercent}
                    color={"blue"}
                />
            </div>

            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <ProgressTool
                    leftText={`Archiving progress - ${archivingPercent}%`}
                    percent={archivingPercent}
                    color={"blue"}
                />
            </div>

            {log == undefined ? null : (
                <div>
                    {revLogs.map((a, i) => {
                        return <LogItem key={`${i}+${a}`}>{a}</LogItem>;
                    })}
                </div>
            )}
        </div>
    );
}

export default function OptimizedDoctorExportPanel(props) {
  const {
    isDemo = false,
    study_tag = "",
    patients = [],
    exportTimestamp,
    exportName = "export.zip",
  } = props;

  const { currentUserObj, defaultEmail } = useMappedState(
    useCallback((state) => {
      let email = state?.auth?.currentUserObj?.usersUser?.email;
      return {
        currentUserObj: state.auth.currentUserObj,
        defaultEmail: email,
        isDemo: email == "demo@corsano.com_",
        isCorsanoDemo: email == "demo@corsano.com",
        bracelets: state.bracelets.braceletsMap.toArray(),
      };
    })
  );

  const [types, setTypes] = useState([]);

  // const [from, setFrom] = useState(+moment(props.from == undefined ? +new Date() : +props.from).startOf('day'));
  const [from, setFrom] = useState(
    props.from == undefined ? +moment().startOf("day") : +props.from
  );
  // const [to, setTo] = useState(+moment(props.to == undefined ? +new Date() : +props.to).endOf('day'));
  const [to, setTo] = useState(
    props.to == undefined ? +moment().endOf("day") : +props.to
  );
  const [duration, setDuration] = useState(
    moment(to).diff(moment(from), "hours")
  );

  const [exporting, setExporting] = useState(false);
  const [gettingExportId, setGettingExportId] = useState(false);
  const [exportId, setExportId] = useState();
  const [fetching, setFetching] = useState(false);

  const [deliveryType, setDeliveryType] = useState("archive");
  const [deliveryEmail, setDeliveryEmail] = useState(defaultEmail || "");

  const [exportStatusData, setExportStatusData] = useState(undefined);

  const [exportData, setExportData] = useState();

  const [error, setError] = useState(undefined);
  let isTooBigSpan =
    from != undefined && to != undefined && +to - +from > 86400000 * 180;
  let isSpanNegative = from != undefined && to != undefined && +to < +from;

  useEffect(() => {
    const d = moment(to).diff(moment(from), "hours");
    setDuration(d);
  }, [from, to]);

  useInterval(() => {
    if (
      exportId == undefined ||
      fetching == true ||
      exportStatusData?.status == "done"
    ) {
      return;
    }
    setFetching(true);
    // OptimizedExportAPI.getExportStatus(exportId).then((d) => {
    OptimizedExportAPI.getNewExportStatus(exportId).then((d) => {
      setExportStatusData(d);
      setFetching(false);
      if (d.status == "done") {
        setExportId(undefined);
        toast("Finished!");
      }
      if (d.status == "no_data") {
        setExportId(undefined);
        toast("No data to export!");
      }
    });
  }, 5000);

  console.log("DoctorExportPanel: render: patients = ", patients);
  let user_folder_names = patients
    .filter((x) => x.state != "pre_created")
    .map((x) => {
      return {
        user_uuid: x.uuid,
        folder_name: getFolderNameByPatient(x),
      };
    });

  return (
    <Wrapper>
      {exportTimestamp != undefined ? null : (
        <DatesPlaceholder>
          <Half>
            <Label>From</Label>
            <DateTimePicker
              onChange={(d) => {
                setFrom(+d);
              }}
              value={new Date(from == undefined ? +new Date() : +from)}
            />
          </Half>
          <Half>
            <Label>To</Label>
            <DateTimePicker
              onChange={(d) => {
                setTo(+d);
              }}
              value={new Date(to == undefined ? +new Date() : +to)}
            />
          </Half>
        </DatesPlaceholder>
      )}

      <TypesPlaceholder>
        <TypesSelector
          onTypesChange={(arr) => {
            setTypes(arr);
          }}
        />
      </TypesPlaceholder>

      <div>
        <DeliveryTypeSelector
          type={deliveryType}
          defaultEmail={defaultEmail}
          onChange={(tp, em) => {
            setDeliveryType(tp);
            setDeliveryEmail(em);
          }}
        />
      </div>

      <BottomPlaceholder>
        <Button
          disabled={types.length == 0}
          onClick={async () => {
            //   array1.some(element => array2.includes(element));
            const hasRawType = types.some((t) => RAW_TYPES.includes(t));
            if (duration > MAX_RAW_EXPORT_RANGE && hasRawType) {
              toast.error(
                "For raw data export, please ensure that the range is no more than 48 hours."
              );
              return;
            }
            if (user_folder_names.length == 0) {
              toast.error(
                "There must be at least one active patient to export data."
              );
              return;
            }
            if (exportId != undefined || gettingExportId == true) {
              return window.alert(
                "Can not start exporting because there is a running export"
              );
            }
            if (types.length == 0) {
              return;
            }
            if (isDemo == true) {
              window.alert("The archive with data will be sent to your inbox.");
              return;
            }
            if (isSpanNegative) {
              setError('Date "To" must be later than date "From"');
              return;
            }
            if (isTooBigSpan) {
              setError("Please select range less than 180 days.");
              return;
            }
            let expData = {
              types: types,
              from: from,
              to: to,
              users: patients,
              study_tag: study_tag,
              name: exportName,
              uuids: user_folder_names.map((x) => x.user_uuid),
              user_folder_names: user_folder_names,
              email: deliveryType == "archive" ? undefined : deliveryEmail,
            };
            setExportData(expData);
            setGettingExportId(true);
            setExportStatusData(undefined);
            // let d = await OptimizedExportAPI.createExport(expData);
            // let d = await OptimizedExportAPI.createExportOptimized(expData);
            let d = await OptimizedExportAPI.createNewExportOptimized(expData);
            setExportStatusData(d);
            setExportId(d?._id);
            setGettingExportId(false);
          }}
        >
          <Spin visible={gettingExportId} />
          {gettingExportId == true ? null : "Export"}
        </Button>
      </BottomPlaceholder>

      {exportData == undefined ? null : (
        <NiceModal
          onClose={() => {
            setExportData(undefined);
          }}
        >
          <LogsInnerPlaceholder>
            {gettingExportId == false ? null : (
              <div>{"Starting export..."}</div>
            )}

            {exportStatusData == undefined ? null : (
              <div>
                <div>
                  {renderNewExportProgressOptimized(
                    exportStatusData,
                    patients,
                    `${exportName}`.replace(".zip", "")
                  )}
                </div>
              </div>
            )}

            {/*<ExportServiceTool {...exportData} />*/}
          </LogsInnerPlaceholder>
        </NiceModal>
      )}

      {error == undefined ? null : (
        <NiceModal
          onClose={() => {
            setError(undefined);
          }}
        >
          <LogsInnerPlaceholder>{error}</LogsInnerPlaceholder>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const LogItem = styled.div`
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
  margin-bottom: 10px;
  font-size: 12px;
  opacity: 0.6;
`;

const TypesPlaceholder = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const LogsInnerPlaceholder = styled.div`
  width: 560px;
  box-sizing: border-box;
  max-height: calc(90vh - 120px);
  overflow-y: auto;
`;

const Wrapper = styled.div``;

const BottomPlaceholder = styled.div`
  margin-top: 20px;
`;

const Label = styled.div`
  font-weight: bold;
`;

const DatesPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Half = styled.div`
  box-sizing: border-box;
  padding: 5px;
  flex: 1;
`;
