import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import ChatGPTAPI from "../../../api/ChatGPTAPI";

export default function GeneralAITool(props) {
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState('');
    const [result, setResult] = useState(undefined);

    return (
        <Wrapper>

            <Row className={'field'}>
                <Label>
                    Instruction
                </Label>
                <textarea value={text}
                          style={{
                              lineHeight: '24px',
                              minHeight: '60px',
                              width: '100%',
                              fontSize: 20
                          }}
                          placeholder={''}
                          onChange={(evt) => {
                              setText(evt.target.value);
                              setResult(undefined);
                          }}/>
            </Row>


            {result != undefined || (text == '')? null :
                <Row>
                    {loading == true ? 'Loading...' :
                        <button onClick={async () => {
                            setLoading(true);
                            setResult(undefined);
                            let pld = await ChatGPTAPI.getGeneralText(text);
                            setResult(pld);
                            setLoading(false);
                        }}>
                            Generate
                        </button>
                    }
                </Row>
            }


            {result == undefined ? null :
                <div>
                    <Label>
                        Result:
                    </Label>
                    <textarea
                        value={result}
                              style={{
                                  lineHeight: '24px',
                                  minHeight: '300px',
                                  width: '100%',
                                  fontSize: '20px'
                              }}
                              placeholder={''}
                    />

                    {/*<div dangerouslySetInnerHTML={{__html: result.replace(/\n/g, '<br/>')}}></div>*/}
                </div>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Row = styled.div`
  margin-bottom: 5px;
`;

const Label = styled.div`
  font-weight: bold;
`;
