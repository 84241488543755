import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

import MmtTemplate from '../templates/MmtTemplate'
import GroupsPanel from "../groups/panels/GroupsPanel";
import DoctorTemplate from "../templates/DoctorTemplate";

function StudyIndexApp() {

    return (
        <DoctorTemplate active={'groups'}>

            <Wrapper>

                <GroupsPanel

                />

            </Wrapper>

        </DoctorTemplate>
    );
}

const Wrapper = styled.div`

`;

export default StudyIndexApp
