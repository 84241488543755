import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import SimpleTrendsChartTool from "./SimpleTrendsChartTool";

export default function TrendsSectionTool(props) {
  const { uuid } = props;

  return (
    <Wrapper>
      <GridItem>
        <Heading className="patient-statistics-heading">Activity</Heading>
        <SimpleTrendsChartTool {...props} dataType={"activity"} />
      </GridItem>
      {window.location.href.indexOf("portal.corsano") > -1 ? null : (
        <GridItem>
          <Heading className="patient-statistics-heading">Sleep</Heading>
          <SimpleTrendsChartTool {...props} dataType={"sleep"} />
        </GridItem>
      )}
      <GridItem>
        <Heading className="patient-statistics-heading">Pulse rate</Heading>
        <SimpleTrendsChartTool {...props} dataType={"heart_rate"} />
      </GridItem>

      {window.location.href.indexOf("portal.corsano") > -1 ? null : (
        <GridItem>
          <Heading className="patient-statistics-heading">HRV</Heading>
          <SimpleTrendsChartTool {...props} dataType={"stress"} />
        </GridItem>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Heading = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 10px;
`;

const GridItem = styled.div`
  padding: 10px;
`;
