import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

import DoctorTemplate from '../templates/DoctorTemplate'
import DoctorDashboardPanel from "../dashboard/panels/DoctorDashboardPanel";
import GroupPanel from "../groups/panels/GroupPanel";
import DoctorCorsanoPatientsPanel from "../bracelets/panels/DoctorCorsanoPatientsPanel";
import {useMappedState} from "redux-react-hook";

function PatientsApp(props) {

    const {
        currentUserObj,
        email,
        isDemo
    } = useMappedState(useCallback(state => {
        let email = state?.auth?.currentUserObj?.usersUser?.email;
        return {
            currentUserObj: state.auth.currentUserObj,
            email: email,
            isDemo: (email == 'demo@corsano.com_')
        }
    }));

    return (
        <DoctorTemplate active={'patients'}>

            <Wrapper>

                <DoctorCorsanoPatientsPanel
                    uuid={currentUserObj?.usersUser?.uuid}
                />

                {/*<GroupPanel*/}

                {/*/>*/}

                {/*<DoctorDashboardPanel/>*/}

            </Wrapper>

        </DoctorTemplate>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default PatientsApp
