import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import { Code } from "react-content-loader";
import DoctorAPI from "../../../api/DoctorAPI";
import VitalParametersEditor from "../tools/VitalParametersEditor";
import GroupsAPI from "../../../api/GroupsAPI";
import { useDispatch, useMappedState } from "redux-react-hook";
import * as UiActions from "../../../redux/actions/UiActions";

export default function StudyVitalParametersSettingsPanel(props) {
  const {
    code,
    isHCPAdmin = false,
    onSaved = () => {},
    isPatientPortal = false,
  } = props;
  const dispatch = useDispatch();

  const [data, setData] = useState(undefined);
  const [tabs, setTabs] = useState(undefined);
  const [dashboard_configuration, setDashboard_configuration] =
    useState(undefined);
  const [dashboard_settings, setDashboard_settings] = useState(
    props.dashboard_settings || {}
  );
  const [loading, setLoading] = useState(false);
  const [braceletVersion, setBraceletVersion] = useState();

  const [alarms_settings, setAlarms_settings] = useState({});
  const [alarms_system, setAlarms_system] = useState();
  const [alarms_base, setAlarms_base] = useState();

  useEffect(() => {
    if (code == undefined) {
      return;
    }
    setLoading(true);
    DoctorAPI.getStudyHealthUserSettings(code).then((d) => {
      console.log("getPatientHealthUserSettings: d = ", d);

      setData(d == undefined ? undefined : d.vital_parameters);
      setTabs(d == undefined ? undefined : d?.ui_settings?.tabs);
      setDashboard_settings(
        d == undefined ? undefined : d?.ui_settings?.dashboard_settings
      );
      setBraceletVersion(
        d == undefined ? undefined : d?.ui_settings?.bracelet_version
      );

      setDashboard_configuration(
        d == undefined ? undefined : d?.ui_settings?.dashboard
      );

      // new paramaters we save
      setAlarms_system(
        d == undefined || !d.ui_settings || !d?.ui_settings?.alarms_system
          ? "none"
          : d?.ui_settings?.alarms_system
      );
      setAlarms_settings(
        d == undefined || !d.ui_settings || !d?.ui_settings?.alarms_settings
          ? {}
          : d?.ui_settings?.alarms_settings
      );
      setAlarms_base(
        d == undefined ||
          !d.ui_settings ||
          !d?.ui_settings?.alarms_base ||
          Object.keys(d?.ui_settings?.alarms_base).length === 0
          ? "raw"
          : d?.ui_settings?.alarms_base
      );

      setLoading(false);
    });
  }, [code]);

  if (data == undefined && loading == true) {
    return <Code />;
  }

  console.log("render: data = ", data);
  console.log("-> render: tabs = ", tabs);
  console.log("alarms_base", alarms_base);
  

  return (
    <Wrapper>
      <VitalParametersEditor
        code={code}
        isHCPAdmin={isHCPAdmin}
        isPatientPortal={isPatientPortal}
        bracelet_version={braceletVersion}
        params={data}
        alarms_enabled={props.alarmsEnabled}
        tabs={tabs}
        dashboard_settings={dashboard_settings}
        dashboard_configuration={dashboard_configuration}
        alarms_settings={alarms_settings}
        alarms_system={alarms_system}
        alarms_base={alarms_base}
        loading={loading}
        // onBraceletVersionChange={async nv => {
        //     setLoading(true);
        //     await DoctorAPI.saveStudyBraceletVersion(code, nv);
        //     setBraceletVersion(nv);
        //     setLoading(false);
        // }}

        onSave={async (
          vitalData,
          tabsData,
          dashboard,
          bracelet_version,
          alarmsEnabled,
          dashboard_settings = {},
          alarms_settings = {},
          alarms_system,
          alarms_base
        ) => {
          console.log("vitalData", alarms_settings, alarms_base);

          setLoading(true);
          if (alarmsEnabled != undefined) {
            await GroupsAPI.updateGroup({
              code: code,
              alarmsEnabled: alarmsEnabled,
            });
          }
          await DoctorAPI.saveStudyHealthUserSettings(code, {
            vital_parameters: vitalData,
            ui_settings: {
              tabs: tabsData,
              dashboard: dashboard,
              bracelet_version: bracelet_version,
              dashboard_settings: dashboard_settings,
              alarms_settings: alarms_settings,
              alarms_system: alarms_system,
              alarms_base: alarms_base,
            },
          });
          setData(vitalData);
          setBraceletVersion(bracelet_version);
          setTabs(tabsData);
          setLoading(false);
          dispatch(UiActions.changeAlarmParams(alarms_settings));
          onSaved();
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
