import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import DoctorAPI from "../../../api/DoctorAPI";

import {Code} from 'react-content-loader'
import moment from "moment/moment";
import EcgRecordPanel from "./EcgRecordPanel";

export default function UserECGRecordsPanel(props) {
    const {
        uuid
    } = props;

    const [records, setRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedId, setSelectedId] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        DoctorAPI.getAllEcgMeasurements(uuid).then(arr => {
            setRecords(arr);
            setLoading(false);
        });
    }, []);

    if (records.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>

            {records.map((a, i) => {
                let {_id, type, start_timestamp, files = []} = a;
                let isSelected = (_id == selectedId);
                return (
                    <SessionItem key={`aa-${a._id}_${i}`} selected={isSelected}>
                        <ItemHeading>
                            <Num style={{cursor: 'pointer'}} onClick={() => {
                                setSelectedId(_id);
                            }}>
                                {+i + 1}
                            </Num>
                            <Start style={{cursor: 'pointer'}} onClick={() => {
                                setSelectedId(_id);
                            }}>
                                {start_timestamp == undefined ? '' : moment(start_timestamp).format('YYYY-MM-DD | HH:mm')}
                            </Start>
                        </ItemHeading>

                        {isSelected == false ? null :
                            <ItemContent>
                                <EcgRecordPanel
                                    userUUID={uuid}
                                    measurementUUID={a?.uuid}
                                />
                            </ItemContent>
                        }

                    </SessionItem>
                )
            })}

        </Wrapper>
    );
}

const ItemContent = styled.div`
  box-sizing: border-box;
  border-top: 1px dashed lightgrey;
  border-bottom: 1px dashed lightgrey;
  border-radius: 4px;
`;

const ItemHeading = styled.div`
  margin-bottom: 2px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  color: #8798AD;
  font-size: 14px;


  :hover {
    opacity: 0.8;
  }
`;

const SessionItem = styled.div`
  border: ${props => (props.selected == true ? '1px solid lightgrey' : 'none')};
  padding: ${props => (props.selected == true ? '5px' : '0px')};
  background: ${props => (props.selected == true ? 'whitesmoke' : 'transparent')};
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
`;

const Wrapper = styled.div`

`;

const Start = styled.div`
  width: 140px;
  margin-right: 40px;
`;


const Stop = styled.div`
  width: 140px;
  margin-right: 40px;
`;

const Num = styled.div`
  width: 20px;
  padding-left: 4px;
  margin-right: 10px;
`;
