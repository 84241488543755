import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import DoctorAPI from "../../../api/DoctorAPI";
import SmartTrendsChart from "./SmartTrendsChart";
import ls from "local-storage";

function getFromToFromOption(opt) {
  function getFormatted(t) {
    return moment(+t).format("YYYY-MM-DD");
  }

  let to = +moment().endOf("day");
  let dd = { to: getFormatted(+to) };
  if (opt == "W") {
    dd.from = getFormatted(+moment(to).add(-1, "week"));
  }
  if (opt == "M") {
    dd.from = getFormatted(+moment(to).add(-1, "month"));
  }
  if (opt == "3M") {
    dd.from = getFormatted(+moment(to).add(-3, "month"));
  }
  if (opt == "6M") {
    dd.from = getFormatted(+moment(to).add(-6, "month"));
  }
  if (opt == "MTD") {
    dd.from = getFormatted(+moment(to).startOf("month").startOf("day"));
  }
  if (opt == "YTD") {
    dd.from = getFormatted(+moment(to).startOf("year").startOf("day"));
  }
  if (opt == "1Y") {
    dd.from = getFormatted(+moment(to).add(-12, "month"));
  }
  return dd;
}

const OPTIONS = [
  {
    label: "WEEK",
    value: "W",
  },
  {
    label: "MONTH",
    value: "M",
  },
  {
    label: "3M",
    value: "3M",
  },
  {
    label: "MTD",
    value: "MTD",
  },
  {
    label: "6M",
    value: "6M",
  },
  {
    label: "YTD",
    value: "YTD",
  },
  {
    label: "1Y",
    value: "1Y",
  },
];

const mergeReadiness = (summaries = []) => {
  let arr = JSON.parse(JSON.stringify(summaries));
  // console.log("mergeReadiness: arr = ", arr);
  for (let i in arr) {
    let { recovery = {}, stress = {} } = arr[i];
    let date1 = recovery?.date;
    let date2 = stress?.date;
    let date = date1 || date2;
    if (date == undefined) {
      continue;
    }
    if (stress != undefined && recovery != undefined) {
      arr[i].stress.readiness = recovery?.value;
    }
  }
  // console.log("after merging: arr = ", arr);
  return arr;
};

export default function SimpleTrendsChartTool(props) {
  const { uuid, dataType = "activity", chartType = "line" } = props;

  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("M");
  const theme = ls.get("theme");
  useEffect(() => {
    if (uuid == undefined) {
      return;
    }
    const { from, to } = getFromToFromOption(selectedOption);
    setLoading(true);
    setSummaries([]);
    DoctorAPI.getUserLightSummariesInRange(uuid, from, to, false).then((d) => {
      // console.log("raw summaries = ", d);
      let d_ = mergeReadiness(d);
      let dArr = d_.map((x) => ({ date: x.date, ...x[dataType] }));
      setSummaries(dArr);
      setLoading(false);
    });
  }, [selectedOption, uuid]);

  return (
    <Wrapper>
      <TopPlaceholder>
        <SwitcherPlaceholder>
          {OPTIONS.map((a, i) => {
            return (
              <SwitchItem
                selected={selectedOption == a.value}
                key={a.value}
                theme={theme}
                onClick={() => {
                  setSelectedOption(a.value);
                }}
              >
                {a.label}
              </SwitchItem>
            );
          })}
        </SwitcherPlaceholder>
      </TopPlaceholder>

      <ChartPlaceholder>
        <ChartInnerPlaceholder style={{ opacity: loading == true ? 0.5 : 1 }}>
          <SmartTrendsChart
            points={summaries}
            loading={loading}
            dataType={dataType}
          />

          {loading == false ? null : (
            <LoaderPlaceholder>
              <LoaderInnerPlaceholder>
                <Spin visible={true} />
              </LoaderInnerPlaceholder>
            </LoaderPlaceholder>
          )}
        </ChartInnerPlaceholder>
      </ChartPlaceholder>
    </Wrapper>
  );
}

const ChartInnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const LoaderPlaceholder = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  box-sizing: border-box;
  z-index: 10;
`;

const LoaderInnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const SwitchItem = styled.div`
  border-bottom: ${(props) =>
    props.selected == true ? "1px solid #4aa4e3" : "1px solid transparent"};
  font-weight: ${(props) => (props.selected == true ? "bold" : "default")};
  cursor: ${(props) => (props.selected == true ? "default" : "pointer")};
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  color: ${(props) =>
    props.selected
      ? props.theme === "dark"
        ? "white"
        : "black"
      : props.theme === "dark"
      ? "#ccc"
      : "black"};

  :hover {
    opacity: 0.7;
  }
`;

const TopPlaceholder = styled.div`
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
`;

const SwitcherPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  box-sizing: border-box;
`;
