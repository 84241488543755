import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import { Code } from "react-content-loader";
import NiceModal from "../../modals/NiceModal";
import GroupsAPI from "../../../api/GroupsAPI";
import UpdateGroupForm from "../forms/UpdateGroupForm";
import CommonHelper from "../../../helpers/CommonHelper";

import pencil from "../../../assets/images/edit_grey.svg";
import trashImage from "../../../assets/images/trash.svg";
import lockImage from "../../../assets/images/key_icon.svg";
import { useMappedState } from "redux-react-hook";
import vitalSettings from "../../../assets/images/vital_settings_2.svg";
import StudyVitalParametersSettingsPanel from "../../vital_parameters/panels/StudyVitalParametersSettingsPanel";
import NiceConfirm from "../../modals/NiceConfirm";
import { toast } from "react-hot-toast";
import FDAMetricsSelectorTool from "../../fake/tools/FDAMetricsSelectorTool";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import { isDebugMode } from "../../../helpers/UiHelper";

export function getLabelsInfoData() {
  let isCM = false;
  let isAfib = false;
  let isPortal = false;
  try {
    isCM =
      window.location.href.indexOf("teams.cardiomood") > -1 ||
      window.location.href.indexOf("coach.cardiomood") > -1;
    isPortal = window.location.href.indexOf("portal") > -1;
    isAfib = window.location.href.indexOf("af.") > -1;
  } catch (exc) {}
  return {
    group_name: isCM
      ? "Group"
      : isPortal == true || isAfib == true
      ? "Department"
      : "Study",
    groups_name: isCM
      ? "Groups"
      : isPortal == true || isAfib == true
      ? "Departments"
      : "Studies",
    add_group_name: isAfib
      ? "Add department"
      : isCM
      ? "Add group"
      : isPortal == true
      ? "Add"
      : "Add study",
    vital_parameters_enabled: isCM ? false : true,
    vouchers_enabled: isCM ? false : true,
    stats_enabled: isAfib ? false : isCM ? true : true,
    live_enabled: isCM ? true : false,
    bracelets_enabled: isCM || isPortal ? false : true,
    export_enabled: isAfib ? false : isCM || isPortal ? false : true,
    invite_enabled: isCM ? true : false,
    sessions_enabled: isCM ? false : true,
    surveys_enabled: isCM ? false : true,
    bioz_enabled: isCM ? false : true,
    spiro_enabled: isCM ? false : true,
    weight_enabled: isCM ? false : true,
    bp_enabled: isCM ? false : true,
    tabs_color: isCM ? "#C00100" : "rgb(1, 194, 169)",
    health_battery_enabled: isDebugMode() ? true : false,
  };
}

export default function GroupsPanel(props) {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const [editId, setEditId] = useState(undefined);
  const [deleteId, setDeleteId] = useState(undefined);
  const [lockId, setLockId] = useState(undefined);
  const [vitalCode, setVitalCode] = useState(undefined);
  const [deleting, setDeleting] = useState(false);

  const { currentUserObj, email, isDemo, theme, role } = useMappedState(
    useCallback((state) => {
      let email = state?.auth?.currentUserObj?.usersUser?.email;
      return {
        currentUserObj: state.auth.currentUserObj,
        email: email,
        isDemo: email == "demo@corsano.com",
        theme: state.ui.theme,
        role: state.ui.role,
      };
    })
  );

  const isHCPAdmin = role === "hcp-admin";

  useEffect(() => {
    setLoading(true);
    GroupsAPI.getGroups().then((arr) => {
      console.log("groups:", arr);

      setGroups(arr.sort((a, b) => +b.timestamp - +a.timestamp));
      setLoading(false);
    });
  }, []);

  if (loading == true && groups.length == 0) {
    return (
      <div>
        {/* <h1>LOADING groupsPanel</h1> */}
        {theme === "dark" ? (
          <Code backgroundColor={"#333"} foregroundColor={"#999"} />
        ) : (
          <Code />
        )}
      </div>
    );
  }

  let editGroup =
    editId == undefined ? undefined : groups.filter((x) => x.id == editId)[0];
  let deletingGroup =
    deleteId == undefined
      ? undefined
      : groups.filter((x) => x.id == deleteId)[0];
  let namingsMap = getLabelsInfoData();
  let vitalGroup =
    vitalCode == undefined
      ? undefined
      : groups.filter((x) => x.code == vitalCode)[0];
  // console.log("ROLES", role);

  return (
    <Wrapper>
      <TopPlaceholder>
        <Heading className="heading">{namingsMap.groups_name}</Heading>
        {isHCPAdmin ? (
          <AddButton
            className="add-button"
            onClick={() => {
              setAddModalVisible(true);
            }}
          >
            {namingsMap.add_group_name}
          </AddButton>
        ) : null}
      </TopPlaceholder>

      <GroupsListPlaceholder>
        {groups.map((a, i) => {
          return (
            <GroupItem key={a.id} className="group-item">
              <ItemLeft>
                <ItemTopPlaceholder>
                  <NamePlaceholder
                    className="group-name"
                    onClick={() => {
                      if (window.location.href.indexOf("portal") > -1) {
                        CommonHelper.linkTo(`/departments/${a.code}`);
                      } else {
                        CommonHelper.linkTo(`/studies/${a.code}`);
                      }
                    }}
                  >
                    {a.name}
                  </NamePlaceholder>
                  {isHCPAdmin ? (
                    <EditImg
                      className="group-info-icon"
                      src={pencil}
                      onClick={() => {
                        setEditId(a.id);
                      }}
                    />
                  ) : null}
                </ItemTopPlaceholder>
                {a.description == undefined || a.description == "" ? null : (
                  <ItemBottomPlaceholder
                    onClick={() => {
                      if (window.location.href.indexOf("portal") > -1) {
                        CommonHelper.linkTo(`/departments/${a.code}`);
                      } else {
                        CommonHelper.linkTo(`/studies/${a.code}`);
                      }
                    }}
                  >
                    {a.description}
                  </ItemBottomPlaceholder>
                )}
              </ItemLeft>
              <ItemRight>
                <CodePlaceholder>
                  {namingsMap.vital_parameters_enabled == false ? null : (
                    <GearImg
                      className="group-info-icon"
                      src={vitalSettings}
                      onClick={() => {
                        setVitalCode(a.code);
                      }}
                    />
                  )}
                  {isHCPAdmin ? (
                    <>
                      <GearImg
                        className="group-info-icon"
                        src={trashImage}
                        onClick={() => {
                          if (isDemo == true) {
                            return window.alert(
                              "You can not delete the demo group."
                            );
                          }
                          setDeleteId(a.id);
                        }}
                      />
                    </>
                  ) : null}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (window.location.href.indexOf("portal") > -1) {
                        CommonHelper.linkTo(`/departments/${a.code}`);
                      } else {
                        CommonHelper.linkTo(`/studies/${a.code}`);
                      }
                    }}
                  >
                    {a.code}
                  </span>
                </CodePlaceholder>
              </ItemRight>
            </GroupItem>
          );
        })}
      </GroupsListPlaceholder>

      {addModalVisible == false ? null : (
        <NiceModal
          onClose={() => {
            setAddModalVisible(false);
          }}
        >
          <AddInnerPlaceholder>
            <UpdateGroupForm
              loading={loading}
              onSave={async (d) => {
                if (isDemo == true) {
                  window.alert(
                    "This is a demo. Please contact admin for full access."
                  );
                  return;
                }
                if (loading == true) {
                  return;
                }
                setLoading(true);
                await GroupsAPI.createGroup({
                  ...d,
                  alarmsEnabled: window.location.href.indexOf("portal") > -1,
                });
                let arr = await GroupsAPI.getGroups();
                setGroups(arr.sort((a, b) => +b.timestamp - +a.timestamp));
                setLoading(false);
                setAddModalVisible(false);
              }}
            />
          </AddInnerPlaceholder>
        </NiceModal>
      )}

      {editGroup == undefined ? null : (
        <NiceModal
          onClose={() => {
            setEditId(undefined);
          }}
        >
          <AddInnerPlaceholder>
            <UpdateGroupForm
              {...editGroup}
              loading={loading}
              onSave={async (d) => {
                if (isDemo == true) {
                  window.alert(
                    "This is a demo. Please contact admin for full access."
                  );
                  return;
                }
                if (loading == true) {
                  return;
                }
                setLoading(true);
                await GroupsAPI.updateGroup({ ...d, code: editGroup.code });
                let arr = await GroupsAPI.getGroups();
                setGroups(arr.sort((a, b) => +b.timestamp - +a.timestamp));
                setLoading(false);
                setEditId(undefined);
              }}
            />
          </AddInnerPlaceholder>
        </NiceModal>
      )}

      {deletingGroup == undefined ? null : (
        <NiceConfirm
          heading={`Deleting study "${deletingGroup?.name}"`}
          subHeading={"Please confirm that you want to delete this study."}
          loading={deleting}
          onConfirm={async () => {
            if (deleting == true) {
              return;
            }
            setDeleting(true);
            let studyName = deletingGroup?.name;
            await GroupsAPI.deleteGroup(deletingGroup?.id);
            setDeleting(false);
            setGroups(groups.filter((x) => x.id != deleteId));
            setDeleteId(undefined);
            toast(`Study "${studyName}" has been deleted`);
          }}
          onCancel={() => {
            setDeleteId(undefined);
          }}
        ></NiceConfirm>
      )}

      {vitalCode == undefined ? null : (
        <NiceModal
          onClose={() => {
            setVitalCode(undefined);
          }}
        >
          <VitalInnerContent>
            <VitalSettingsHeading>
              <span style={{ fontWeight: "bold", fontSize: "24px" }}>
                {`${vitalCode}`}
              </span>

              {/*{window.location.href.indexOf('portal') == -1 ? null :*/}
              {true == false ? null : (
                <div>
                  <div className="setting-label" style={{ padding: 10 }}>
                    <>
                      {`HCP Code:`}

                      <div
                        className="setting-label"
                        style={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        {vitalGroup.hcpCode}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          fontStyle: "italic",
                          opacity: 0.7,
                        }}
                        className="setting-label-deep"
                      >
                        The HCP Code can be updated under Settings/Profile in
                        the Corsano App
                      </div>
                    </>

                    <div
                      style={{ marginTop: 20 }}
                      className="setting-label"
                    >{`Patient Code:`}</div>

                    <div
                      className="setting-label"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      {vitalGroup.code}
                    </div>

                    <div
                      style={{
                        fontSize: "14px",
                        fontStyle: "italic",
                        opacity: 0.7,
                      }}
                      className="setting-label-deep"
                    >
                      Please ask the patient to enter the Patient Code under
                      Settings/Profile in the Corsano App
                    </div>
                  </div>

                  {/*{window.location.href.indexOf("portal") == -1 ? null : role !== "Patient" ? (*/}
                  {/*    <FDAMetricsSelectorTool code={vitalCode} role={role}/>) : null}*/}
                </div>
              )}
              {/*<br/>*/}
              {/*{'Vital parameters'}*/}
            </VitalSettingsHeading>

            {isHCPAdmin ? (
              <VitalPanelPlaceholder>
                <StudyVitalParametersSettingsPanel
                  isPatientPortal={window.location.href.indexOf("portal") > -1}
                  isHCPAdmin={isHCPAdmin}
                  code={vitalCode}
                  {...vitalGroup}
                  groupId={vitalGroup?.id}
                  onSaved={() => {
                    setVitalCode(undefined);
                  }}
                />
              </VitalPanelPlaceholder>
            ) : null}
          </VitalInnerContent>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const VitalPanelPlaceholder = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 15px;
`;

const VitalInnerContent = styled.div`
  width: 820px;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
`;

const ItemBottomPlaceholder = styled.div`
  opacity: 0.8;
  font-size: 12px;
  font-style: italic;
  cursor: pointer;
`;

const VitalSettingsHeading = styled.div`
  text-align: center;
  margin-bottom: 15px;
`;

const NamePlaceholder = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const CodePlaceholder = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1px;
  opacity: 0.7;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GearImg = styled.img`
  height: 26px;
  width: 26px;
  margin-right: 20px;
  opacity: 0.7;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const HcpCodeImg = styled.img`
  height: 22px;
  width: 22px;
  margin-right: 20px;
  opacity: 0.7;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const AddInnerPlaceholder = styled.div`
  width: 600px;
  box-sizing: border-box;
  padding: 20px;
`;

const EditImg = styled.img`
  cursor: pointer;
  opacity: 0.7;
  height: 20px;
  margin-left: 10px;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div``;

const Heading = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 1px;
`;

const AddButton = styled.div`
  background: #147aff;
  height: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: white;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ItemTopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemLeft = styled.div`
  flex: 1;
`;

const ItemRight = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const GroupItem = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: 2px solid rgba(135, 152, 173, 0.15);
  box-shadow: ${(props) =>
    props.danger == true ? "0px 4px 5px rgba(250, 70, 70, 0.05);" : "none"};
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
`;

const GroupsListPlaceholder = styled.div``;
