import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';

import empty_ch from '../../../assets/images/checkbox.svg'
import ch_box from '../../../assets/images/chbx.svg'

// "dashboard": {
//     "activity": true,
//         "sleep": true,
//         "heart_rate": true,
//         "ecg": true,
//         "spo2": true,
//         "respiration": true,
//         "nibp": true,
//         "stress": true,
//         "spirometer": true,
//         "weight": true,
//         "insights": true,
//         "workout": true,
//         "temperature": true,
// },

const ITEMS = [
    {
        label: 'Activity',
        value: 'activity'
    },
    {
        label: 'Sleep',
        value: 'sleep'
    },
    {
        label: 'Pulse Rate',
        value: 'heart_rate'
    },
    {
        label: 'ECG',
        value: 'ecg'
    },
    {
        label: 'Saturation',
        value: 'spo2'
    },
    {
        label: 'Respiration',
        value: 'respiration'
    },
    {
        label: 'Temperature',
        value: 'temperature'
    },
    {
        label: 'NIBP',
        value: 'nibp'
    },
    {
        label: 'Stress',
        value: 'stress'
    },
    {
        label: 'Workout',
        value: 'workout'
    },
    {
        label: 'Spirometer',
        value: 'spirometer'
    },
    {
        label: 'Weight',
        value: 'weight'
    },
    {
        label: 'Insights',
        value: 'insights'
    }
];

export function getEnhSettings(st = {}) {
    let res = {...st};
    for (let i in ITEMS) {
        let v = ITEMS[i];
        if (res[v.value] == undefined) {
            res[v.value] = false;
        }
    }
    return res;
}

export default function DashboardSettingsEditor(props) {
    const {
        code = 'test',
        onChange = d => {

        }
    } = props;

    const [dashboard_settings, setDashboard_settings] = useState(props.dashboard_settings || {});

    useEffect(() => {
        if (props.dashboard_settings != undefined){
            setDashboard_settings(props.dashboard_settings);
        }
    }, [props.dashboard_settings]);

    return (
        <Wrapper>

            <OuterPlaceholder>

                <InnerPlaceholder>

                    {ITEMS.map((a, i) => {
                        let isSelected = (dashboard_settings[a.value] == true);
                        return (
                            <ItemBox selected={isSelected} key={`${i}_${a.value}`} onClick={() => {
                                let dd = {...dashboard_settings};
                                dd[a.value] = !isSelected;
                                setDashboard_settings(getEnhSettings(dd));
                                onChange(getEnhSettings(dd));
                            }}>
                                <ItemCheck src={(isSelected == true) ? ch_box : empty_ch}/>
                                <ItemLabel>
                                    {a.label}
                                </ItemLabel>
                            </ItemBox>
                        )
                    })}

                </InnerPlaceholder>

            </OuterPlaceholder>


            {/*<div>*/}

            {/*    <BottomPlaceholder>*/}
            {/*        <Button onClick={() => {*/}

            {/*        }}>*/}
            {/*            Save*/}
            {/*        </Button>*/}
            {/*    </BottomPlaceholder>*/}

            {/*</div>*/}

        </Wrapper>
    );
}

const OuterPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-left: 40px;
`;


const BottomPlaceholder = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

const Button = styled.div`
  width: 100px;

  background: #147AFF;
  border-radius: 5px;
  height: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;

  font-weight: 800;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.85;
  }
`;


const Wrapper = styled.div`
  margin-top: 0px;
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const ItemLabel = styled.div`

`;

const ItemCheck = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 7px;
`;

const InnerPlaceholder = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: 150px 150px 150px 150px;
`;