import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import DoctorAPI from "../../../api/DoctorAPI";
import moment from "moment";

export default function SimpleAfNsrChart(props) {
  let {
    points = [],
    onMeasurementClick = (mUuid) => {},
    onAlertClick = (x) => {},

    min,
    max,
  } = props;

  // let minVal = 0; // ecg measurements
  // let maxVal = 2; //

  let possibleValues = [
    {
      value: 0,
      label: "ECG:",
    },
    {
      value: 1,
      label: "PPG:",
    },
    {
      value: 2,
      label: "ECG Alert:",
    },
  ];

  let minT = points.reduce((acc, x) => {
    return Math.min(acc, x.t);
  }, +new Date());
  // if (min != undefined) {
  //     minT = Math.min(+min, +minT);
  // }
  let maxT = points.reduce((acc, x) => {
    return Math.max(acc, x.t);
  }, 0);
  if (max != undefined) {
    maxT = Math.max(+max, +maxT);
  }

  let variation = maxT - minT;

  let pointsWithPerc =
    variation == 0
      ? []
      : points.map((x) => {
          return {
            ...x,
            percent: ((1.0 * (x.t - minT)) / (maxT - minT)) * 100.0,
          };
        });

  let daysNum = Math.ceil(variation / (1000 * 60 * 60 * 24));
  let daysLabels = [];
  for (let i = 0; i < daysNum; i++) {
    let t = minT + i * (1000 * 60 * 60 * 24);
    let m = moment(t);
    daysLabels.push(m.format("YYYY-MM-DD"));
  }

  return (
    <Wrapper>
      <InnerPlaceholder>
        <YAxis>
          <YxisLabelsPlaceholder>
            {possibleValues.map((a, i) => {
              return (
                <YLabelPlaceholder
                  className="af-labels"
                  key={i}
                  style={{ bottom: `calc(${50 * a.value}% - 5px)` }}
                >
                  {a.label}
                </YLabelPlaceholder>
              );
            })}
          </YxisLabelsPlaceholder>
        </YAxis>

        <DataPlaceholder>
          {possibleValues.map((a, i) => {
            let fPoints = pointsWithPerc.filter((x) => x.value == a.value);
            return (
              <DataLine
                key={i}
                style={{
                  bottom: `calc(${50 * a.value}% - ${i == 0 ? 0 : 5}px)`,
                }}
              >
                <DataLineInnerPlaceholder>
                  {fPoints.map((x, j) => {
                    let isBubble = x.value == 0 || x.value == 2;
                    return (
                      <React.Fragment key={j}>
                        {isBubble == true ? null : (
                          <div
                            style={{
                              position: "absolute",
                              left: `calc(${x.percent}% - ${DOT_W / 2}px)`,
                              top: `-${DOT_H / 2}px`,
                              width: `${DOT_W}px`,
                              height: `${DOT_H}px`,
                              background: x.color,
                            }}
                          ></div>
                        )}

                        {isBubble == false ? null : (
                          <Bubble
                            className="bubble"
                            style={{
                              position: "absolute",
                              left: `calc(${x.percent}% - ${
                                BUBBLE_SIZE / 2
                              }px)`,
                              top: `-${BUBBLE_SIZE / 2}px`,
                              width: `${BUBBLE_SIZE}px`,
                              height: `${BUBBLE_SIZE}px`,
                              background: x.color,
                            }}
                            onClick={() => {
                              if (x.isMeasurement == true) {
                                onMeasurementClick(x.uuid);
                              } else {
                                onAlertClick(x);
                              }
                            }}
                          ></Bubble>
                        )}
                      </React.Fragment>
                    );
                  })}
                </DataLineInnerPlaceholder>
              </DataLine>
            );
          })}

          <NsrAfibLegend className="af-legend-background">
            <LegendRow>
              <SmallLegendBubble color={"red"} />
              <span className="af-legend">NSR</span>
            </LegendRow>
            <LegendRow>
              <SmallLegendBubble color={"blue"} />
              <span className="af-legend">Afib</span>
            </LegendRow>
          </NsrAfibLegend>
        </DataPlaceholder>

        <XAxis>
          <XxisLabelsPlaceholder>
            {daysLabels.map((a, i) => {
              return (
                <VerticalXLabels
                  className="af-labels"
                  key={i}
                  style={{
                    position: "absolute",
                    left: `calc(${
                      (100 / (daysLabels.length - 1)) * i
                    }% - 40px)`,
                    textAlign: "center",
                  }}
                >
                  {a}
                </VerticalXLabels>
              );
            })}
          </XxisLabelsPlaceholder>
        </XAxis>
      </InnerPlaceholder>
    </Wrapper>
  );
}

const DOT_H = 10;
const DOT_W = 2;

const BUBBLE_SIZE = 12;

const SmallLegendBubble = styled.div`
  background: ${(props) => props.color};
  width: 8px;
  height: 8px;
  border-radius: 1000px;
  margin-right: 5px;
`;

const NsrAfibLegend = styled.div`
  position: absolute;
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  background: white;
  right: 10px;
  top: 10px;
  font-size: 12px;
`;

const LegendRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  :first-of-type {
    margin-bottom: 6px;
  }
`;

const Bubble = styled.div`
  box-sizing: border-box;
  z-index: 3;
  border-radius: 1000px;
  border: 1px solid black;
  cursor: pointer;

  :hover {
    background: green !important;
  }
`;

const DataLine = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
`;

const DataLineInnerPlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  background: lightgrey;
`;

const DataPlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const YLabelPlaceholder = styled.div`
  position: absolute;
  left: -65px;
  font-size: 12px;
  width: 65px;
  min-width: 65px;
  text-align: right;
  padding-right: 5px;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const InnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const XAxis = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 2px;
  background: lightgrey;
`;

const YAxis = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 2px;
  background: lightgrey;
`;

const YxisLabelsPlaceholder = styled.div`
  position: relative;
  height: 100%;
  box-sizing: border-box;
`;

const XxisLabelsPlaceholder = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

const VerticalXLabels = styled.div`
  position: absolute;
  bottom: -50px;
  height: 16px;
  font-size: 12px;
  width: 80px;
  text-align: center;
  box-sizing: border-box;
  transform: rotate(-90deg);
`;
