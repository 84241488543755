import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import DoctorAPI from "../../../api/DoctorAPI";
import NiceModal from "../../modals/NiceModal";
import SimpleGridTool from "../tools/SimpleGridTool";

const MAX_DAY_PPG_POINTS = 25 * 86400;

export function perc2color(perc) {
    let r, g, b = 0;
    if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    } else {
        g = 255;
        r = Math.round(510 - 5.10 * perc);
    }
    let h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
}

export default function PatientCompliancePanel(props) {
    const {
        monthTimestamp,
        id,
        isDemo = false
    } = props;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState(undefined);

    const [slotLoading, setSlotLoading] = useState(false);
    const [slotItems, setSlotItems] = useState([]);

    let selectedSlotDate = (selectedSlot == undefined) ? undefined : selectedSlot.date;

    useEffect(() => {
        if (monthTimestamp == undefined || id == undefined) {
            return null;
        }
        setLoading(true);
        setItems([]);
        setSelectedSlot(undefined);
        DoctorAPI.getPatientComplianceItems(id, monthTimestamp, isDemo).then(arr => {
            console.log('getPatientComplianceItems: --->>> arr = ', arr);
            setLoading(false);
            setItems(arr);
        })
    }, [monthTimestamp, id]);

    useEffect(() => {
        if (selectedSlot == undefined) {
            return;
        }
        setSlotItems([]);
        setSlotLoading(true);
        DoctorAPI.getPatientComplianceSlotStats(id, selectedSlot._id, isDemo).then(pld => {
            console.log('getPatientComplianceSlotStats: pld = ', pld);
            setSlotItems(pld);
            setSlotLoading(false);
        });
    }, [selectedSlotDate]);

    let from = +moment(monthTimestamp).startOf('month').startOf('day');
    let to = +moment(monthTimestamp).startOf('month').add(1, 'months').startOf('day');
    let n = (+to - +from) / 86400000;
    let days = Array.from({length: n}).map((a, i) => (+from + i * 86400000));

    return (
        <Wrapper loading={loading}>
            <InnerWrapper>
                {days.map((a, i) => {
                    let ds = moment(a).format('YYYY-MM-DD');
                    let xVal = items.filter(z => (z.date == ds))[0];
                    let val = xVal?.measurements_count;
                    let perc = (val == undefined) ? undefined : 100.0 * val / MAX_DAY_PPG_POINTS;
                    let bgColor = perc2color(perc);
                    return (
                        <Day key={a} bgColor={perc == undefined ? undefined : bgColor}
                             style={{cursor: (xVal == undefined) ? 'default' : 'pointer'}}
                             onClick={() => {
                                 if (xVal != undefined) {
                                     setSelectedSlot(xVal);
                                 }
                             }}>
                            <TopDayPlaceholder>
                                {moment(a).format('D ddd')}
                            </TopDayPlaceholder>
                            <BottomDayPlaceholder>
                                {val == undefined ? null : `${perc.toFixed(1)}%`}
                            </BottomDayPlaceholder>
                        </Day>
                    )
                })}
            </InnerWrapper>

            {selectedSlot == undefined ? null :
                <NiceModal
                    onClose={() => {
                        setSelectedSlot(undefined);
                    }}>

                    <InnerModal>
                        <SlotHeading>
                            {selectedSlot.date}
                        </SlotHeading>
                        <SlotContentPlaceholder>
                            <SimpleGridTool items={slotItems}
                                            loading={slotLoading}
                            />
                        </SlotContentPlaceholder>
                        <SlotBottom>
                            <OkButton onClick={() => {
                                setSelectedSlot(undefined);
                            }}>
                                {slotLoading == true ? <Spin visible={slotLoading}/> : 'Ok'}
                            </OkButton>
                        </SlotBottom>
                    </InnerModal>
                </NiceModal>
            }
        </Wrapper>
    );
}

const InnerModal = styled.div`
  width: 920px;
  @media (max-width: 980px) {
    width: calc(100vw - 80px);
  }
`;

const OkButton = styled.div`
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 46px;
  border: 1px solid #147AFF;
  border-radius: 5px;
  color: #147AFF;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const SlotContentPlaceholder = styled.div`
  width: 100%;
  height: 420px;
  background-color: whitesmoke;
  @media (max-height: 600px) {
    height: calc(80vh - 20px);
  }
`;

const SlotBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

const SlotHeading = styled.div`
  font-size: 32px;
  line-height: 45px;
  font-weight: bold;
  color: #000F4B;
  text-align: center;
  margin-bottom: 20px;
`;

const Day = styled.div`
  background: #FFFFFF;
  border: 1px solid rgba(135, 152, 173, 0.1);
  box-sizing: border-box;
  padding: 5px;
  text-align: center;
  color: #2E384D;
  font-size: 12px;
  height: 40px;
  background: ${props => (props.bgColor == undefined ? 'white' : props.bgColor)};
`;

const TopDayPlaceholder = styled.div`
  text-align: center;
  color: #2E384D;
`;

const BottomDayPlaceholder = styled.div`
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  opacity: 0.475;
`;

const InnerWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  opacity: ${props => (props.loading == true ? 0.5 : 1)};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  @media (max-width: 920px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  
  @media (max-width: 840px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 720px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 520px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 420px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;