import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {Button} from "../../auth/ui";
import NiceModal from "../../modals/NiceModal";
import axios from 'axios'
import ls from 'local-storage'
import FindUsetTool from "./FindUsetTool";
import ExportHrvValuesTool from "./ExportHrvValuesTool";
import DateTimePicker from "react-datetime-picker";
const API_ENDPOINT = 'https://api.study-integration.corsano.com';

async function getUsers() {
    let pld = (await axios.get(`${API_ENDPOINT}/v1/dictionary_items/melokura/melokura`)).data;
    return (pld?.data?.users || []);
}

async function saveUsers(users = []) {
    await axios.put(`${API_ENDPOINT}/v1/dictionary_items/melokura`, {
        type: 'melokura',
        data: {
            users: users
        }
    });
}

export default function ExportHrvValuesPanel(props) {
    const [users, setUsers] = useState([]);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [from, setFrom] = useState(moment().add(-10, 'days').format('YYYY-MM-DD'));
    const [to, setTo] = useState(moment().format('YYYY-MM-DD'));

    useEffect(() => {
        getUsers().then(arr => {
            setUsers(arr);
        })
    }, []);

    return (
        <Wrapper>

            <TopPlaceholder>
                <DateItem>
                    <DateTimePicker
                        disableClock={true}
                        format={'y-MM-dd'}
                        onChange={d => {
                            let v = moment(d).format('YYYY-MM-DD');
                            setFrom(v);
                        }}
                        value={(from == undefined || from == '') ? new Date() : new Date(from)}
                    />
                </DateItem>
                <DateItem>
                    <DateTimePicker
                        disableClock={true}
                        format={'y-MM-dd'}
                        onChange={d => {
                            let v = moment(d).format('YYYY-MM-DD');
                            setTo(v);
                        }}
                        value={(to == undefined || to == '') ? new Date() : new Date(to)}
                    />
                </DateItem>
            </TopPlaceholder>

            <ContentPlaceholder>
                <ExportHrvValuesTool users={users}
                                     from={from}
                                     to={to}
                                     onUserDelete={async uuid => {
                                         let newUsers = users.filter(x => (x != undefined && x?.uuid != uuid));
                                         // ls('melo_users', newUsers);
                                         await saveUsers(newUsers);
                                         setUsers(newUsers);
                                     }}/>
            </ContentPlaceholder>

            <BottomPlaceholder>
                <Button style={{paddingLeft: 20, paddingRight: 20}} onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Add user
                </Button>
            </BottomPlaceholder>

            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>
                    <InnerPlaceholder>
                        <FindUsetTool onAdd={async d => {
                            console.log('onAdd: d = ', d);
                            if (d == undefined) {
                                setAddModalVisible(false);
                                return window.alert('Not found!');
                            }
                            let newUsers = users.filter(x => (x.uuid != d.uuid)).concat([d]);
                            // ls('melo_users', newUsers);
                            await saveUsers(newUsers);
                            setUsers(newUsers);
                            setAddModalVisible(false);
                        }}/>
                    </InnerPlaceholder>

                </NiceModal>
            }

        </Wrapper>
    );
}

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const DateItem = styled.div`
  padding: 10px;
  margin-left: 15px;
  margin-right: 15px;
`;

const InnerPlaceholder = styled.div`
  width: 520px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const ContentPlaceholder = styled.div`

`;

const BottomPlaceholder = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;