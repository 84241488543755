import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import ls from "local-storage";
import { useDispatch, useMappedState } from "redux-react-hook";
import * as UiActions from "../../../redux/actions/UiActions";

const PARAMETERS = [
  {
    label: "Raw Value",
    value: "raw",
    default: true,
  },
  {
    label: "Baseline",
    value: "baseline",
    default: false,
    disable: true,
  },
];
export default function UserAlarmBaseTool(props) {
  const handleCheckboxChange = (param, value) => {};

  return (
    <div style={{ display: "flex" }}>
      {PARAMETERS.map((state, index) => {
        return (
          <div style={{ marginLeft: "10px", marginRight: "10px" }} key={index}>
            <Label className="setting-text-active" disable={state.disable}>
              <Checkbox
                id={state.value}
                name={`select-alarm-param-${index}`}
                type="checkbox"
                disabled={state.disable}
                checked={state.default}
                // is_news={is_news}
                // color="blue"
                // checked={is_news || alarms_settings[state.value]}
                onChange={(e) => {
                  // console.log(alarmParams[state.value]);
                  //   console.log(state.label, state.value, e.target.checked);
                  handleCheckboxChange(state.value, e.target.checked);
                }}
              />
              {state.label}
            </Label>
          </div>
        );
      })}
    </div>
  );
}

const Label = styled.div`
  // font-weight: bold;
  margin-bottom: 5px;
  opacity: ${(props) => (props.disable ? 0.5 : 0.9)};
`;

const Checkbox = styled.input`
  &[type="checkbox"] {
    accent-color: #1e7efa;
  }
`;
