import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
} from "react";
import styled from "styled-components";
import NiceModal from "../../modals/NiceModal";

import infoImg from "./info_b.svg";

export default function InfoToolBadge(props) {
    const { type = "" } = props;

    const [visible, setVisible] = useState(false);

    let d = getData(type);

    let { title, content } = d;

    return (
        <Wrapper>
            <IconPlaceholder
                onClick={() => {
                    setVisible(true);
                }}
            >
                <Box>
                    <Icon src={infoImg} className="bp-info-icon" />
                </Box>
                <InfoSpan className="bp-info-span">Info</InfoSpan>
            </IconPlaceholder>

            {visible == false ? null : (
                <NiceModal
                    onClose={() => {
                        setVisible(false);
                    }}
                >
                    <DialogInner>
                        {title == undefined || title == "" ? null : (
                            <Heading>{title}</Heading>
                        )}

                        <ContentPlaceholder>
                            <div
                                dangerouslySetInnerHTML={{ __html: content }}
                            ></div>
                        </ContentPlaceholder>
                    </DialogInner>
                </NiceModal>
            )}
        </Wrapper>
    );
}

const Icon = styled.img`
    width: 22px;
    height: 22px;
    cursor: pointer;

    :hover {
        opacity: 0.8;
    }
`;

const Box = styled.div`
    border: 1px solid rgba(135, 152, 173, 0.1);
    -webkit-filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
    filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
    border-radius: 8px;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;

const IconPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const InfoSpan = styled.div`
    color: rgb(20, 122, 255);
    cursor: pointer;
    font-weight: 300;
    margin-right: 15px;
`;

const DialogInner = styled.div`
    width: 620px;
    padding: 10px;
    box-sizing: border-box;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
`;

const Heading = styled.div`
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    color: #000f4b;
`;

const ContentPlaceholder = styled.div`
    font-family: sans-serif;
`;

const Wrapper = styled.div``;

const getData = (type) => {
    let d = INFO_DATA[type];
    if (d == undefined) {
        return DEFAULT;
    }
    return d;
};

const DEFAULT = {
    title: "No data",
    content: `<p>No information</p>`,
};

const INFO_DATA = {
    SLEEP: {
        title: "Sleep",
        content: `<p>
            Some information about sleep
        </p>`,
    },
    HEART_RATE: {
        title: "Pulse rate",
        content: `<p>
            Pulse rate is the speed of the heartbeat measured by the number of contractions of the heart per minute. 
            The heart rate can vary according to the body's physical needs, including the need to absorb oxygen and excrete carbon dioxide. 
            Activities that can provoke change include physical exercise, sleep, anxiety, stress, illness, and ingestion of drugs.
            Pulse rate is measured in beats per minute.
        </p>`,
    },
    BLOOD_PRESSURE: {
        title: "Blood Pressure",
        content: `<div>
            <p>
                Currently, there are two types of warnings / errors implemented in the output of the SBP and DBP values.
            </p> 
            <p>
In case the model is not good enough to provide any AI prediction at all, the AI model returns none values with the following error messages in the "model_error" field: 

<br/>- No initialisations nor history to train model.
<br/>- Not enough historic data to train model
<br/>- Not enough high quality cuff initialisations to train model            
            </p>
    

<p>
In case the model does provide values, but there are some inconsistencies detected for the given model, they are returned in the "model_warnings" field of the output. Currently the following warnings are implemented:
    <br/>
    <br/>- Night dip < 10%
<br/>- Night dip > 30%
<br/>- Signal flatline
<br/>- Capricious signal
<br/>- Not enough night history
<br/>- Not enough day history

</p>




<p>
If you want to discuss SBP and DBP values with Corsano staff, please let us know the voucher ID of the patient, the date and observation or question. Please make sure you only communicate the anonyme voucher ID.
</p> 

        </div>`,
    },

    EMOGRAPHY: {
        title: "",
        content: `
               <h3>
                EmoGraphy
                </h3>
            <p>
                 EmoGraphy provides insight in how we respond to stress. Humans respond to stress in two ways; an immediate response to the stressor (adrenaline release) and a slower response (cortisol release) to recover from that stressor. Stress has a direct impact on our cognitive performance. High cortisol levels may negatively affect cognitive performance such as contemplation, strategic thinking and memory function. Low levels are thought to increase fatigue by making tasks appear tougher, and limiting endurance of sustained physical and mental activities.
            </p>
            <p>
            EmoGraphy provides detailed insights in this mechanism by displaying stress levels in real-time and calculating the related cognitive functioning of the brain – the CognitiveZone - that is impacted by cortisol. Through these measurements and calculations, EmoGraphy can also predict changes in cognitive abilities up to an hour ahead (assuming the user performs similar activities).
            </p>
            <p>
                EmoGraphy is based on skin conductance (EDA); an accurate measurement of sympathetic nervous system activity that reflects the body’s so-called <i>fight</i> or <i>flight</i> response. The Cognitive Zones help the user to understand how to optimize his/her cognitive performances and <i>avoid under- and over-stimulation</i>.
            </p>
            <h3>
                Stress Level
            </h3>
            <p>
                Stress Level reflects the immediate response to a stressor. It indicates the amount of physical (strong exercise, spicy food, or the temperature) and emotional stress of the user at present time.  
            </p>
            <h3>
                Cognitive Zone
            </h3>
            <p>
                Cognitive Zone is based on the slower response (cortisol release) to a stressor. By remaining in a zone of optimal emotional stimulation, you can avoid both drowsiness and too much stress. This is the objective of Cognitive Zone. The Balanced Zone represents the area of emotional fitness, essential for healthy lifestyle and optimal cognitive performance.  
            </p>
            <ul>
                <li>
                    Cognitive Zone is the current cognitive zone at present time.
                </li>
                <li>
                    Cognitive Zone in One Hour is a prediction of the cognitive zone in one hour assuming the user performs similar activities as at present time
                </li>
                <li>
                    Predictive Cognitive Zone is the next predicated cognitive zone in a number of minutes given by the Predictive Cognitive Zone Transition Time.
                </li>
                <li>
                    Predictive Cognitive Zone Transition Time is the time to reach the next predicted cognitive zone
                    <ul>
                        <li>
                            <u>Over stimulated zone</u>
                            <br/>
                            Decreased cognitive performance; strategic thinking, memory, etc. - you might want to do a relaxing exercise to reach the balanced zone for optimal performance
                        </li>
                        <li>
                            <u>Balanced zone</u>
                            <br/>
                            Optimal cognitive performance – to stay in this zone take regular breaks
                        </li>
                        <li>
                            <u>Under stimulated zone</u>
                            <br/>
                            Enhanced perception of effort, reduced endurance of mental activity – you might want to take a walk or activity to stimulate yourself to reach the balanced zone
                        </li>
                    </ul>
                </li>
            </ul>
            
            <h3>
                Cortisol Contribution
            </h3>
            <p>
                The Cognitive Zones are derived from the estimated Cortisol Contribution which is the amount of cortisol that can be accounted for by stress. Cortisol is the most accurate measure of stress but it can only be measured via blood, hair or saliva samples. By calculating the cortisol contributions, Philips is able to determine the easy-to-understand CognitiveZone.
            </p>
`,
    },
};
