import * as types from "../ActionTypes.js";
import ls from "local-storage";

const VALID_THEMES = ["light", "dark"];
const VALID_LAYOUTS = ["normal", "big_tiles", "small_tiles"];
const VALID_ROLES = ["hcp-new", "hcp-approved", "hcp-admin", "patient"];
// const valid_ALARMS = ["none", "flags1", "flags2", "news1", "news2", "corrisk"];
const VALID_TEMP_UNITS = ["C", "F"];

const DEFAULT_THEME = "light";
const DEFAULT_LAYOUT = "normal";
const DEFAULT_ROLE = "hcp-admin";
// const DEFAULT_ROLE = "hcp-approved";
const DEFAULT_ALARM = "none";
const DEFAULT_TEMP_UNIT = "C";
const DEFAULT_PRIORITY = -1;
const DEFAULT_ORDERING = "default"; // could be: "default", "alphabetical", "compliance", "hidden"
const DEFAULT_FILTER = "none"; // "hidden", "none"

const DEFAULT_ALARM_PARAMS = {
  heart_rate: true,
  temperature: true,
  spo2: true,
  systolic_blood_pressure: false,
  respiration_rate: false,
};

const initialState = {
  theme: ls.get("theme") || DEFAULT_THEME,
  layout: ls.get("layout") || DEFAULT_LAYOUT,
  role: DEFAULT_ROLE,
  alarm: ls.get("alarm") || DEFAULT_ALARM,
  unit: ls.get("unit") || DEFAULT_TEMP_UNIT,
  alarmParams: {},
  selectedPriority: DEFAULT_PRIORITY,
  patientsListOrder: ls.get("order") || DEFAULT_ORDERING,
  patientsListFilter: ls.get("filter") || DEFAULT_FILTER,
};

const UiReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.CHANGE_THEME:
      return {
        ...state,
        theme: action.theme,
      };
    case types.CHANGE_LAYOUT:
      return {
        ...state,
        layout: action.layout,
      };
    case types.CHANGE_ROLE:
      return {
        ...state,
        role: action.role,
      };
    case types.CHANGE_ALARM:
      return {
        ...state,
        alarm: action.alarm,
      };
    case types.CHANGE_ALARM_PARAMS:
      return {
        ...state,
        alarmParams: action.alarmParams,
      };
    case types.CHANGE_TEMP_UNIT:
      return {
        ...state,
        unit: action.unit,
      };
    case types.CHANGE_PRIORITY:
      return {
        ...state,
        selectedPriority: action.selectedPriority,
      };
    case types.CHANGE_ORDERING:
      return {
        ...state,
        patientsListOrder: action.patientsListOrder,
      };
    case types.CHANGE_FILTER:
      return {
        ...state,
        patientsListFilter: action.patientsListFilter,
      };
    default:
      return state;
  }
};

export default UiReducer;
