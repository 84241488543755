import axios from 'axios'
import env from "react-dotenv";

import ls from 'local-storage'

const {DOCTORS_API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;
const delay = ms => new Promise(res => setTimeout(res, ms));

const SZobinAPI = {

    async getFilteredPoints(points) {
        let pts = points.filter(x => (x != undefined));
        let n = Math.floor(pts.length / 8);
        pts = pts.slice(0, n * 8);
        let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/ecg/filtered-points`, {
            points: pts
        })).data;
        return pld;
    },

    async checkIfReady(recordId) {
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/v2/szobin/signal/status/get`, {
            params: {
                record_id: recordId
            }
        })).data;
        return (pld.status == 0);
    },

    async runProcessing(recordId) {
        let pld = (await axios.post(`https://ecg-base.cardiomood.com/api/record/processing/start/`, {
            record_id: recordId,
            "source_ref": "https://api.study-integration.corsano.com/v2/szobin/"
        })).data;
        return pld;
    },

    async runProcessingAndWaitForFinishing(recordId) {
        await this.runProcessing(recordId);
        await delay(2000);
        let f = await this.checkIfReady(recordId);
        let k = 0;
        while (f == false && k < 50) {
            f = await this.checkIfReady(recordId);
            k = +k + 1;
            await delay(3000);
        }
    }

}

export default SZobinAPI;
