import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useReducer,
    useMemo,
} from "react";
import moment from "moment";
import styled from "styled-components";
import ls from "local-storage";

export function perc2color(perc) {
    if (perc == undefined || perc == 0) {
        return "transparent";
    }
    let r,
        g,
        b = 0;
    if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
    } else {
        g = 255;
        r = Math.round(510 - 5.1 * perc);
    }
    let h = r * 0x10000 + g * 0x100 + b * 0x1;
    return "#" + ("000000" + h.toString(16)).slice(-6);
}

export default function PatientComplianceTool(props) {
    const {
        date_from,
        date_to,
        onDateClick = (t) => {
        },
        dataMap = {},
        selectedTimestamp,
    } = props;

    // console.log("dataMap" ,dataMap);

    let from = +moment(date_from).startOf("month").startOf("day");
    let to = +moment(date_to).startOf("month").add(1, "months").startOf("day");
    let n = Math.ceil((1.0 * (+to - +from)) / 86400000.0);
    let days = Array.from({length: n})
        .map((a, i) => +from + i * 86400001 + 3 * 3600000)
        .map((x) => +moment(x).startOf("day"));
    const theme = ls.get("theme");
    // console.log('selectedTimestamp = ', selectedTimestamp);

    return (
        <Wrapper>
            <InnerWrapper>
                {days.map((a, i) => {
                    let ds = moment(a).format("YYYY-MM-DD");
                    let perc = dataMap[ds];
                    let bgColor = perc == undefined ? "transparent" : perc2color(perc);

                    return (
                        <Day
                            key={a}
                            theme={theme}
                            className="compliance-date"
                            bgColor={perc == undefined ? undefined : bgColor}
                            style={{
                                cursor: perc == undefined ? "default" : "pointer",
                            }}
                            onClick={() => {
                                if (+moment(+a).startOf("day") > +new Date()) {
                                    return;
                                }
                                onDateClick(+moment(a).startOf("day"));
                            }}
                        >
                            <TopDayPlaceholder
                                style={{
                                    color:
                                        perc == undefined
                                            ? theme === "dark"
                                                ? "white"
                                                : "black"
                                            : "black",
                                    fontWeight:
                                        +moment(selectedTimestamp).startOf("day") ==
                                        +moment(+a).startOf("day")
                                            ? "bold"
                                            : "normal",
                                }}
                            >
                                {moment(+a + 3 * 60 * 60 * 1000).format("D ddd")}
                            </TopDayPlaceholder>
                            <BottomDayPlaceholder
                                style={{
                                    color:
                                        perc == undefined
                                            ? theme === "dark"
                                                ? "white"
                                                : "black"
                                            : "black",
                                }}
                            >
                                {perc == undefined ? null : `${perc.toFixed(1)}%`}
                            </BottomDayPlaceholder>
                        </Day>
                    );
                })}
            </InnerWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
`;

const Day = styled.div`
  background: #ffffff;
  border: 1px solid rgba(135, 152, 173, 0.1);
  box-sizing: border-box;
  padding: 5px;
  text-align: center;
  color: #2e384d;
  font-size: 12px;
  height: 40px;
  background: ${(props) =>
    props.bgColor == undefined
        ? props.theme === "dark"
            ? "#22262c"
            : "white"
        : props.bgColor};
`;

const TopDayPlaceholder = styled.div`
  text-align: center;
  color: #2e384d;
`;

const BottomDayPlaceholder = styled.div`
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  opacity: 0.475;
`;

const InnerWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  opacity: ${(props) => (props.loading == true ? 0.5 : 1)};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

  @media (max-width: 1220px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 920px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 840px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 720px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 520px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 420px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
