import moment from "moment";
import styled from "styled-components";
import ls from "local-storage";
import useDimensions from "react-use-dimensions";

import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
} from "recharts";
import {
  AXES_COLOR,
  AXES_STROKE_WIDTH,
  DEFAULT_LINE_COLOR,
  DOT_STROKE,
  GRID_DASH_ARRAY,
  GRID_STROKE_COLOR,
  GRID_THICKNESS,
  xaxisTimeFormatter,
  xaxisTimeFormatterWithOffset,
} from "../../ui/templates";
import "./style.css";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import DrawHelper from "../../../helpers/DrawHelper";

import energy_img from "../../../assets/images/energy.svg";
import BaselineHelper from "../../../helpers/BaselineHelper";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let tt = payload[0]?.payload?.t;
    let SQI = payload[0]?.payload?.SQI;
    let respiration = payload[0]?.payload?.respiration_rate;
    return (
      <TooltipWrapper>
        {tt == undefined ? null : (
          <TooltipRow>{`${moment(tt).format("HH:mm")}`}</TooltipRow>
        )}
        <TooltipRow>{`Respiration rate: ${respiration.toFixed(0)}`}</TooltipRow>
        <TooltipRow>{`Q: ${SQI.toFixed(0)}`}</TooltipRow>
      </TooltipWrapper>
    );
  }

  return null;
};

const CustomSpo2Tooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let qq = payload[0]?.payload?.quality || payload[0]?.payload?.spo2_q;
    return (
      <TooltipWrapper>
        <TooltipRow className="label">{`${moment(label).format(
          "HH:mm"
        )}`}</TooltipRow>
        <TooltipRow className="label">{`SPO2: ${payload[0].value}`}</TooltipRow>
        {qq == undefined ? null : (
          <TooltipRow className="desc">{`Q: ${qq}`}</TooltipRow>
        )}
      </TooltipWrapper>
    );
  }

  return null;
};

const CustomRespirationTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let qq = payload[0]?.payload?.quality || payload[0]?.payload?.spo2_q;
    return (
      <TooltipWrapper>
        <TooltipRow className="label">{`${moment(label).format(
          "HH:mm"
        )}`}</TooltipRow>
        <TooltipRow className="label">{`Respiration Rate: ${payload[0].value.toFixed(
          0
        )}`}</TooltipRow>

        {/*<TooltipRow className="label">{`${label} : ${payload[0].value}`}</TooltipRow>*/}
        {qq == undefined ? null : (
          <TooltipRow className="desc">{`Q: ${qq.toFixed(0)}`}</TooltipRow>
        )}
      </TooltipWrapper>
    );
  }

  return null;
};

const CustomBpmTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let date = payload[0]?.payload?.date;
    let t = payload[0]?.payload?.t;
    let bpm = payload[0]?.payload?.bpm;
    let dateString = moment(t).format("HH:mm");
    return (
      <TooltipWrapper>
        <TooltipRow className="label">{`${dateString}`}</TooltipRow>
        <TooltipRow>{`Pulse Rate: ${bpm}`}</TooltipRow>
        {/*<div className="label">*/}
        {/*    <span dangerouslySetInnerHTML={{__html: `${date} - <b>${bpm}</b>`}}></span>*/}
        {/*</div>*/}
      </TooltipWrapper>
    );
  }
  return null;
};

const CustomTemperatureTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let date = payload[0]?.payload?.date;
    let temperature = payload[0]?.payload?.temperature;
    let t = payload[0]?.payload?.t;
    let bpm = payload[0]?.payload?.bpm;
    let dateString = moment(t).format("HH:mm");
    return (
      <TooltipWrapper>
        <TooltipRow className="label">{`${date}`}</TooltipRow>
        <TooltipRow>{`Temperature: ${temperature}`}</TooltipRow>
        {/*<div className="label">*/}
        {/*    <span dangerouslySetInnerHTML={{__html: `${date} - <b>${bpm}</b>`}}></span>*/}
        {/*</div>*/}
      </TooltipWrapper>
    );
  }
  return null;
};

function getOffsetFromStartOfTheDay(dateString) {
  let startOfTheDay = moment(dateString).startOf("day");
  let now = moment(dateString);
  return +now.diff(startOfTheDay, "seconds");
}

export default function SimpleBpmSlotsChart(props) {
  const {
    slots = [],
    workouts = [],
    min = 0,
    max = +new Date() + 86400000,
    dayTimestamp = +new Date(),
    noDataText = "...",
    connectNulls = false,

    preserveMinMax = true,
    dataKey = "bpm",
    yDoman,
    hasTooltip = true,
    onWorkoutClick = (w) => {},
    tz,
  } = props;
  // console.log("TZ", tz);

  const [ref, { x, y, width, height }] = useDimensions();
  // const [refx, { width, height }] = useDimensions();
  const [xaxisRef, xaxisSize] = useDimensions();

  const theme = ls.get("theme");
  // let fPoints = slots.filter(x => (+x.t >= +min && +x.t < +max));

  let fPoints = slots;

  // console.log("-->>>> | >>> fPoints = ", fPoints);

  // console.log("SimpleBpmSlotsChart: dataKey, slots = ", dataKey, slots);
  let isSpo2 = dataKey == "spo2";
  let isBpm = dataKey == "bpm";
  let isRespiration = dataKey == "respiration_rate";

  // console.log("fPoints = ", fPoints);
  if (fPoints.length == 0) {
    return (
      <SimpleNoDataMessageTool
        message={"Sorry, there is no heart rate data for this day."}
      />
    );
  }

  // fPoints = [{bpm: fPoints[0].bpm, t: +min}].concat(fPoints).concat([{bpm: fPoints[fPoints.length - 1].bpm, t: +max}])
  // fPoints = [{bpm: null, t: +min}].concat(fPoints).concat([{bpm: null, t: +max}])

  // if (preserveMinMax == true) {
  //     fPoints = [{bpm: null, t: +min, date: moment(+min).format('HH:mm')}].concat(fPoints).concat([{bpm: null, t: +max, date: moment(+max).format('HH:mm')}])
  // }
  //

  if (dayTimestamp == "bpm") {
    fPoints = slots.filter((x) => +x.t >= +min && +x.t < +max);
    fPoints = DrawHelper.getMonotoneForAxisHeartRatePoints(fPoints, min, max);
  }

  if (dataKey == "temperature") {
    console.log("transformed points = ", fPoints);
  }
  if (isSpo2 == true) {
    // console.log("spo2 fPoints = ", fPoints);
    // for (let i in fPoints){
    //     if (fPoints[i].spo2 != undefined && +fPoints[i].spo2 < 92){
    //         delete fPoints[i].spo2;
    //     }
    // }
    // console.log('filtered fPoints = ', fPoints);
  }
  let extraLines = [];
  if (isBpm == true) {
    // console.log('bpm fPoints = ', fPoints);
    for (let i in fPoints) {
      if (fPoints[i].bpm == undefined || fPoints[i].bpm == 0) {
        delete fPoints[i].bpm;
      }
    }
    fPoints = fPoints
      .filter((x) => x.date != undefined && x.date.indexOf(":") > -1)
      .map((xx, i) => {
        return {
          ...xx,
          date_key: +xx.date.split(":")[0] * 60 + +xx.date.split(":")[1],
        };
      });
    if (fPoints.length > 1) {
      let xMap = {};
      for (let i = 1; i < fPoints.length; i++) {
        let delta = fPoints[i].date_key - fPoints[+i - 1].date_key;
        let xKey = `delta-${delta}`;
        if (xMap[xKey] == undefined) {
          xMap[xKey] = {
            xKey: xKey,
            delta: delta,
            number: 0,
          };
        }
        xMap[xKey].number = +xMap[xKey].number + 1;
      }
      let arr = Object.keys(xMap)
        .map((xKey) => xMap[xKey])
        .sort((a, b) => +b.number - +a.number);
      let freqDelta = +arr[0].delta;
      let step_ = +freqDelta == 1 ? 1 : 5;
      let fMap = {};
      let maxVal = Math.max(...fPoints.map((xx) => xx.date_key));
      for (let i in fPoints) {
        let fp = fPoints[i];
        fMap[`x-${fp.date_key}`] = fp;
      }
      let t = 0;
      let rPoints = [];
      while (+t < +maxVal) {
        t = +t + +step_;
        let tKey = `x-${t}`;
        let h_ = Math.floor(+t / 60.0);
        let min_ = +t - h_ * 60;
        let date_ = `${h_}`.padStart(2, "0") + ":" + `${min_}`.padStart(2, "0");
        let val_ = fMap[tKey];
        if (val_ == undefined) {
          val_ = {
            date: date_,
            date_key: t,
          };
        }
        rPoints.push(val_);
      }
      fPoints = rPoints;
    }
    extraLines = DrawHelper.getExtraDottedBpmChartsData(fPoints);
    // extraLines = extraLines.filter(x => (x.points[1].date_key - x.points[0].date_key < 6));
    // extraLines = extraLines.filter(x => (x.points[1].date_key - x.points[0].date_key < 600));
    for (let i in fPoints) {
      let t = fPoints[i].date_key;
      for (let j in extraLines) {
        let eLine = extraLines[j];
        let lp = eLine.points[0];
        let rp = eLine.points[1];
        let isBig = rp.date_key - lp.date_key >= 6;
        let suffix = isBig == true ? "big" : "small";
        if (lp.date_key == t) {
          fPoints[i][`bpm_${j}_${suffix}`] = lp.bpm;
        }
        if (rp.date_key == t) {
          fPoints[i][`bpm_${j}_${suffix}`] = rp.bpm;
        }
      }
    }
    // console.log('filtered fPoints = ', fPoints);
  }

  // console.log("extraLines = ", extraLines);
  // console.log("isRespiration = ", isRespiration);
  // console.log("SimpleBpmSlotsChart: render: workouts = ", workouts);
  const tickNum = 24;
  let xticks;
  let _min;
  let _max;
  if (tz !== undefined) {
    xticks = BaselineHelper.getXaxisTicksEachHourWithTz(min, tz);
    _min = moment(min).add(tz, "hours").valueOf();
    _max = moment(max).add(tz, "hours").valueOf();
  } else {
    xticks = [];
    for (let i = 0; i < tickNum; i++) {
      xticks.push(moment(min).add(i, "hour").valueOf());
    }
    _min = min;
    _max = max;
  }
  // let xticks = BaselineHelper.getXaxisTicksEachHourWithTz(min, tz);

  let yticks = [];
  if (isRespiration) {
    let rrs = fPoints.map((x) => x.respiration_rate);
    let maxRr = Math.max(...rrs) + 5;
    for (let i = 0; i <= maxRr; i += 5) {
      yticks.push(i);
    }
  }
  if (isSpo2) {
    let spo2List = fPoints.map((x) => x.spo2).filter((x) => x !== null);

    let minSpo2 = Math.min(...spo2List);
    let ymin = Math.floor(minSpo2 / 10) * 10;
    if (ymin >= 90) {
      yticks = [80, 85, 90, 95, 100];
    } else if (ymin < 90 && ymin >= 80) {
      yticks = [80, 85, 90, 95, 100];
    } else if (ymin < 80 && ymin >= 70) {
      yticks = [70, 80, 90, 100];
    } else {
      const gap = Math.round((100 - ymin) / 5);
      for (let i = ymin; i <= 100; i += gap) {
        yticks.push(i);
      }
      yticks.push(100);
    }
  }
  // let _min = moment(min).subtract(30, "minutes").valueOf();
  // let _max = moment(max).subtract(30, "minutes").valueOf();
  let _verbalDate = moment(min).format("DD HH:mm");

  // console.log("respiration_rate", fPoints);
  if (isBpm == true) {
    return (
      <Wrapper ref={ref}>
        <LineChart
          width={width}
          height={height}
          data={fPoints}
          connectNulls={true}
          margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
        >
          <CartesianGrid
            stroke={GRID_STROKE_COLOR}
            strokeWidth={GRID_THICKNESS}
            strokeDasharray={GRID_DASH_ARRAY}
          />
          <XAxis
            stroke={theme === "dark" ? "white" : AXES_COLOR}
            // dataKey="date_key"
            ticks={xticks}
            // tickLine={false}
            strokeWidth={AXES_STROKE_WIDTH}
            allowDataOverflow={true}
            dataKey={"timestamp"}
            tickCount={tickNum}
            tickSize={10}
            domain={[_min, _max]}
            interval={0}
            // textAnchor="end"
            tick={{ fontSize: 14 }}
            type={"number"}
            // tickFormatter={xaxisTimeFormatter}
            tickFormatter={(a) => {
              return moment(a).subtract(tz, "hours").format("HH:mm");
            }}
          />
          {hasTooltip == false ? null : (
            <Tooltip content={<CustomBpmTooltip />} />
          )}
          <YAxis
            stroke={theme === "dark" ? "white" : AXES_COLOR}
            domain={yDoman}
            strokeWidth={AXES_STROKE_WIDTH}
          />

          <Line
            type="monotone"
            dataKey={dataKey}
            stroke={DEFAULT_LINE_COLOR}
            strokeWidth={1}
            animationDuration={1}
            dot={false}
            connectNulls={false}
          />

          {extraLines.map((l, i) => {
            return (
              <Line
                key={i}
                type={"monotone"}
                dataKey={`bpm_${i}_small`}
                stroke="#D92A3E"
                animationDuration={0.1}
                strokeWidth={2}
                dot={false}
                connectNulls={true}
              />
            );
          })}

          {extraLines.map((l, i) => {
            return (
              <Line
                key={i}
                type={"monotone"}
                strokeDasharray="3 5"
                dataKey={`bpm_${i}_big`}
                animationDuration={0.1}
                stroke={theme === "dark" ? "#eee" : "#8087A680"}
                strokeWidth={1}
                dot={false}
                connectNulls={true}
              />
            );
          })}

          {/*{extraLines.map((l, i) => {*/}
          {/*    let {points = []} = l;*/}
          {/*    return (*/}
          {/*        <Line type={'monotone'} strokeDasharray="3 2" dataKey={`bpm_${i}_big`} stroke="#8087A6" strokeWidth={2}*/}
          {/*              dot={false} connectNulls={true} />*/}
          {/*    )*/}
          {/*})}*/}

          {/*{extraBigLines.map((l, i) => {*/}
          {/*    let {points = []} = l;*/}
          {/*    return (*/}
          {/*        <Line type={'monotone'} strokeDasharray="3 2" dataKey={`bpm_${i}`} stroke="#8087A6" strokeWidth={2}*/}
          {/*              dot={false} connectNulls={true} />*/}
          {/*    )*/}
          {/*})}*/}
        </LineChart>

        {workouts == undefined || workouts.length == 0 ? null : (
          <div style={{ position: "absolute", left: 0, bottom: 20, right: 0 }}>
            <WorkoutsPlaceholder>
              {workouts.map((a, i) => {
                let seconds = getOffsetFromStartOfTheDay(a.start_date);
                let perc = (100.0 * seconds) / (24 * 60 * 60);
                return (
                  <WorkoutImg
                    src={energy_img}
                    style={{
                      position: "absolute",
                      bottom: "5px",
                      left: `${perc}%`,
                    }}
                    onClick={() => {
                      onWorkoutClick(a);
                    }}
                  />
                );
              })}
            </WorkoutsPlaceholder>
          </div>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper ref={ref}>
      {isSpo2 == false && isRespiration == false ? (
        <LineChart
          width={width}
          height={height}
          data={fPoints}
          connectNulls={true}
          margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
        >
          <XAxis
            dataKey={"t"}
            allowDataOverflow={true}
            stroke={theme === "dark" ? "white" : AXES_COLOR}
            strokeWidth={AXES_STROKE_WIDTH}
            tickCount={tickNum}
            ticks={xticks}
            interval={0}
            type="number"
            // domain={[min, max]}
            domain={[_min, _max]}
            tickFormatter={(a) => {
              return moment(a).subtract(tz, "hours").format("HH:mm");
              // if (`${a}`.length < 8) {
              //   return a;
              // }
              // return moment(a).format("HH:mm");
            }}
          />
          {hasTooltip == false ? null : isSpo2 ||
            dataKey == "respiration_rate" ? (
            <Tooltip content={<CustomSpo2Tooltip />} />
          ) : dataKey == "temperature" ? (
            <Tooltip content={<CustomTemperatureTooltip />} />
          ) : (
            <Tooltip />
          )}
          <YAxis
            domain={yDoman}
            stroke={theme === "dark" ? "white" : AXES_COLOR}
            strokeWidth={AXES_STROKE_WIDTH}
          />
          <CartesianGrid
            stroke={GRID_STROKE_COLOR}
            strokeWidth={GRID_THICKNESS}
            strokeDasharray={GRID_DASH_ARRAY}
          />
          <Line
            type="monotone"
            dataKey={dataKey}
            stroke={DEFAULT_LINE_COLOR}
            strokeWidth={2}
            animationDuration={1}
            dot={false}
            connectNulls={false}
          />
        </LineChart>
      ) : (
        <LineChart
          width={width}
          height={height}
          data={fPoints}
          connectNulls={true}
          margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
        >
          <CartesianGrid
            stroke={GRID_STROKE_COLOR}
            strokeWidth={GRID_THICKNESS}
            strokeDasharray={GRID_DASH_ARRAY}
          />
          <XAxis
            allowDataOverflow={true}
            dataKey="t"
            tickCount={tickNum}
            ticks={xticks}
            interval={0}
            type="number"
            // domain={[min, max]}
            domain={[_min, _max]}
            stroke={theme === "dark" ? "white" : AXES_COLOR}
            strokeWidth={AXES_STROKE_WIDTH}
            tickFormatter={xaxisTimeFormatter}
          />
          {hasTooltip == false ? null : isSpo2 ? (
            <Tooltip content={<CustomSpo2Tooltip />} />
          ) : (
            <Tooltip content={<CustomRespirationTooltip />} />
          )}
          <Line
            type="monotone"
            dataKey={dataKey}
            stroke={DEFAULT_LINE_COLOR}
            strokeDashArray="4 1"
            strokeWidth={1}
            // animationDuration={1}
            isAnimationActive={false}
            dot={DOT_STROKE}
          />
          <YAxis
            domain={([min, max]) => {
              if (isSpo2) {
                return [min, 100];
              } else {
                return [min.toFixed(0), max.toFixed(0)];
              }
            }}
            ticks={yticks}
            // tickCount={8}
            allowDecimals={false}
            dataKey={dataKey}
            // type="number"
            stroke={theme === "dark" ? "white" : AXES_COLOR}
            strokeWidth={AXES_STROKE_WIDTH}
          />
        </LineChart>
      )}
    </Wrapper>
  );
}

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const Spo2TooltipWrapper = styled.div`
  background: white;
  padding: 5px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
`;

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
`;

const WorkoutsPlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
`;

const WorkoutImg = styled.img`
  width: 20px;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
