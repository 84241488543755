import { createGlobalStyle } from "styled-components";
import CommonHelper from "./CommonHelper";
import moment from "moment";

const TarnsformerHelper = {
  // end_date: "2021-01-18T21:35:59"
  // start_date: "2021-01-18T21:35:00"
  // timestamp: 1611005700000
  // type: 1

  // calendar_date: "2021-02-17"
  // created_at: "2021-02-17 21:26:03"
  // data_source_id: null
  // date: "2021-02-17T02:22:14.000+01:00"
  // date_without_tz: "2021-02-17T02:22:14.000"
  // is_converted_from_old_data: false
  // sleep_q: null
  // sleep_state: 1
  // timestamp: 1613524934000
  // updated_at: "2021-02-17 21:26:03"
  // user_id: "600830d0c24dd44bf5214847"
  // _id: "602d89eb85f5d2aaf53d3dfc"

  getSleepPointsFromRawSleepPoints(raw_points: Array<any>): Array<any> {
    let arr: Array<any> = [];
    for (let i = 0; i < raw_points.length - 1; i++) {
      let currPoint = raw_points[i];
      let nextPoint = raw_points[i + 1];
      let start_date = currPoint.date;
      let end_date = nextPoint.date;
      let type = currPoint.sleep_state;
      arr = arr.concat([{ start_date, end_date, type }]);
    }
    return arr;
  },

  get24HoursStepsArray(
    slots: Array<{ date: string; step_count: number; timestamp: number }>
  ): Array<{ time: string; value: number }> {
    // console.log("get24HoursStepsArray occured! slots = ", slots);
    let arr = Array.from({ length: 24 }).map((a, i) => ({
      time: `${i}`.padStart(2, "0") + ":00",
      value: 0,
    }));
    let getItems = (x: string) =>
      slots.filter((aa) => aa.date.split(":")[0] == x.split(":")[0]);
    arr = arr.map((x) => ({
      ...x,
      value: getItems(x.time).reduce((sum, q) => +sum + +q.step_count, 0),
    }));
    return arr;
  },

  getHeightOfSleepBarByType(type: number): number {
    if (type == 1) {
      // AWAKE
      return 4;
    }
    if (type == 2) {
      // LIGHT
      return 2;
    }
    if (type == 3) {
      // DEEP
      return 1;
    }
    if (type == 4) {
      // REM
      return 3;
    }
    return 0;
  },

  getColorOfSleepBar(type: number): string {
    let height = this.getHeightOfSleepBarByType(type);
    if (height == 4) {
      return AWAKE_COLOR;
    }
    if (height == 3) {
      return REM_COLOR;
    }
    if (height == 2) {
      return LIGHT_COLOR;
    }
    if (height == 1) {
      return DEEP_COLOR;
    }
    return AWAKE_COLOR;
  },

  getGroupedSleepPoints(points: any[] = []) {
    // console.log("getGroupedSleepPoints");
    let result: any[] = [];
    if (points.length == 0) {
      return [];
    }

    let currPoint = points[0];
    for (let i in points) {
      let p = points[i];
      if (currPoint.type == p.type) {
        currPoint.end_date = p.end_date;
      } else {
        result.push(currPoint);
        currPoint = p;
      }
    }
    result.push(currPoint);
    result = result.map((x) => ({
      ...x,
      // from: +new Date(x.start_date),
      // to: +new Date(x.end_date),
      from: CommonHelper.getTimestampFromDateString(x.start_date),
      to: CommonHelper.getTimestampFromDateString(x.end_date),
      width:
        +CommonHelper.getTimestampFromDateString(x.end_date) -
        +CommonHelper.getTimestampFromDateString(x.start_date),
    }));
    if (result.length == 0) {
      return [];
    }
    let totalWidth = +result[result.length - 1].to - +result[0].from;
    let globalFrom = +result[0].from;
    if (totalWidth <= 0) {
      return [];
    }
    result = result
      .map((x) => ({
        ...x,
        width_percent: (100.0 * x.width) / totalWidth,
        left_percent: (100.0 * (x.from - globalFrom)) / totalWidth,
      }))
      .map((x) => ({
        ...x,
        height: this.getHeightOfSleepBarByType(x.type),
        color: this.getColorOfSleepBar(x.type),
      }));
    return result;
  },

  getGroupedSleepPointsFullDay(
    points: any[] = [],
    date: string,
    offset: number
  ) {
    const dateFrom: moment.Moment = moment(date);
    const twelveHoursBefore: moment.Moment = dateFrom
      .clone()
      .subtract(12 + offset, "hours");
    let result: any[] = [];
    if (points.length == 0) {
      return [];
    }
    let currPoint = points[0];
    for (let i in points) {
      let p = points[i];
      if (currPoint.type == p.type) {
        currPoint.end_date = p.end_date;
      } else {
        result.push(currPoint);
        currPoint = p;
      }
    }
    result.push(currPoint);
    result = result.map((x) => ({
      ...x,
      // from: +new Date(x.start_date),
      // to: +new Date(x.end_date),
      from: CommonHelper.getTimestampFromDateString(x.start_date),
      to: CommonHelper.getTimestampFromDateString(x.end_date),
      _width:
        +CommonHelper.getTimestampFromDateString(x.end_date) -
        +CommonHelper.getTimestampFromDateString(x.start_date),
      width:
        1000 *
        60 *
        (moment(x.end_date).diff(moment(x.start_date), "minutes") + 1),
    }));
    if (result.length == 0) {
      return [];
    }
    let totalWidth = +result[result.length - 1].to - +result[0].from;
    let globalFrom = +result[0].from;
    if (totalWidth <= 0) {
      return [];
    }
    result = result
      .map((x) => ({
        ...x,
        // _width_percent: (100.0 * x.width) / totalWidth,
        // _left_percent: (100.0 * (x.from - globalFrom)) / totalWidth,
        width_percent:
          (100 *
            (moment(x.end_date).diff(moment(x.start_date), "minutes") + 0)) /
          (24 * 60),
        left_percent:
          (100 * moment(x.from).diff(twelveHoursBefore, "minutes")) / (24 * 60),
      }))
      .map((x) => ({
        ...x,
        height: this.getHeightOfSleepBarByType(x.type),
        color: this.getColorOfSleepBar(x.type),
      }));
    return result;
  },

  getGroupedAISleepPoints(points: any[] = []) {
    // console.log("getGroupedAISleepPoints");
  },
};

const AWAKE_COLOR = "#FC0903";
const REM_COLOR = "#0FA9FC";
const LIGHT_COLOR = "#3763B5";
const DEEP_COLOR = "#374D89";

export default TarnsformerHelper;
