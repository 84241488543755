import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import DoctorAPI from "../../../api/DoctorAPI";
import VitalParametersEditor from "../tools/VitalParametersEditor";

export default function VitalParametersSettingsPanel(props) {
    const {
        userId,
        onSaved = () => {

        }
    } = props;
    const [data, setData] = useState(undefined);
    const [tabs, setTabs] = useState(undefined);
    const [dashboard_configuration, setDashboard_configuration] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userId == undefined) {
            return;
        }
        setLoading(true);
        DoctorAPI.getPatientHealthUserSettings(userId).then(d => {
            console.log('getPatientHealthUserSettings: d = ', d);
            setData(d == undefined ? undefined : d.vital_parameters);
            setTabs(d == undefined ? undefined : d?.ui_settings?.tabs);
            setDashboard_configuration(d == undefined ? undefined : d?.ui_settings?.dashboard);
            setLoading(false);
        });
    }, [userId])

    if (data == undefined && loading == true) {
        return (
            <Code/>
        )
    }

    console.log('VitalParametersSettingsPanel: render: data = ', data);

    return (
        <Wrapper>

            <VitalParametersEditor
                params={data}
                dashboard_configuration={dashboard_configuration}
                tabs={tabs}
                loading={loading}
                onSave={async (vitalData, tabsData, dashboard) => {
                    console.log('--->>> vitalData, tabsData = ', vitalData, tabsData);
                    setData(vitalData);
                    setLoading(true);
                    await DoctorAPI.savePatientHealthUserSettings(userId, {
                        vital_parameters: vitalData,
                        ui_settings: {
                            tabs: tabsData,
                            dashboard: dashboard
                        }
                    });
                    setLoading(false);
                    onSaved();
                }}
            />

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;