import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import ls from "local-storage";
import { useDispatch, useMappedState } from "redux-react-hook";
import * as UiActions from "../../../redux/actions/UiActions";

const PARAMETERS = [
  {
    label: "Pulse Rate",
    value: "heart_rate", // heart_rate
  },
  {
    label: "SpO2",
    value: "spo2", // spo2
  },
  {
    label: "Respiration Rate",
    value: "respiration_rate", // respiration_rate
  },
  {
    label: "Blood Pressure",
    value: "systolic_blood_pressure",
  },
  {
    label: "Temperature",
    value: "temperature",
  },
];

export default function UserAlarmParametersTools(props) {
  const { alarms_settings, alarms_system } = props;
  console.log("alarms_system", alarms_system);

  const is_news =
    alarms_system && alarms_system.includes("news") ? true : false;
  console.log("is_flag", is_news, alarms_system);

  const handleCheckboxChange = (param, value) => {
    // console.log("oldnewParamsSetting", alarmParams);
    let newParamsSetting = {
      ...alarms_settings,
    };
    newParamsSetting[param] = value;

    // console.log("newParamsSetting", newParamsSetting);
    // setSelected([""]);
    // ls.set("alarm-params", newParamsSetting);
    // dispatch(UiActions.changeAlarmParams(newParamsSetting));
    props.onChange(newParamsSetting);
  };

  return (
    <div style={{ display: "flex" }}>
      {PARAMETERS.map((state, index) => {
        return (
          <div style={{ marginLeft: "10px", marginRight: "10px" }} key={index}>
            <Label className="setting-text-active">
              <Checkbox
                id={state.value}
                name={`select-alarm-param-${index}`}
                type="checkbox"
                is_news={is_news}
                color="blue"
                checked={is_news || alarms_settings[state.value]}
                onChange={(e) => {
                  // console.log(alarmParams[state.value]);
                  // console.log(
                  //     state.label,
                  //     state.value,
                  //     e.target.checked
                  // );
                  handleCheckboxChange(state.value, e.target.checked);
                }}
              />
              {state.label}
            </Label>
          </div>
        );
      })}
    </div>
  );
}

const Label = styled.div`
  // font-weight: bold;
  margin-bottom: 5px;
  opacity: 0.9;
`;

const Checkbox = styled.input`
  &[type="checkbox"] {
    accent-color: ${(props) => (props.is_news ? "#ccc" : "#1e7efa")};
  }
`;

const SettingSubHeader = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;
