import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {perc2color} from "../panels/PatientCompliancePanel";
import NiceModal from "../../modals/NiceModal";

export default function SimpleGridTool(props) {
    const {
        items = [],
        loading = false
    } = props;

    const [selectedTime, setSelectedTime] = useState(undefined);

    let map = items.reduce((mp, x) => ({...mp, [x.time]: x.measurements_count}), {});
    let HOURS = Array.from({length: 24}).map((a, i) => i);
    let MINUTES_SLOTS = Array.from({length: 12}).map((a, i) => (i * 5));

    return (
        <Wrapper>
            {MINUTES_SLOTS.map((m, i) => {
                return (
                    <Row key={i}>
                        {HOURS.map((h, j) => {
                            let hS = `${h}`.padStart(2, '0');
                            let mS = `${m}`.padStart(2, '0');
                            let s = `${hS}:${mS}`;
                            let vv = map[s];
                            let perc = (vv == undefined) ? undefined : (100.0 * vv / (25 * 5 * 60));
                            let bgColor = perc2color(perc);
                            return (
                                <Day key={j} bgColor={bgColor} onClick={() => {
                                    setSelectedTime(s);
                                }}>
                                    <div style={{fontWeight: 'bold'}}>
                                        {s}
                                    </div>
                                    {vv == undefined ? null :
                                        <div style={{
                                            fontSize: 5,
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            <span style={{fontSize: 5, marginRight: 4}}>
                                                {`${perc.toFixed(0)}%`}
                                            </span>
                                            <span style={{fontWeight: 'normal'}}>
                                                {`${vv}`}
                                            </span>
                                        </div>
                                    }
                                </Day>
                            )
                        })}
                    </Row>
                )
            })}

            {selectedTime == undefined ? null :
                <NiceModal onClose={() => {
                    setSelectedTime(undefined)
                }}>

                    <TimeDialogInner>

                        <TimeDialogHeading>
                            {selectedTime}
                        </TimeDialogHeading>

                        <div>

                        </div>

                    </TimeDialogInner>

                </NiceModal>
            }

        </Wrapper>
    );
}

const TimeDialogHeading = styled.div`
  text-align: center;
`;

const TimeDialogInner = styled.div`
  width: 520px;
  background: white;
  padding: 20px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: calc(8.3% - 0px);
  box-sizing: border-box;
`;

const Day = styled.div`
  flex: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  opacity: 0.5;
  border: 0.5px solid white;
  background-color: ${props => (props.bgColor == undefined ? 'transparent' : props.bgColor)};
`;