import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import CommonHelper from "../../../helpers/CommonHelper";

import './style.css'

function getDatesInRange(dateFrom, dateTo) {
    let dates = [];
    let d = dateFrom;
    while (d < dateTo) {
        dates.push(d);
        d = moment(d, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD');
    }
    return dates;
}

function getSummariesInDatesRange(summaries = [], dateFrom, dateTo) {
    let dates = getDatesInRange(dateFrom, dateTo);
    return summaries.filter(x => (dates.indexOf(x?.date) > -1));
}

function getValuesInDatesRange(summaries, dateFrom, dateTo, valueName) {
    let filteredSummaries = getSummariesInDatesRange(summaries, dateFrom, dateTo);
    let vArr = valueName.split('.');
    return filteredSummaries.map(x => {
        return vArr.reduce((acc, cur) => {
            return (acc == undefined ? {} : acc)[cur];
        }, x);
    }).filter(x => (x != undefined));
}

function getAverageValuesInDatesRange(summaries, dateFrom, dateTo, valueName) {
    let values = getValuesInDatesRange(summaries, dateFrom, dateTo, valueName);
    if (values.length == 0) {
        return 0;
    }
    return values.reduce((acc, cur) => (+acc + +cur), 0) / values.length;
}

function getDiff(a, b) {
    if (a == undefined || b == undefined || a == 0) {
        return undefined;
    }
    let diff = (+b - +a);
    return (100.0 * (+diff) / (+a)).toFixed(1);
}

export default function StatsToolTable(props) {
    const {
        users = [],
        paramName = 'activity.total_steps',
        hasDiff = true,
        fixedNumber = 0,
        hasPrev = false,
        title,
        tag,
        formatter = x => {
            return x;
        }
    } = props;

    return (
        <Wrapper>

            {(title == undefined || title == '') ? null :
                <TableTitle>
                    {title}
                </TableTitle>
            }

            <Table>

                <Thead>
                    <Tr>
                        <Th>
                            User
                        </Th>
                        <Th>
                            Yesterday
                        </Th>
                        <Th>
                            Last 7d
                        </Th>
                        {hasPrev == false ? null :
                            <Th>
                                Prev 7d
                            </Th>
                        }

                        {hasDiff == false ? null :
                            <Th>
                                Last 7d vs
                                <br/>
                                Prev 7d
                            </Th>
                        }
                        <Th>
                            Last 30d
                        </Th>
                        {hasPrev == false ? null :
                            <Th>
                                Prev 30d
                            </Th>
                        }
                        {hasDiff == false ? null :
                            <Th>
                                Last 30d vs
                                <br/>
                                Prev 30d
                            </Th>
                        }

                    </Tr>
                </Thead>

                <Tbody>

                    {users.map((a, i) => {
                        let summaries = a.summaries || [];
                        let yesterdayValue = getAverageValuesInDatesRange(summaries, moment().add(-1, 'day').format('YYYY-MM-DD'), moment().add(-1 + 1, 'day').format('YYYY-MM-DD'), paramName);
                        let sevenDaysValue = getAverageValuesInDatesRange(summaries, moment().add(-8, 'day').format('YYYY-MM-DD'), moment().add(-1 + 1, 'day').format('YYYY-MM-DD'), paramName);
                        let sevenDaysBeforeValue = getAverageValuesInDatesRange(summaries, moment().add(-1 - 7 - 7 + 1, 'day').format('YYYY-MM-DD'), moment().add(-1 - 7 + 1, 'day').format('YYYY-MM-DD'), paramName);
                        let thirtyDaysValue = getAverageValuesInDatesRange(summaries, moment().add(-1 - 30 + 1, 'day').format('YYYY-MM-DD'), moment().add(-1 + 1, 'day').format('YYYY-MM-DD'), paramName);
                        let thirtyDaysBeforeValue = getAverageValuesInDatesRange(summaries, moment().add(-1 - 30 - 30 + 1, 'day').format('YYYY-MM-DD'), moment().add(-1 - 30 + 1, 'day').format('YYYY-MM-DD'), paramName);

                        let diff7 = getDiff(sevenDaysBeforeValue, sevenDaysValue);
                        let diff30 = getDiff(thirtyDaysBeforeValue, thirtyDaysValue);

                        let diff7String = (diff7 == undefined || diff7 == '') ? '' : `${diff7 > 0 ? '+' : ''}${diff7}%`;
                        let diff30String = (diff30 == undefined || diff30 == '') ? '' : `${diff30 > 0 ? '+' : ''}${diff30}%`;

                        let diff7ClassName = (diff7String.indexOf('+') > -1 ? 'green' : (diff7String.indexOf('-') > -1 ? 'red' : ''));
                        let diff30ClassName = (diff30String.indexOf('+') > -1 ? 'green' : (diff30String.indexOf('-') > -1 ? 'red' : ''));

                        return (
                            <Tr key={a.uuid}>
                                <SmallTd >
                                    <span style={{cursor: 'pointer'}} onClick={() => {
                                        CommonHelper.linkTo(`/studies/${tag}/user/${a.uuid}`, true);
                                    }} >
                                        {CommonHelper.getPatientHeadingString(a)}
                                    </span>
                                </SmallTd>
                                <SmallTd>
                                    {yesterdayValue == undefined ? '' : formatter((+yesterdayValue).toFixed(fixedNumber))}
                                </SmallTd>
                                <SmallTd>
                                    {sevenDaysValue == undefined ? '' : formatter((+sevenDaysValue).toFixed(fixedNumber))}
                                </SmallTd>
                                {hasPrev == false ? null :
                                    <SmallTd>
                                        {sevenDaysBeforeValue == undefined ? '' : (+sevenDaysBeforeValue).toFixed(fixedNumber)}
                                    </SmallTd>
                                }


                                {hasDiff == false ? null :
                                    <SmallTd className={diff7ClassName}>
                                        {diff7String}
                                    </SmallTd>
                                }

                                <SmallTd>
                                    {thirtyDaysValue == undefined ? '' : formatter((+thirtyDaysValue).toFixed(fixedNumber))}
                                </SmallTd>

                                {hasPrev == false ? null :
                                    <SmallTd>
                                        {thirtyDaysBeforeValue == undefined ? '' : formatter((+thirtyDaysBeforeValue).toFixed(fixedNumber))}
                                    </SmallTd>
                                }

                                {hasDiff == false ? null :
                                    <SmallTd className={diff30ClassName}>
                                        {diff30String}
                                    </SmallTd>
                                }
                            </Tr>
                        )
                    })}

                </Tbody>

            </Table>

        </Wrapper>
    );
}

const TableTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 5px;
`;

const Wrapper = styled.div`
  padding: 15px;
  background: white;
  border-radius: 4px;
`;


const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr`
  height: 40px;
`;

const Th = styled.th`
  border: 1px solid lightgrey;
  text-align: left;
  font-weight: bold;
  padding-left: 7px;
  opacity: 0.8;
  font-size: 14px;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
  padding-left: 7px;
`;

const SmallTd = styled(Td)`
    font-size: 14px;
`;

const Thead = styled.thead`
  background: #F5F5F5;
`;

const Tbody = styled.tbody`

`;