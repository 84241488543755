import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import DoctorAPI from "../../../api/DoctorAPI";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { Code } from "react-content-loader";

export default function PatientMPITool(props) {
  const { uuid, t, windowSec = 10 * 60 } = props;

  const [activityPoints, setActivityPoints] = useState([]);
  const [temperaturePoints, setTemperaturePoints] = useState([]);
  const [hrvPoints, setHrvPoints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downActivityPoints, setDownActivityPoints] = useState([]);

  useEffect(() => {
    setLoading(true);
    let to = +moment(+t).endOf("day");
    let from = +moment(+t).startOf("day").add(-10, "day");

    DoctorAPI.getAggregatedPoints(
      uuid,
      "activity",
      ["bpm", "last_steps", "respiration_rate", "spo2"],
      windowSec,
      from,
      to
    ).then((data) => {
      setActivityPoints(data);
      setLoading(false);
    });
    DoctorAPI.getAggregatedPoints(
      uuid,
      "temperature",
      ["temp_sk1", "temp_sk2", "temp_amb"],
      windowSec,
      from,
      to
    ).then((data) => {
      setTemperaturePoints(data);
    });
    DoctorAPI.getAggregatedPoints(
      uuid,
      "heart-rate-variability",
      ["rmssd"],
      windowSec,
      from,
      to
    ).then((data) => {
      setHrvPoints(data);
    });
    DoctorAPI.getAggregatedPoints(
      uuid,
      "activity",
      ["bpm"],
      windowSec * 10,
      from,
      to
    ).then((data) => {
      setDownActivityPoints(data);
      setLoading(false);
    });
  }, []);

  let downBpmPoints = (downActivityPoints || [])
    .filter((x) => x?.bpm != undefined)
    .map((x) => x.bpm);
  let bpmPoints = (activityPoints || [])
    .filter((x) => x?.bpm != undefined)
    .map((x) => x.bpm);
  let maxBpm = bpmPoints.length == 0 ? 100 : Math.max(...bpmPoints);
  let bpmRange = [30, Math.ceil(Math.floor(maxBpm + 10) / 10.0) * 10];

  // let maxHr = (activityPoints == undefined || activityPoints.)

  if (loading == true) {
    return <Code />;
  }

  return (
    <Wrapper>
      <Row>
        <RowHeading>PR</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={activityPoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey={"slot"}
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
            />
            <Tooltip />
            <YAxis domain={bpmRange} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey={"bpm"}
              stroke="#D92A3E"
              strokeWidth={2}
              animationDuration={1}
              dot={false}
              connectNulls={false}
            />
          </LineChart>
        </ChartPlaceholder>
      </Row>

      <Row>
        <RowHeading>HRV</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={hrvPoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey={"slot"}
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
            />
            <Tooltip />
            <YAxis />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey={"rmssd"}
              stroke="#D92A3E"
              strokeWidth={2}
              animationDuration={1}
              dot={false}
              connectNulls={false}
            />
          </LineChart>
        </ChartPlaceholder>
      </Row>

      <Row>
        <RowHeading>SPO2</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={activityPoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey={"slot"}
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
            />
            <Tooltip />
            <YAxis domain={[70, 100]} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey={"spo2"}
              stroke="#D92A3E"
              strokeWidth={1}
              animationDuration={1}
              dot={{ stroke: "#D92A3E", strokeWidth: 1 }}
              connectNulls={true}
            />
          </LineChart>
        </ChartPlaceholder>
      </Row>

      <Row>
        <RowHeading>Respiration Rate</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={activityPoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey={"slot"}
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
            />
            <Tooltip />
            <YAxis />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey={"respiration_rate"}
              stroke="#D92A3E"
              strokeWidth={2}
              animationDuration={1}
              dot={false}
              connectNulls={false}
            />
          </LineChart>
        </ChartPlaceholder>
      </Row>

      <Row>
        <RowHeading>CBT</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={temperaturePoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey="slot"
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
            />
            <Tooltip />
            <YAxis domain={[35, 42]} />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey={"temp_sk1"}
              stroke="#D92A3E"
              strokeWidth={2}
              animationDuration={1}
              dot={false}
              connectNulls={false}
            />
          </LineChart>
        </ChartPlaceholder>
      </Row>

      <Row>
        <RowHeading>Steps</RowHeading>
        <ChartPlaceholder>
          <LineChart
            width={1200}
            height={200}
            data={activityPoints}
            connectNulls={true}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <XAxis
              dataKey={"slot"}
              tickFormatter={(a) => {
                return moment(a).format("DD.MM");
              }}
            />
            <Tooltip />
            <YAxis />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey={"last_steps"}
              stroke="#D92A3E"
              strokeWidth={2}
              animationDuration={1}
              dot={false}
              connectNulls={false}
            />
          </LineChart>
        </ChartPlaceholder>
      </Row>
    </Wrapper>
  );
}

const Row = styled.div`
  margin-bottom: 10px;
`;

const RowHeading = styled.div``;

const ChartPlaceholder = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
`;
