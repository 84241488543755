import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import pencilImage from "../../notes/panels/clarity_edit-solid.svg";
import trashImage from "../../../assets/images/trash.svg";
import NiceModal from "../../modals/NiceModal";
import UpdateGatewayForm from "../forms/UpdateGatewayForm";
import GatewaysAPI from "../../../api/GatewaysAPI";
import { useInterval } from "../../apps/RouterApp";

import * as actions from "../../../redux/actions/GatewaysActions";

export default function DoctorGatewaysPanel(props) {
  const { uuid } = props;

  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [selectedId, setSelectedId] = useState(undefined);
  const [createOpen, setCreateOpen] = useState(false);
  const [deletingId, setDeletingId] = useState(undefined);
  const [onlineMap, setOnlineMap] = useState({});

  const { gateways, loading } = useMappedState(
    useCallback((state) => {
      let email = state?.auth?.currentUserObj?.usersUser?.email;
      return {
        currentUserObj: state.auth.currentUserObj,
        email: email,
        loading: state.gateways.loading,
        isDemo: email == "demo@corsano.com_",
        gateways: state.gateways.gatewaysMap
          .toArray()
          .sort((a, b) => +b.timestamp - +a.timestamp),
      };
    })
  );

  useEffect(() => {
    dispatch(actions.loadAllGateways(uuid)).then(() => {
      console.log("gws loaded!");
      GatewaysAPI.getDoctorGatewaysOnlineMap(uuid).then((mp) => {
        setOnlineMap(mp);
      });
    });
  }, [uuid]);

  useInterval(() => {
    GatewaysAPI.getDoctorGatewaysOnlineMap(uuid).then((mp) => {
      setOnlineMap(mp);
    });
  }, 30 * 1000);

  if (gateways.length == 0 && loading == true) {
    return <Code />;
  }
  let selectedItem =
    selectedId == undefined
      ? undefined
      : gateways.filter((x) => x?.id == selectedId)[0];

  return (
    <Wrapper>
      <TopPlaceholder>
        <TopLeft>
          <TopHeading className="heading">Gateways</TopHeading>
        </TopLeft>
        <TopRight>
          <Button
            onClick={() => {
              setCreateOpen(true);
            }}
          >
            Add new
          </Button>
        </TopRight>
      </TopPlaceholder>

      {gateways.map((a, i) => {
        let isDeleting = a.id == deletingId;
        let lastSeen = onlineMap[a?.id];
        return (
          <BraceletItem key={a.id} className="gateway-item">
            <BraceletInner>
              <BrLeft>
                <BrName>
                  {a?.name}
                  {/*<div style={{fontSize: 12, opacity: 0.4}}>*/}
                  {/*    {`${a.id} | ${a.serialNumber}`}*/}
                  {/*</div>*/}
                </BrName>
              </BrLeft>

              <BrRight>
                {lastSeen == undefined ? null : (
                  <div
                    style={{ fontSize: 14, opacity: 0.4, marginRight: 20 }}
                    hint={moment(lastSeen).format("DD.MM.YYYY HH:mm:ss")}
                    className="item-misc"
                  >
                    {`last data ${moment(lastSeen).fromNow()}`}
                  </div>
                )}
                <ContrItem>
                  <Spin visible={isDeleting} />
                  {isDeleting == true ? null : (
                    <NoteEditIcon
                      className="chevron-icon"
                      style={{ height: 28, marginRight: 20 }}
                      src={trashImage}
                      onClick={async () => {
                        if (loading == true) {
                          return;
                        }
                        if (
                          window.confirm(
                            "Are you sure you want to delete the gateway?"
                          ) == false
                        ) {
                          return;
                        }
                        setDeletingId(a?.id);
                        await dispatch(actions.deleteGateway(uuid, a?.id));
                        setDeletingId(undefined);
                      }}
                    />
                  )}
                </ContrItem>
                <ContrItem>
                  <NoteEditIcon
                    src={pencilImage}
                    className="chevron-icon"
                    onClick={() => {
                      setSelectedId(a?.id);
                    }}
                  />
                </ContrItem>
              </BrRight>
            </BraceletInner>
          </BraceletItem>
        );
      })}

      {selectedItem == undefined ? null : (
        <NiceModal
          onClose={() => {
            setSelectedId(undefined);
          }}
        >
          <DialogInner2>
            <UpdateGatewayForm
              {...selectedItem}
              loading={loading}
              onSave={async (d) => {
                if (loading == true) {
                  return;
                }
                await dispatch(
                  actions.updateGateway(uuid, { id: selectedItem.id, ...d })
                );
                setSelectedId(undefined);
              }}
            />
          </DialogInner2>
        </NiceModal>
      )}

      {createOpen == false ? null : (
        <NiceModal
          onClose={() => {
            setCreateOpen(false);
          }}
        >
          <DialogInner2>
            <UpdateNoteHeading>{"New Gateway"}</UpdateNoteHeading>

            <UpdateGatewayForm
              loading={loading}
              onSave={async (d) => {
                if (loading == true) {
                  return;
                }
                await dispatch(actions.createGateway(uuid, { ...d }));
                setCreateOpen(false);
              }}
            />
          </DialogInner2>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const SubInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-align: center;
`;

const TopHeading = styled.div`
  color: #000f4b;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  letter-spacing: 1px;
`;

const TopPlaceholder = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeft = styled.div``;

const TopRight = styled.div``;

const Button = styled.div`
  width: 200px;

  background: #147aff;
  border-radius: 5px;
  height: 48px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;

  font-weight: 800;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  :hover {
    opacity: 0.85;
  }
`;

const UpdateNoteHeading = styled.div`
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 10px;
`;

const DialogInner2 = styled.div`
  width: 600px;
`;

const NoteEditIcon = styled.img`
  height: 22px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

const Wrapper = styled.div``;

const BrType = styled.div`
  margin-left: 20px;
`;

const BraceletInner = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BrLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BrName = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
`;

const BrRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContrItem = styled.div``;

const normalBorder = `1px solid rgba(135, 152, 173, 0.15)`;
const dangerBorder = `2px solid #FA4646;`;

const BraceletItem = styled.div`
  border-radius: 4px;
  background: #ffffff;
  border: ${(props) => (props.danger == true ? dangerBorder : normalBorder)};
  box-shadow: ${(props) =>
    props.danger == true ? "0px 4px 5px rgba(250, 70, 70, 0.05);" : "none"};
  box-sizing: border-box;
  margin-bottom: 20px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;
