import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import EcgProcessingAPI from "../../../api/EcgProcessingAPI";
import { Document, Page } from 'react-pdf';

async function loadOneFile(uuid, mediaId) {
    let pld = await EcgProcessingAPI.getMediaLink(uuid, mediaId);
    return pld?.link;
}

async function loadManyFiles(uuid, file_ids = []) {
    let arr = await Promise.all(file_ids.map(x => loadOneFile(uuid, x)));
    arr = arr.filter(x => (x != undefined));
    return arr;
}

export default function SimplePdfViewer(props) {
    const {
        uuid,
        file_ids = [],
        isImage = false
    } = props;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        loadManyFiles(uuid, file_ids).then(x => {
            setData(x);
            setLoading(false);
        })
    }, [uuid]);

    if (loading == true) {
        return (
            <Code/>
        )
    }

    return (
        <Wrapper>

            {data.map((x, i) => {
                return (
                    <div key={`x-${i}`}>
                        {isImage == true ?
                            <img src={x} style={{width: '580px'}} /> :
                            <>
                                <Document className={'corsano_pdf_doc'} file={x.replace('cloudconnect-uploads-prod.s3.eu-central-1.amazonaws.com', 'files.corsano.com')} >
                                    <Page width={600} pageNumber={1} />
                                </Document>
                            </>
                        }
                    </div>
                )
            })}

            <div className={'no-print'} style={{textAlign: 'center', padding: '7px'}} >
                            <span style={{cursor: 'pointer'}} onClick={() => {
                                try{
                                    window.print();
                                }catch(exc){

                                }
                            }} >
                                Print
                            </span>
            </div>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;