import axios from "axios";
import env from "react-dotenv";

import ls from "local-storage";

const { DOCTORS_API_ENDPOINT = "https://api.study-integration.corsano.com" } =
  env;

const GroupsAPI = {
  async getSpotsMeasurements(tag) {
    try {
      let pld = (
        await axios.get(
          `${DOCTORS_API_ENDPOINT}/groups/${tag}/latest_spot_measurements`
        )
      ).data;
      return pld;
    } catch (err) {
      return err;
    }
  },
  async getGroups() {
    let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/groups`)).data.result;
    return pld;
  },

  async getGroupById(id) {
    let groups = await this.getGroups();
    let arr = groups.filter((x) => x.id == id);
    return arr[0];
  },

  async getGroupByTag(code) {
    console.log("getGroupByTag occured: code = ", code);
    let gr = (await axios.get(`${DOCTORS_API_ENDPOINT}/groups/tag/${code}`))
      .data.result;
    return gr;
  },

  async getUsersByTag(tag) {
    let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/users/by-tag/${tag}`))
      .data.result;
    return pld;
  },

  async getComplianceByTag(tag, date) {
    let pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/groups/${tag}/compliance/${date}`
      )
    ).data;
    return pld;
  },

  async getCuffBpByTag(tag) {
    let pld = (
      await axios.get(
        // `${DOCTORS_API_ENDPOINT}/groups/${tag}/blood-pressure-cuff-measurements-count`
        `${DOCTORS_API_ENDPOINT}/groups/${tag}/blood-pressure-sessions-count`
      )
    ).data;
    return pld;
  },

  async updateGroup(data) {
    let pld = (
      await axios.put(`${DOCTORS_API_ENDPOINT}/groups`, {
        ...data,
      })
    ).data.result;
    return pld;
  },

  async createGroup(data) {
    let pld = (
      await axios.post(`${DOCTORS_API_ENDPOINT}/groups`, {
        ...data,
      })
    ).data.result;
    return pld;
  },

  async deleteGroup(id) {
    await axios.delete(`${DOCTORS_API_ENDPOINT}/groups/${id}`);
  },

  async getGroupAlarmsHistory(code, from, to) {
    try {
      let pld = (
        await axios.get(
          `${DOCTORS_API_ENDPOINT}/groups/${code}/alarms-history`,
          {
            params: {
              from: +from,
              to: +to,
            },
          }
        )
      ).data;
      return pld;
    } catch (exc) {
      console.log("getGroupAlarmsHistory: exc = ", exc);
      return [];
    }
  },

  async getGroupShortPatients(code) {
    try {
      let pld = (
        await axios.get(
          `${DOCTORS_API_ENDPOINT}/groups/${code}/short-patients`,
          {}
        )
      ).data;
      return pld;
    } catch (exc) {
      console.log("getGroupShortPatients: exc = ", exc);
      return [];
    }
  },
};

export default GroupsAPI;
