import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";

import bell_image from "../../../assets/images/bell.svg";
import no_alarm from "../../../assets/images/no_alarm.png";
import alarm_paused from "../../../assets/images/audio_paused.png";

export default function Bell(props) {
  const { type = "bell", width = 34, height = 34 } = props;

  let img = type == "bell" ? bell_image : no_alarm;
  if (type == "no_alarm") {
    img = no_alarm;
  }
  if (type == "alarm_paused") {
    img = alarm_paused;
  }

  return (
    <Wrapper
      src={img}
      style={{ height: height, width: width }}
      className="bottom-bell-icon"
    ></Wrapper>
  );
}

const Wrapper = styled.img``;
