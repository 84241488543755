import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import ls from "local-storage";
import { Code } from "react-content-loader";
import DoctorAPI from "../../../api/DoctorAPI";
import { Sidebar } from "arui-feather/sidebar";
import axios from "axios";
import { EditButton } from "../../patients/panels/PatientPanel";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import DrawHelper from "../../../helpers/DrawHelper";
import Tabs, { TabItem } from "../../ui/Tabs";
import UserInitBpMeasurementsTool from "./UserInitBpMeasurementsTool";
import { isDebugMode } from "../../../helpers/UiHelper";
import InfoToolBadge from "../../info/tools/InfoToolBadge";
import BpSquareTool from "../../bp/tools/BpSquareTool";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import { AXES_COLOR, AXES_STROKE_WIDTH } from "../../ui/templates";

const BP_FILTERS = [
  { value: "cloud", label: "Cloud" },
  { value: "long-term", label: "Bracelet Long Term" },
  { value: "short-term", label: "Bracelet Short Term" },
];

const BP_Y_DOMAIN = [0, 200];
const BP_COLORS = [
  {
    name: "cloud",
    sbp: "#3333FF",
    dbp: "#FF4E45",
  },
  {
    name: "short-term",
    sbp: "#33e3FF",
    dbp: "#aF4E45",
  },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    // console.log("shortterm payload", payload);
    let data = {
      sbp: undefined,
      dbp: undefined,
      nibp_sbp: undefined,
      nibp_dbp: undefined,
    };

    let dd = payload[0]?.payload || {};
    let t1 = payload[0]?.payload?.t || undefined;
    let t2 = payload[1]?.payload?.t || undefined;
    let t3 = payload[2]?.payload?.t || undefined;
    let t4 = payload[3]?.payload?.t || undefined;
    payload.map((p, i) => {
      if (p?.dataKey === "sbp") {
        data.sbp = p?.value;
      }
      if (p?.dataKey === "dbp") {
        data.dbp = p?.value;
      }
      if (p?.dataKey === "nibp_systolic") {
        data.nibp_sbp = p?.value;
      }
      if (p?.dataKey === "nibp_diastolic") {
        data.nibp_dbp = p?.value;
      }
    });
    let bp = dd["blood pressure"];
    let is_init = dd.is_init;
    let tLabel = dd.recorded_t;
    let hr = dd["heart rate"];
    let notifications = dd?._raw_response?.notification_codes;
    // let messages = dd?._raw_response?.messages;
    if (tLabel == "Invalid date") {
      tLabel = ``;
    }
    let quality = dd?._raw_response?.Quality;

    return (
      <TooltipWrapper>
        {tLabel == "" ? null : (
          <>
            <p>
              <b>{tLabel}</b>
            </p>
            <p>
              {bp == undefined ? null : `Blood pressure: ${bp[1]}/${bp[0]}`}
            </p>
            <b>Short term:</b>
            <p>
              {data.nibp_dbp && data.nibp_dbp
                ? `${data.nibp_sbp}/${data.nibp_dbp}`
                : null}
            </p>
            {notifications ? <strong>Errors:</strong> : null}
            {notifications
              ? notifications.map((n, i) => {
                  return <p key={i}>{n}</p>;
                })
              : null}
            {bp && bp[0] !== null ? (
              <p>
                <strong>PPG Quality: </strong>
                {quality ? mapPPGQuality(quality) : ""}
              </p>
            ) : null}
            <div>
              <strong>Recorded: </strong>
              <p>{t1 ? moment(t1).format("HH:mm") : ""} </p>
              <p>{t2 ? moment(t2).format("HH:mm") : ""} </p>
              <p>{t3 ? moment(t3).format("HH:mm") : ""} </p>
              <p>{t4 ? moment(t4).format("HH:mm") : ""} </p>
            </div>
          </>
        )}
      </TooltipWrapper>
    );
  }

  return null;
};

function showBpAMessage(a) {
  try {
    return a?._raw_response == undefined
      ? ""
      : JSON.parse(a?._raw_response)?.Message;
  } catch (exc) {}
  return "";
}

function mapPPGQuality(num) {
  if (num < 0 || num > 100) {
    return "Invalid PPG quality.";
  }
  return Math.floor(num / 25);
}

function getAverageSbpDbp(points) {
  let sbpSum = 0;
  let dbpSum = 0;
  let count = 0;
  points.forEach((x) => {
    if (x.sbp != undefined) {
      sbpSum += +x.sbp;
      count++;
    }
    if (x.dbp != undefined) {
      dbpSum += +x.dbp;
      // count++;
    }
  });
  return {
    sbp: count == 0 ? "" : Math.round((1.0 * +sbpSum) / count),
    dbp: count == 0 ? "" : Math.round((1.0 * +dbpSum) / count),
  };
}

function getUniqueStrings(arr) {
  let map = {};
  for (let i in arr) {
    map[arr[i]] = 1;
  }
  return Object.keys(map);
}

function getBpWarnings(points) {
  let warnings = [];
  for (let i in points) {
    let { _raw_response } = points[i];
    if (_raw_response != undefined) {
      warnings = warnings.concat(_raw_response?.model_warnings || []);
    }
  }
  warnings = warnings.filter((x) => x != undefined && x != "");
  if (warnings.length == 0) {
    return [];
  }
  return getUniqueStrings([warnings[warnings.length - 1]]);
}

function getBpMessages(points) {
  let warnings = [];
  for (let i in points) {
    let { _raw_response } = points[i];
    if (
      _raw_response != undefined &&
      _raw_response.model_message != undefined
    ) {
      warnings.push(_raw_response.model_message);
    }
  }
  warnings = warnings.filter((x) => x != undefined && x != "");
  if (warnings.length == 0) {
    return [];
  }
  return getUniqueStrings([warnings[warnings.length - 1]]);
}

export default function PatientDayBpTool(props) {
  const { uuid, dayTimestamp, min, max } = props;

  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [recalculating, setRecalculating] = useState(false);
  const [mode, setMode] = useState("day");
  const [loadingId, setLoadingId] = useState(undefined);
  const [selectedBar, setSelectedBar] = useState(undefined);
  const [aiWarnings, setAiWarnings] = useState([]);
  const [noWarning, setNoWarning] = useState(false);
  const [freshestData, setFreshestData] = useState([]);
  const [freshestNotification, setFreshestNotification] = useState([]);

  const [shortTerm, setShortTerm] = useState([]);
  const [extraMissingLines, setExtraMissingLines] = useState([]);
  const [bpSeries, setBpSeries] = useState({
    cloud: [],
    "short-term": [],
    "long-term": [],
  });
  const [xDataAi, setXDataAi] = useState([]);

  const [dayAiStats, setDayAiStats] = useState([]);
  const [dayStats, setDayStats] = useState([]);
  const [nightAiStats, setNightAiStats] = useState([]);
  const [nightStats, setNightStats] = useState([]);

  const [bpFilter, setBpFilter] = useState(["cloud", "short-term"]);
  const theme = ls.get("theme");
  let endOfDay = +moment(+dayTimestamp).endOf("day");
  let startOfDay = +moment(+dayTimestamp).startOf("day");

  let startOfNextDay = moment(startOfDay).add(1, "day").valueOf();

  let startOfDay12 = +moment(+dayTimestamp).startOf("day").add(-12, "hours");
  let endOfDay12 = +moment(+dayTimestamp).startOf("day").add(12, "hours");
  // console.log("shortterm endofda", startOfDay, startOfNextDay);

  useEffect(() => {
    let from = +moment(+dayTimestamp).startOf("day");
    let to = +moment(+dayTimestamp).endOf("day");

    if (mode != "day") {
      from = +moment(+dayTimestamp).startOf("day").add(-12, "hours");
      to = +moment(+dayTimestamp).startOf("day").add(12, "hours");
    }

    setLoading(true);
    setPoints([]);
    setBpSeries({ ...bpSeries, cloud: [] });
    DoctorAPI.getAIBpData(uuid, from, to).then((arr) => {
      console.log("getAIBpData: arr = ", arr);
      setPoints(arr);
      setBpSeries({ ...bpSeries, cloud: arr });
      setLoading(false);

      let AIwarning = arr.slice(0, arr.length).map((x) => {
        let notification = x?._raw_response?.notification_codes;
        let aiModelSuccess = x?._raw_response?.Success;
        return {
          notification: notification,
          ts: x?.client_start_timestamp,
          aiModelSuccess: aiModelSuccess,
        };
      });
      AIwarning = AIwarning.filter((a) => a.aiModelSuccess);
      let checkAllWarnings = arr.map((x) => {
        let notification = x?._raw_response?.notification_codes;
        if (notification === undefined || notification[0] === "OK") {
          return null;
        }
        return notification;
      });

      setAiWarnings(AIwarning);
      // console.log("AIwarning", AIwarning);

      setNoWarning(checkAllWarnings.every((x) => x === null));
    });
  }, [uuid, dayTimestamp, mode]);

  let messages = getBpMessages(points);
  let warnings = getBpWarnings(points);

  useEffect(() => {
    if (recalculating == true) {
      return;
    }
    let from = +moment(+dayTimestamp).startOf("day");
    let to = +moment(+dayTimestamp).endOf("day");
    if (mode != "day") {
      from = +moment(+dayTimestamp).startOf("day").add(-12, "hours");
      to = +moment(+dayTimestamp).startOf("day").add(12, "hours");
    }
    setPoints([]);
    setBpSeries({ ...bpSeries, cloud: [] });
    DoctorAPI.getAIBpData(uuid, from, to).then((arr) => {
      setPoints(arr);
      // setBpSeries({ ...bpSeries, cloud: arr });
    });
  }, [recalculating, mode]);

  useEffect(() => {
    let n = 10;
    setFreshestData([]);
    setFreshestNotification([]);
    DoctorAPI.getFreshestAIBpData(uuid, n).then((arr) => {
      if (arr.length === 0) {
      } else {
        let _freshestNotification = arr[0].notification_codes;
        setFreshestData(arr[0]);
        setFreshestNotification(_freshestNotification || []);
      }
    });
  }, [dayTimestamp]);

  useEffect(() => {
    let from = +moment(+dayTimestamp).startOf("day");
    let to = +moment(+dayTimestamp).endOf("day");
    if (mode != "day") {
      from = +moment(+dayTimestamp).startOf("day").add(-12, "hours");
      to = +moment(+dayTimestamp).startOf("day").add(12, "hours");
    }

    setBpSeries({ ...bpSeries, "short-term": [] });
    DoctorAPI.getActivityPoints(uuid, from, to).then((arr) => {
      let _shortTerm = [];
      // console.log("DoctorAPI.getActivityPoints", arr);

      arr.map((a, i) => {
        let nd = a?.nibp_diastolic;
        let ns = a?.nibp_systolic;
        _shortTerm.push({
          t: a?.timestamp,
          nibp_diastolic: nd ? nd : null,
          nibp_systolic: ns ? ns : null,
          date: moment(a?.timestamp).format("HH:mm"),
        });
      });
      console.log(
        "getActivityPoints: _shortTerm = ",
        _shortTerm.filter((x) => x.nibp_systolic !== null)
      );

      setShortTerm(_shortTerm);
      // setBpSeries({
      //   ...bpSeries,
      //   "short-term": _shortTerm,
      // });
    });
  }, [uuid, dayTimestamp, mode]);

  useEffect(() => {
    // console.log("shortterm points", points);
    let xPoints = points.map((x) => ({
      t: +x.start_timestamp,
      ...x,
      date: moment(+x.start_timestamp).format("HH:mm"),
    }));

    let gridPoints = DrawHelper.getBpMonotonePoints(
      xPoints,
      mode == "day"
        ? +dayTimestamp
        : moment(+dayTimestamp).startOf("day").add(-12, "hours"),
      mode
    );
    let xData = gridPoints.map((x) => {
      return {
        ["blood pressure"]: [x.dbp, x.sbp],
        t: +x.t,
        ["heart rate"]: x.hr,
        dbp: x?.dbp,
        sbp: x?.sbp,
        date: x.date,
      };
    });
    let xDataAi = gridPoints.map((x) => {
      return {
        // ['blood pressure']: [x?._raw_response?.DBP_ai, x?._raw_response?.SBP_ai],
        ["blood pressure"]: [
          x?._raw_response?.DBP_ai,
          x?._raw_response?.SBP_ai,
        ],
        ["blood_pressure"]: [
          x?._raw_response?.DBP_ai_debug,
          x?._raw_response?.SBP_ai_debug,
        ],
        t: +x.t,
        ["heart rate"]: x.hr,
        ["_raw_response"]: x?._raw_response,
        date: x.date,
        dbp: x?._raw_response?.DBP_ai,
        d: x?._raw_response?.DBP_ai,
        dbp_: x?._raw_response?.DBP_ai_debug,
        sbp: x?._raw_response?.SBP_ai,
        s: x?._raw_response?.SBP_ai,
        sbp_: x?._raw_response?.SBP_ai_debug,
      };
    });
    setXDataAi(xDataAi);

    let nightXDataAi = xDataAi.filter((x) => x.date <= "07:00");
    let nightXData = xData.filter((x) => x.date <= "07:00");
    let dayXDataAi = xDataAi.filter((x) => x.date > "07:00");
    let dayXData = xData.filter((x) => x.date > "07:00");

    setDayAiStats(getAverageSbpDbp(dayXDataAi));
    setDayStats(getAverageSbpDbp(dayXData));
    setNightAiStats(getAverageSbpDbp(nightXDataAi));
    setNightStats(getAverageSbpDbp(nightXData));
    let fPoints = xDataAi;

    for (let i in fPoints) {
      if (fPoints[i].sbp == undefined || fPoints[i].sbp == 0) {
        delete fPoints[i].sbp;
      }
    }
    // console.table(fPoints, ["date"]);
    fPoints = fPoints
      .filter((x) => x.date != undefined && x.date.indexOf(":") > -1)
      .map((xx, i) => {
        return {
          ...xx,

          date_key:
            (+xx.t < startOfDay ? -24 * 60 : 0) +
            (+xx.date.split(":")[0] * 60 + +xx.date.split(":")[1]),
          date_key0: +xx.date.split(":")[0] * 60 + +xx.date.split(":")[1],
          isDayBefore: +xx.t < startOfDay,

          xxx: 111,
        };
      })
      .map((x) => {
        let xDate_key = Math.floor(+x.date_key / 30) * 30;
        return {
          ...x,
          date_key: xDate_key,
        };
      });
    // console.log("shortterm fpoints1 ", fPoints);

    if (fPoints.length > 1) {
      let xMap = {};
      for (let i = 1; i < fPoints.length; i++) {
        let delta = fPoints[i].date_key - fPoints[+i - 1].date_key;
        let xKey = `delta-${delta}`;
        if (xMap[xKey] == undefined) {
          xMap[xKey] = {
            xKey: xKey,
            delta: delta,
            number: 0,
          };
        }
        xMap[xKey].number = +xMap[xKey].number + 1;
      }
      // console.log("xMap = ", xMap);
      let arr = Object.keys(xMap)
        .map((xKey) => xMap[xKey])
        .sort((a, b) => +b.number - +a.number);
      let freqDelta = +arr[0].delta;
      // console.log("freqDelta = ", freqDelta);
      let step_ = +freqDelta == 1 ? 1 : 30;
      let fMap = {};
      let maxVal = Math.max(...fPoints.map((xx) => xx.date_key));

      if (mode == "day") {
        maxVal = 24 * 60;
      } else {
        maxVal = 12 * 60;
      }

      for (let i in fPoints) {
        let fp = fPoints[i];
        fMap[`x-${fp.date_key}`] = fp;
      }

      let t = mode == "day" ? 0 : -12 * 60;
      let rPoints = [];

      while (+t < +maxVal) {
        t = +t + +step_;
        let tKey = `x-${t}`;
        let h_ = Math.floor(+t / 60.0);
        let min_ = +t - h_ * 60;
        let date_ =
          `${t < 0 ? 24 - Math.abs(h_) : h_}`.padStart(2, "0") +
          ":" +
          `${min_}`.padStart(2, "0");
        let val_ = fMap[tKey];
        if (val_ == undefined) {
          val_ = {
            date: date_,
            date_key: t,
          };
        }
        rPoints.push(val_);
      }
      fPoints = rPoints;
    }

    let extraLines = [];
    extraLines = DrawHelper.getExtraDottedBloodPressureChartsData(fPoints);
    for (let i in fPoints) {
      let t = fPoints[i].date_key;
      for (let j in extraLines) {
        let eLine = extraLines[j];
        let lp = eLine.points[0];
        let rp = eLine.points[1];
        if (lp.date_key == t) {
          fPoints[i][`sbp_gap_${j}`] = lp.sbp;
          fPoints[i][`dbp_gap_${j}`] = lp.dbp;
        }
        if (rp.date_key == t) {
          fPoints[i][`sbp_gap_${j}`] = rp.sbp;
          fPoints[i][`dbp_gap_${j}`] = rp.dbp;
        }
      }
    }

    setExtraMissingLines(extraLines);
    setBpSeries({
      ...bpSeries,
      cloud: fPoints,
      "short-term": shortTerm,
    });
    // console.log("shortterm bp afterset", bpSeries);
  }, [points, shortTerm, dayTimestamp]);

  const handleBPFilterChange = (evt) => {
    const item = evt.target.name;
    setBpFilter((prev) => {
      if (prev.includes(item)) {
        return prev.filter((checkedItem) => checkedItem !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  let hideErrorIfThereIs = !moment(endOfDay).isAfter(
    freshestData.start_timestamp
  );
  let xticks = [];
  let tickNum = 24;
  let _min;
  let _max;
  if (mode === "day") {
    _min = min;
    _max = max;
    for (let i = 0; i < tickNum; i++) {
      xticks.push(moment(min).add(i, "hour").valueOf());
    }
  } else {
    for (let i = 0; i < tickNum; i++) {
      _min = moment(min).subtract(12, "hours");
      _max = moment(max).subtract(12, "hours");
      xticks.push(
        moment(min)
          .add(i - 12, "hour")
          .valueOf()
      );
    }
  }
  // dayTimestamp < freshestData.start_timestamp ||
  // !moment(dayTimestamp).isSame(freshestData.start_timestamp, "day");
  if (points.length == 0 && loading == true) {
    return <Code />;
  }

  if (points.length == 0 && loading == false) {
    if (
      !freshestNotification[0] ||
      freshestNotification.length === 0 ||
      !freshestData ||
      hideErrorIfThereIs
    ) {
      // console.log("daytimestamp RETURN early");

      return (
        <div style={{ marginBottom: "1em" }}>
          <TabHeading className="patient-statistics-heading">
            <div>Blood Pressure</div>
          </TabHeading>
          <SimpleNoDataMessageTool
            // loading={loading}
            message={"Sorry, there is no blood pressure data for this day."}
          />
        </div>
      );
    }
    return (
      <div style={{ marginBottom: "1em" }}>
        <TabHeading className="patient-statistics-heading">
          <div>Blood Pressure</div>
        </TabHeading>

        <SimpleNoDataMessageTool
          // loading={loading}
          message={"Sorry, there is no blood pressure data for this day."}
        />
        <div
          className="normal-text"
          style={{
            display: freshestNotification[0] ? "block" : "none",
            fontWeight: "500",
            fontSize: "20px",
          }}
        >
          Warnings:
        </div>
        <WarningArea>
          <div
            className="normal-text"
            style={{ width: "80%", marginBottom: "1rem" }}
          >
            <ul>
              {freshestNotification
                .filter((m) => {
                  // console.log("FRESHES", m);

                  return NOTIFICATION_TO_DETAILS[m];
                })
                .map((n, _i) => {
                  return (
                    <li key={_i}>
                      <strong>{NOTIFICATION_TO_DETAILS[n].title}</strong>
                      {": "}
                      {NOTIFICATION_TO_DETAILS[n].verbal}
                    </li>
                  );
                })}
            </ul>
          </div>
        </WarningArea>
      </div>
    );
  }

  // console.log("shortterm", bpSeries);
  // let xticks = [];
  // for (let i = 0; i < 24; i++) {
  //   if (mode === "day") {
  //     xticks.push(moment(startOfDay).add(i, "hour").valueOf());
  //   } else {
  //     xticks.push(moment(startOfDay12).add(i, "hour").valueOf());
  //   }
  // }
  // console.log("shortterm ticks", xticks);

  return (
    <Wrapper>
      {/* <Heading> */}
      {/* Experimental AI Spo2 */}
      {/* </Heading> */}

      <TabHeading className="patient-statistics-heading">
        <div>Blood pressure</div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontStyle: "normal",
            fontWeight: "normal",
            marginLeft: 20,
            fontSize: "14px",
            alignItems: "center",
          }}
        >
          <strong>Mode:</strong>
          <ModeSwitcherItem
            selected={mode == "day"}
            onClick={() => {
              setMode("day");
            }}
          >
            00:00-24:00
          </ModeSwitcherItem>
          <ModeSwitcherItem
            selected={mode == "12-12"}
            onClick={() => {
              setMode("12-12");
            }}
          >
            12:00-12:00
          </ModeSwitcherItem>
        </div>
        {isDebugMode() ? (
          <BpFilterContainer>
            <strong>Filters:</strong>
            {BP_FILTERS.map((f, i) => {
              return (
                <BpFilterItem key={i}>
                  <BpFilterCheckbox
                    type="checkbox"
                    name={f.value}
                    checked={bpFilter.includes(f.value)}
                    onChange={handleBPFilterChange}
                  />
                  {f.label}
                </BpFilterItem>
              );
            })}
            <div style={{ marginLeft: 40 }}>
              <InfoToolBadge type={"BLOOD_PRESSURE"} />
            </div>
          </BpFilterContainer>
        ) : null}
      </TabHeading>

      <TopDayNight
        style={{ flexDirection: "flex-start", justifyContent: "space-between" }}
      >
        <div style={{ paddingLeft: 64 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {!isDebugMode() ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "1.5rem",
                }}
              >
                <SysDiaColorSpan color={BP_COLORS[0].sbp} />
                <span style={{ marginRight: "1rem", fontSize: "14px" }}>
                  Cloud Systolic
                </span>
                <SysDiaColorSpan color={BP_COLORS[0].dbp} />
                <span style={{ marginRight: "1rem", fontSize: "14px" }}>
                  Cloud Diastolic
                </span>
              </div>
            ) : null}
            {isDebugMode() && bpFilter.includes("cloud") ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "1.5rem",
                }}
              >
                <SysDiaColorSpan color={BP_COLORS[0].sbp} />
                <span style={{ marginRight: "1rem", fontSize: "14px" }}>
                  Cloud Systolic
                </span>
                <SysDiaColorSpan color={BP_COLORS[0].dbp} />
                <span style={{ marginRight: "1rem", fontSize: "14px" }}>
                  Cloud Diastolic
                </span>
              </div>
            ) : null}
            {isDebugMode() && bpFilter.includes("short-term") ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "1.5rem",
                }}
              >
                <SysDiaColorSpan color={BP_COLORS[1].sbp} />
                <span style={{ marginRight: "1rem", fontSize: "14px" }}>
                  Short Term Systolic
                </span>
                <SysDiaColorSpan color={BP_COLORS[1].dbp} />
                <span style={{ marginRight: "1rem", fontSize: "14px" }}>
                  Short Term Diastolic
                </span>
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></div>
        </div>

        <div className="bp-daynight">
          <div>{`Night: ${nightAiStats.sbp}/${nightAiStats.dbp}`}</div>
          <div>{`Day: ${dayAiStats.sbp}/${dayAiStats.dbp}`}</div>
        </div>
      </TopDayNight>

      <ChartPlaceholder>
        <ResponsiveContainer height={320}>
          {/* <ComposedChart data={fPoints}> */}
          <ComposedChart>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              xAxisId={1}
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              strokeWidth={AXES_STROKE_WIDTH}
              tickCount={tickNum}
              dataKey={"t"}
              ticks={xticks}
              interval={0}
              // interval={1}
              // allowDataOverflow={false}
              // interval={"preserveStartEnd"}
              // tickCount={26}
              // tickLine={true}
              // tickSize={4}
              domain={[_min, _max]}
              // domain={([dataMin, dataMax]) => {
              //   // console.log("shortterm data min max", dataMin, dataMax);
              //   return [dataMin, dataMax];
              // }}
              // allowDuplicatedCategory={false}
              tickFormatter={xFormatter}
            />
            <YAxis
              type="number"
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              strokeWidth={AXES_STROKE_WIDTH}
              domain={([dataMin, dataMax]) => {
                const _max = dataMax > 200 ? dataMax : 200;
                return [0, _max];
              }}
            />

            {/* <Legend/> */}
            {/* <Bar
              data={bpSeries["cloud"]}
              dataKey="blood pressure"
              fill="#b3cccc56"
              strokeWidth={1}
              barSize={2}
              strokeDasharray="3 5"
              xAxisId={1}
              hide={!bpFilter.includes("cloud")}
            /> */}

            <Line
              xAxisId={1}
              dataKey={"dbp"}
              data={bpSeries["cloud"]}
              stroke={BP_COLORS[0].dbp}
              strokeWidth={2}
              connectNulls={false}
              hide={!bpFilter.includes("cloud")}
              isAnimationActive={false}
            />
            <Line
              xAxisId={1}
              dataKey={"sbp"}
              data={bpSeries["cloud"]}
              stroke={BP_COLORS[0].sbp}
              strokeWidth={2}
              connectNulls={false}
              hide={!bpFilter.includes("cloud")}
              isAnimationActive={false}
            />
            <Line
              dataKey={"nibp_systolic"}
              stroke={BP_COLORS[1].sbp}
              data={bpSeries["short-term"]}
              dot={true}
              connectNulls={false}
              xAxisId={1}
              hide={!bpFilter.includes("short-term")}
              isAnimationActive={false}
            />
            <Line
              dataKey={"nibp_diastolic"}
              stroke={BP_COLORS[1].dbp}
              data={bpSeries["short-term"]}
              dot={true}
              connectNulls={false}
              xAxisId={1}
              hide={!bpFilter.includes("short-term")}
              isAnimationActive={false}
            />

            {extraMissingLines.map((l, i) => {
              return (
                <>
                  <Line
                    hide={!bpFilter.includes("cloud")}
                    key={`${i}_dbp`}
                    data={bpSeries["cloud"]}
                    type={"monotone"}
                    dataKey={`dbp_gap_${i}`}
                    animationDuration={0.1}
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    stroke="#ff730080"
                    dot={false}
                    connectNulls={true}
                    xAxisId={1}
                  />
                  <Line
                    hide={!bpFilter.includes("cloud")}
                    key={`${i}_sbp`}
                    data={bpSeries["cloud"]}
                    type={"monotone"}
                    dataKey={`sbp_gap_${i}`}
                    animationDuration={0.1}
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    stroke="#0000ff80"
                    dot={false}
                    connectNulls={true}
                    xAxisId={1}
                  />
                </>
              );
            })}

            <Tooltip
              content={<CustomTooltip />}
              labelFormatter={(t) => moment(t).format("HH:mm")}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartPlaceholder>

      <div style={{ display: "none", fontSize: "12px", textAlign: "center" }}>
        {messages.length == 0 ? null : (
          <span style={{ marginRight: 20 }}>
            {`Messages: ${messages.join(", ")}`}
          </span>
        )}
        {warnings.length == 0 ? null : (
          <span>{`Warnings: ${warnings.join(", ")}`}</span>
        )}
      </div>
      {noWarning || hideErrorIfThereIs ? (
        <WarningArea>
          <div style={{ fontWeight: "700", fontSize: "20px" }}>{""}</div>
        </WarningArea>
      ) : (
        <WarningArea>
          {aiWarnings
            .slice(aiWarnings.length - 1, aiWarnings.length)
            .map((x, i) => {
              let nfs = x.notification;
              if (!nfs) return null;

              return (
                <div key={i} style={{ width: "80%", marginBottom: "1rem" }}>
                  <div style={{ fontWeight: "500", fontSize: "20px" }}>
                    Warnings:
                  </div>
                  {/* <TimeText>{moment(x.ts).format("HH:mm")}</TimeText> */}
                  <ul>
                    {nfs
                      .filter((m) => NOTIFICATION_TO_DETAILS[m])
                      .map((n, _i) => {
                        return (
                          <li key={_i}>
                            <strong>{NOTIFICATION_TO_DETAILS[n].title}</strong>
                            {": "}
                            {NOTIFICATION_TO_DETAILS[n].verbal}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              );
            })}
        </WarningArea>
      )}
      <BpSquareTool items={bpSeries["cloud"]} />

      {isDebugMode() == false ? null : (
        <>
          <TabHeading className="patient-statistics-heading">BP AI</TabHeading>

          <TopDayNight>
            <div className="bp-daynight">
              <div>{`Night: ${nightAiStats.sbp}/${nightAiStats.dbp}`}</div>
              <div>{`Day: ${dayAiStats.sbp}/${dayAiStats.dbp}`}</div>
            </div>
          </TopDayNight>
          <ChartPlaceholder>
            <ResponsiveContainer height={320}>
              <ComposedChart
                data={xDataAi}
                onClick={(x) => {
                  console.log("ComposedChart: onClick: x = ", x);
                  let d = (x?.activePayload || []).find(
                    (x) => x.dataKey == "blood_pressure"
                  );
                  setSelectedBar(d?.payload);
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="t"
                  stroke={theme === "dark" ? "#ddd" : ""}
                  tickFormatter={(a) => {
                    return moment(a).format("HH:mm");
                  }}
                />
                <YAxis stroke={theme === "dark" ? "#ddd" : ""} />

                <Tooltip labelFormatter={(t) => moment(t).format("HH:mm")} />
                <Legend />
                <Bar
                  name="Blood Pressure"
                  dataKey="blood_pressure"
                  fill="#8884d8"
                />
                <Line
                  name="Heart Rate"
                  type="monotone"
                  dataKey="heart rate"
                  stroke="#ff7300"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartPlaceholder>
        </>
      )}

      <BottomDiv>
        <RecordsSpan
          onClick={() => {
            setModalVisible(true);
          }}
        >
          records list
        </RecordsSpan>
      </BottomDiv>

      <Sidebar
        visible={modalVisible}
        onCloserClick={() => {
          setModalVisible(false);
        }}
      >
        {modalVisible == false ? null : (
          <div>
            <Tabs
              tabs={[
                {
                  label: "measurements",
                  content: (
                    <TabItem>
                      {points.map((a, i) => {
                        let isLoading = a._id == loadingId;
                        let hasError = a.dbp == undefined || a.dbp == 0;
                        // console.log('loop: a._raw_response = ', a._raw_response);
                        // console.log('loop: a._raw_response?.Quality = ', a._raw_response?.Quality);
                        let dd = a?._raw_response || {};
                        let Q = dd["Quality"] || dd["Quality:"];
                        // try{
                        //     dd = JSON.parse(JSON.stringify(a?._raw_response));
                        //     console.log('loop: dd?.Quality = ', dd?.Quality);
                        //     console.log('loop: dd = ', dd);
                        // }catch(exc){
                        //
                        // }
                        return (
                          <RowItem key={i}>
                            <span>
                              <span>
                                {`${moment(a.start_timestamp).format(
                                  "DD.MM.YYYY HH:mm:ss"
                                )} - ${a.dbp_ai} - ${a.sbp_ai}`}
                                <span
                                  style={{
                                    fontSize: 12,
                                    opacity: 0.5,
                                    marginLeft: 5,
                                  }}
                                >
                                  {Q}
                                </span>
                              </span>
                              <br />
                              {hasError == false ? null : (
                                <span
                                  style={{
                                    fontSize: 10,
                                    fontStyle: "italic",
                                    opacity: 0.5,
                                    color: "red",
                                  }}
                                >
                                  {showBpAMessage(a)}
                                </span>
                              )}
                            </span>
                            {isLoading == true ? (
                              <RowDownload2>loading...</RowDownload2>
                            ) : (
                              <RowDownload
                                onClick={async () => {
                                  setLoadingId(a._id);
                                  let pld = (
                                    await axios.get(
                                      `https://api.study-integration.corsano.com/v2/ds/user/${uuid}/blood-pressure-measurements/${a._id}/download`
                                    )
                                  ).data;
                                  setLoadingId(false);
                                  let txt = JSON.stringify(pld);
                                  download(
                                    `${moment(a.start_timestamp).format(
                                      "DD_MM_YYYY_HH_mm_ss"
                                    )}_spo2_raw_ppg2.json`,
                                    txt
                                  );
                                }}
                              >
                                download
                              </RowDownload>
                            )}
                          </RowItem>
                        );
                      })}

                      <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <EditButton
                          onClick={async () => {
                            if (recalculating == true) {
                              return;
                            }
                            setRecalculating(true);
                            let from = +moment(+dayTimestamp).startOf("day");
                            let to = +moment(+dayTimestamp).endOf("day");
                            await DoctorAPI.recalculateSpo2(uuid, +from, +to);
                            setRecalculating(false);
                            setModalVisible(false);
                          }}
                        >
                          {recalculating == false
                            ? "Recalculate"
                            : "Recalculating..."}
                        </EditButton>
                      </div>
                    </TabItem>
                  ),
                },
                {
                  label: "init",
                  content: (
                    <TabItem>
                      <UserInitBpMeasurementsTool uuid={uuid} />
                    </TabItem>
                  ),
                },
              ]}
            />
          </div>
        )}
      </Sidebar>

      <Sidebar
        visible={selectedBar != undefined}
        width={Math.min(window.innerWidth, 820)}
        onCloserClick={() => {
          setSelectedBar(undefined);
        }}
      >
        {selectedBar == undefined ? null : (
          <div style={{ zIndex: 1 }}>
            <pre
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(fixSelectedBar(selectedBar), null, 2),
              }}
            ></pre>
          </div>
        )}
      </Sidebar>
    </Wrapper>
  );
}
function xFormatter(a) {
  let ss = moment(a).format("HH:mm");
  if (ss == "Invalid date") {
    return "";
  }
  return ss;
}

function fixSelectedBar(d) {
  let res = { ...d };
  if (
    d != undefined &&
    d._raw_response != undefined &&
    typeof d._raw_response == "string"
  ) {
    res._raw_response = JSON.parse(d._raw_response);
  }
  return res;
}

const TabHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
`;

const ModeSwitcherItem = styled.div`
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  cursor: ${(props) => (props.selected ? "default" : "pointer")};
  text-decoration: ${(props) => (props.selected ? "underline" : "none")};
  margin-left: 5px;
  margin-right: 5px;
`;

const TopDayNight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const RowItem = styled.div`
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RowDownload = styled.a`
  font-size: 10px;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const RowDownload2 = styled.div`
  font-size: 10px;
  opacity: 0.5;
`;

const BottomDiv = styled.div`
  text-align: right;
`;

const RecordsSpan = styled.span`
  font-style: italic;
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  width: 100%;

  .sidebar__inner {
    background: white;
  }
`;

const TooltipWrapper = styled.div`
  background: white;
  padding: 5px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
`;

const BpFilterContainer = styled.div`
  display: flex;
  // flex-direction: column;
  gap: 24px;
  align-items: center;

  font-weight: normal;
  font-size: 14px;
  margin-left: 20px;
  // line-height: 28px;
  // border-left: 1px solid grey;
  &:before {
    content: "";
    border: 1px solid #17f;
    align-self: stretch;
  }
`;

const BpFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const BpFilterCheckbox = styled.input`
  &[type="checkbox"] {
    accent-color: #1e7efa;
  }
`;
const ChartPlaceholder = styled.div`
  height: 320px;
  width: 100%;
`;

const Heading = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 10px;
  font-size: 12px;
  opacity: 0.8;
`;

export function download(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
const WarningArea = styled.div`
  margin-left: 1rem;
`;

const SysDiaColorSpan = styled.span`
  display: inline-block;
  height: 4px;
  opacity: 0.8;
  width: 50px;
  background-color: ${(props) => props.color} !important;
  margin-right: 10px;
`;

const NOTIFICATION_TO_DETAILS = {
  BRACELET_NOT_WORN_AND_MISSING_INITS: {
    verbal:
      "Requirements to obtain Non-Invasive Blood Pressure measurements are not met. Please ask patient to wear the Cardiowatch at least 48 hours consecutively and perform BP Cuff Measurements in the morning, afternoon and evening.",
    title: "Bracelet Not Worn And Missing Inits",
  },
  BRACELET_NOT_WORN_LONG_ENOUGH: {
    verbal:
      "Awaiting 48 hours of consecutive data before Non-Invasive Blood Pressure can be measured. Please make sure CardioWatch is worn consecutively for 48 hours.",
    title: "Bracelet Not Worn Long Enough",
  },
  BAD_MODEL_QUALITY_NEW_INIT_NEEDED: {
    verbal:
      "The quality of Non-Invasive Blood Pressure predictions seems to be suboptimal. Please ask patient to perform another BP Cuff Measurement to improve the quality of the Blood Pressure Measurements.",
    title: "Bad Model Quality New Init Needed",
  },
  NO_INIT_MORNING: {
    verbal:
      "Requirements to obtain Non-Invasive Blood Pressure Measurement are not met. Please ask patient to perform a BP Cuff Measurement in the morning.",
    title: "No Init Morning",
  },
  NO_INIT_AFTERNOON: {
    verbal:
      "Requirements to obtain Non-Invasive Blood Pressure Measurement are not met. Please ask patient to perform a BP Cuff Measurement in the afternoon.",
    title: "No Init Afternoon",
  },
  NO_INIT_EVENING: {
    verbal:
      "Requirements to obtain Non-Invasive Blood Pressure Measurement are not met. Please ask patient to perform a BP Cuff Measurement in the evening.",
    title: "No Init Evening",
  },
  NO_VALID_INIT_MORNING: {
    verbal:
      "Unfortunately, a problem occurred while processing your latest morning BP Cuff Measurement. Please ask patient to repeat your BP Cuff Measurement to improve NIBP predictions.",
    title: "No Valid Init Morning",
  },
  NO_VALID_INIT_AFTERNOON: {
    verbal:
      "Unfortunately, a problem occurred while processing your latest afternoon BP Cuff Measurement. Please ask patient to repeat your BP Cuff Measurement to improve NIBP predictions.",
    title: "No Valid Init Afternoon",
  },
  NO_VALID_INIT_EVENING: {
    verbal:
      "Unfortunately, a problem occurred while processing your latest evening BP Cuff Measurement. Please ask patient to repeat your BP Cuff Measurement to improve NIBP predictions.",
    title: "No Valid Init Evening",
  },
  MODEL_TRAINING_PROBLEM_CONTACT_DEV: {
    verbal:
      "Training of the Non-Invasive Blood Pressure model appears unstable. Please contact your Corsano coordinator to request and investigation.",
    title: "Model Training Problem Contact Dev",
  },
  // BAD_MODEL_QUALITY_NEW_INIT_NEEDED:
  //   "Please ask patient to wear the Cardiowatch at least 48 hours consecutively and perform new BP Cuff Measurements in the morning, afternoon and evening.",
};
