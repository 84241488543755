import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import EcgProcessingAPI from "../../../api/EcgProcessingAPI";
import {useInterval} from "../../apps/RouterApp";
import SimpleEcgViewer from "../tools/SimpleEcgViewer";

function getUrl(userUUID, measurementUUID){
    return `https://app.ecg.corsano.com/#/report/${userUUID}_${measurementUUID}`
}

export default function EcgRecordPanel(props) {
    const {
        userUUID, measurementUUID,
        forceUrl
    } = props;

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);
    const [initialized, setInitialized] = useState(false);
    const [taskIsReady, setTaskIsReady] = useState(false);

    // useEffect(() => {
    //     if (userUUID == undefined || measurementUUID == undefined) {
    //         return;
    //     }
    //     setTaskIsReady(false);
    //     setLoading(true);
    //     EcgProcessingAPI.scheduleProcessingLazily(userUUID, measurementUUID).then(() => {
    //         console.log('task has been scheduled');
    //         setInitialized(true);
    //         EcgProcessingAPI.getTaskResult(userUUID, measurementUUID).then(result => {
    //             setData(result);
    //             setLoading(false);
    //         });
    //         // EcgProcessingAPI.checkIfTaskIsReady(userUUID, measurementUUID).then(f => {
    //         //     setTaskIsReady(f);
    //         //     if (f == true) {
    //         //         EcgProcessingAPI.getTaskResult(userUUID, measurementUUID).then(result => {
    //         //             setData(result);
    //         //             setLoading(true);
    //         //         })
    //         //     }
    //         // });
    //     });
    // }, [userUUID, measurementUUID]);
    //
    // useInterval(() => {
    //     if (taskIsReady == true) {
    //         return;
    //     }
    //     EcgProcessingAPI.checkIfTaskIsReady(userUUID, measurementUUID).then(f => {
    //         setTaskIsReady(f);
    //     });
    // }, 5000);

    // if (initialized == false) {
    //     return (
    //         <Code/>
    //     )
    // }

    return (
        <Wrapper>

            {/*<WaitingPlaceholder>*/}
            {/*    {loading == true ? 'Loading... Please wait...' : null}*/}
            {/*</WaitingPlaceholder>*/}

            {/*{data == undefined ? null :*/}
                <ViewerPlaceholder>
                    <div className='content-record' style={{position:"relative"}}>
                        <iframe title="ECG-record" width="100%"
                                style={{minHeight: "920px", border: "none"}}
                                src={forceUrl == undefined ? getUrl(userUUID, measurementUUID) : forceUrl}/>
                    </div>
                </ViewerPlaceholder>
            {/*}*/}


        </Wrapper>
    );
}

const WaitingPlaceholder = styled.div`
  box-sizing: border-box;
  padding: 15px;
  border-radius: 4px;
  background-color: whitesmoke;
  border: 1px solid lightgrey;
`;

const ViewerPlaceholder = styled.div`
    
`;

const Wrapper = styled.div`

`;