import styled from "styled-components";

import DoctorTemplate from "../templates/DoctorTemplate";
import GroupPanel from "../groups/panels/GroupPanel";
import { useParams } from "react-router-dom";

function GroupApp(props) {
  let params = useParams();
  let { code } = params;

  return (
    <DoctorTemplate active={`groups/${code}`}>
      <Wrapper>
        <GroupPanel code={code} />
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div``;

export default GroupApp;
