import { useCallback } from "react";
import styled from "styled-components";

// import MmtTemplate from '../templates/MmtTemplate'
import DoctorTemplate from "../templates/DoctorTemplate";
import HeartRatePanel from "../heart_rate/panels/HeartRatePanel";
import DoctorDashboardPanel from "../dashboard/panels/DoctorDashboardPanel";
import GroupsPanel from "../groups/panels/GroupsPanel";
import { useMappedState } from "redux-react-hook";
import PatientPanel from "../patients/panels/PatientPanel";

function IndexApp() {
  const { currentUserObj, email } = useMappedState(
    useCallback((state) => {
      let email = state?.auth?.currentUserObj?.usersUser?.email;
      let allInmates = state.inmates.inmatesMap.toArray();
      return {
        currentUserObj: state.auth.currentUserObj,
        email: email,
      };
    })
  );

  const isPortalCardioMood =
    window.location.href.indexOf("portal.cardiomood") > -1;

  return (
    <DoctorTemplate active={"index"}>
      <Wrapper>
        {/*<HeartRatePanel/>*/}

        {isPortalCardioMood == false ? null : (
          <div>
            {/*{JSON.stringify(currentUserObj)}*/}
            <PatientPanel
              id={currentUserObj?.healthUser?.uuid}
              hasBackButton={!isPortalCardioMood}
            />
          </div>
        )}

        {isPortalCardioMood == true ? null : <GroupsPanel />}
        {/*<DoctorDashboardPanel/>*/}
      </Wrapper>
    </DoctorTemplate>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default IndexApp;
