import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import ls from "local-storage";
import moment from "moment";
import styled from "styled-components";
import DoctorAPI from "../../../api/DoctorAPI";
import SleepViewTool from "../../sleep/tools/SleepViewTool";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import SabirBarChart from "../../activity/tools/SabirBarChart";
import TarnsformerHelper from "../../../helpers/TarnsformerHelper";
import SimpleBpmSlotsChart from "../../heart_rate/tools/SimpleBpmSlotsChart";
import DayHrvAnalysisPanel from "../../hrv/panels/DayHrvAnalysisPanel";
import OverviewTool from "../tools/OverviewTool";
import SimpleTemperatureDeviationSlotsChart from "../../heart_rate/tools/SimpleTemperatureDeviationSlotsChart";

import export_img from "../tools/export_img.svg";
import CommonHelper from "../../../helpers/CommonHelper";
import PpgFromToExportTool from "../tools/PpgFromToExportTool";
import InfoToolBadge from "../../info/tools/InfoToolBadge";
import WorkoutsExportTool from "../tools/WorkoutsExportTool";
import BinaryMeasurementsPanel from "../binary/panels/BinaryMeasurementsPanel";
import PatientSurveysPanel from "../../surveys/panels/PatientSurveysPanel";
import Sidebar from "arui-feather/sidebar";
import ContinuousMeasurementsPanel from "./ContinuousMeasurementsPanel";
import NiceModal from "../../modals/NiceModal";
import PpgTypesSelector from "../../export/tools/PpgTypesSelector";
import BinaryMeasurementsAPI from "../../../api/BinaryMeasurementsAPI";
import PatientNotesPanel from "../../notes/panels/PatientNotesPanel";
import PatientSessionsPanel from "./PatientSessionsPanel";
import ExportServiceTool from "../../export/tools/ExportServiceTool";
import PatientEmographyPanel from "./PatientEmographyPanel";
import UserWeeklyRecoveryPanel from "../../recovery/panels/UserWeeklyRecoveryPanel";
import NewExportServiceTool from "../../export/tools/NewExportServiceTool";
import DayBpAnalysisPanel from "../../bp/panels/DayBpAnalysisPanel";
import { getLabelsInfoData } from "../../groups/panels/GroupsPanel";
import PatientDaySpo2Tool from "../../spo2/tools/PatientDaySpo2Tool";
import PatientDaySpo2BaselineTool from "../../spo2/tools/PatientDaySpo2BaselineTool";
import { isDebugMode, isSleepDebugMode } from "../../../helpers/UiHelper";
import SimpleTrendsChartTool from "../../trends/tools/SimpleTrendsChartTool";
import TrendsSectionTool from "../../trends/tools/TrendsSectionTool";
import PatientDayBpTool from "../../spo2/tools/PatientDayBpTool";
import NewSurveysPanel from "../../surveys/panels/NewSurveysPanel";
import AfibTool from "../../afib/tools/AfibTool";
import PatientDayRespirationTool from "../../spo2/tools/PatientDayRespirationTool";
import EmptyChartTool from "../../spo2/tools/EmptyChartTool";
import SpirometerTool from "../../spiro/tools/SpirometerTool";
import WeightTool from "../../spiro/tools/WeightTool";
import HappitechTool from "../../afib/tools/HappitechTool";
import HappitechChart from "../../afib/tools/HappitechChart";
import CalcAPI from "../../../api/CalcAPI";
import DrawHelper from "../../../helpers/DrawHelper";
import ActivityHeartRateChart from "../../heart_rate/tools/ActivityHeartRateChart";
import UserECGRecordsPanel from "../../ecg/panels/UserECGRecordsPanel";
import HealthBatteryTool from "../../health_battery/tools/HealthBatteryTool";
import HealthBatteryWidgetTool from "../../health_battery/tools/HealthBatteryWidgetTool";
import VivalinkItemsTool from "../../vivalink/tools/VivalinkItemsTool";
import TempStepsTool from "../tools/TempStepsTool";
import TempRespTool from "../tools/TempRespTool";
import SummaryActivityGaitViewTool from "../../gait/tools/SummaryActivityGaitViewTool";
import AiSleepTool from "../../sleep/AiSleepTool";
import AiSleepViewTool from "../../sleep/tools/AiSleepViewTool";
import PatientMPITool from "../../mpi/tools/PatientMPITool";
import PatientMPIToolBaseline from "../../mpi/tools/PatientMPIToolBaseline";
import AfNsrTool from "../../afib/tools/AfNsrTool";
import AppLogsTool from "../../logs/tools/AppLogsTool";
import PatientDayPulseRateBaselineTool from "../../spo2/tools/PatientDayPulseRateBaselineTool";
import PatientDayBasicBpTool from "../../spo2/tools/PatientDayBasicBpTool";
import PatientDayBRPMBaselineTool from "../../spo2/tools/PatientDayBRPMBaselineTool";
import PatientDayTemperatureBaselineTool from "../../spo2/tools/PatientDayTemperatureBaselineTool";

const isAFMode = window.location.href.indexOf("af.") > -1;
const hasTopTabs = isAFMode == false;

const DEMO_MESSAGE =
  "This is a demo portal. For full access please contact administrator.";

export const isCorsanoMode = () => {
  try {
    return window.location.href.indexOf("corsano") > -1;
  } catch (exc) {}
  return false;
};

const TABS = [
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Overview",
        value: "overview",
      },
  {
    label: "Pulse Rate",
    value: "heart_rate",
  },

  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "HRV",
        value: "hrv",
      },

  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "AF",
        value: "af",
      },

  {
    label: "SPO2",
    value: "spo2",
  },
  {
    // label: "Respiration",
    label: "BRPM",
    value: "respiration",
  },

  {
    // label: "Temperature",
    label: "CBT",
    value: "temperature",
  },

  getLabelsInfoData().bioz_enabled == false
    ? undefined
    : {
        label: "NIBP",
        value: "blood_pressure",
      },
  getLabelsInfoData().spiro_enabled == false ||
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Spiro",
        value: "spiro",
      },
  getLabelsInfoData().weight_enabled == false ||
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Weight",
        value: "weight",
      },
  getLabelsInfoData().bioz_enabled == false ||
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "BIOZ",
        value: "bioz",
      },
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Sleep",
        value: "sleep",
      },

  getLabelsInfoData().surveys_enabled == false ||
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Surveys",
        value: "surveys",
      },

  // window.location.href.indexOf("portal") > -1
  //   ? undefined
  //   :
  {
    label: "Activity",
    value: "activity",
  },
  {
    label: "RISK",
    value: "mpi",
  },
  // (getLabelsInfoData().health_battery_enabled == false ? undefined : {
  //     label: 'ER',
  //     value: 'health_battery'
  // }),
].filter((x) => x != undefined);

function ExportDiv(props) {
  const { onExport = () => {}, name = "Export", isDemo = false } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        marginBottom: 10,
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        onClick={() => {
          onExport();
        }}
      >
        <SquareForImg>
          <img className="ppg-icon" style={{ height: 22 }} src={export_img} />
        </SquareForImg>
        <span
          className="bp-info-span"
          style={{ color: "#147AFF", cursor: "pointer", fontWeight: "300" }}
        >
          {name}
        </span>
      </div>
    </div>
  );
}

function getTemperaturePoints(summary) {
  let pts = summary?.temperature?.slots || [];
  let isSk1 = pts.reduce(
    (res, pt) => (pt.temp_sk1 != undefined && pt.temp_sk1 != 0) || res,
    false
  );
  let newPoints = pts
    .map((x) => ({
      ...x,
      // temperature: isSk1 ? x.temp_sk1 : x.temp_sk2,
      temperature: x.temp_sk1,
    }))
    .filter((x) => x.temperature != undefined && +x.temperature > 25);
  return newPoints;
}

export default function PatientDayAnalyticsPanel(props) {
  const {
    user,
    hasSleepTab = true,
    isDemo = false,
    patientName,
    isEmbedMode = false,
    appAndDeviceInfo,
    id,
    date, // in format YYYY-MM-DD
  } = props;

  const [rrsDownloading, setRrsDownloading] = useState(false);

  const d = new Date();
  let diff = Math.round(d.getTimezoneOffset() / 60);

  let default_tab =
    window.location.href.indexOf("portal") > -1 ? "heart_rate" : "overview";
  // window.location.href.indexOf("portal") > -1 ? "heart_rate" : "blood_pressure";
  const [selectedTab, setSelectedTab] = useState(default_tab);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(undefined);
  const [aiSleepLoading, setAiSleepLoading] = useState(false);
  const [aiSleepLoading3, setAiSleepLoading3] = useState(false);
  const [aiSleepStages, setAiSleepStages] = useState(undefined);
  const [aiSleepStages2, setAiSleepStages2] = useState(undefined);
  const [aiSleepStages3, setAiSleepStages3] = useState(undefined);

  const [ntrainSleepStages, setNtrainSleepStages] = useState(undefined);
  const [ntrainId, setNtrainId] = useState(undefined);

  const [goals, setGoals] = useState(undefined);
  const [canShowPhilipsSpo2, setCanShowPhilipsSpo2] = useState(false);

  const [workouts, setWorkouts] = useState([]);

  const [exportPpg2Visible, setExportPpg2Visible] = useState(false);

  const [exportToolData, setExportToolData] = useState(undefined);
  const [newExportToolData, setNewExportToolData] = useState(undefined);

  const [debugVisible, setDebugVisible] = useState(false);
  const [baselineWindow, setBaselineWindow] = useState(15); // in minutes

  const [tz, setTz] = useState(moment().utcOffset() / 60);
  const [tempActivityPoints, setTempActivityPoints] = useState([]); // for Pulse rate & Spo2
  const [tempActivityPointsLoading, setTempActivityPointsLoading] =
    useState(false);

  const [activityPoints, setActivityPoints] = useState([]); // for BRPM
  const [activityPointsLoading, setActivityPointsLoading] = useState(false);

  const [tempPoints, setTempPoints] = useState([]); // for temperature
  const [tempPointsLoading, setTempPointsLoading] = useState(false);

  const [sessionMode, setSessionMode] = useState("current-day");
  const { unit } = useMappedState(
    useCallback((state) => {
      return {
        unit: state.ui.unit,
      };
    })
  );

  const theme = ls.get("theme");
  const displayFullDay = true;

  useEffect(() => {
    DoctorAPI.getPatientGoals(id, isDemo).then((d) => {
      setGoals(d);
    });
  }, [id]);

  useEffect(() => {
    if (date == undefined) {
      return;
    }

    setLoading(true);
    setSummary(undefined);
    DoctorAPI.getUserDaySummary(id, date, isDemo).then((pld) => {
      // console.log("got summary = ", pld);
      setLoading(false);

      setSummary(pld);
      setNtrainSleepStages(undefined);
      setNtrainId(undefined);

      if (
        !(
          pld == undefined ||
          pld.sleep == undefined ||
          pld.sleep.slots == undefined
        )
      ) {
        if (isDebugMode() == true || isSleepDebugMode() == true) {
          CalcAPI.getNTrainSleepStages3(id, date).then((d) => {
            setNtrainSleepStages(
              (d.points || []).map((x) => ({ ...x, sleepStage: x.value }))
            );
            setNtrainId(d.id);
            setAiSleepLoading(false);
          });
        }
      }
    });
    setWorkouts([]);
    DoctorAPI.getUserDayWorkouts(id, date).then((arr) => {
      // console.log("WORKOUTS: arr = ", arr);
      setWorkouts(arr);
    });
  }, [date]);

  useEffect(() => {
    if (date === undefined) {
      return;
    }
    let dataFrom = moment(date).startOf("day").subtract(1, "hour").valueOf();
    let dataTo = moment(date).endOf("day").add(tz, "hour").valueOf();
    let rrDataFrom = moment(date).startOf("day").subtract(1, "hour").valueOf();
    let rrDataTo = moment(date).endOf("day").subtract(1, "hour").valueOf();
    setTempActivityPointsLoading(true);
    setActivityPointsLoading(true);
    setTempPointsLoading(true);
    DoctorAPI.getTempActivityPoints(id, dataFrom, dataTo).then((d) => {
      if (!d || d.length === 0) {
        return;
      }

      let _tempActivityPoints = d;

      let _tzs = d.map((s) => s?.date).filter((s) => s);
      let currentZone = moment().utcOffset() / 60;
      if (_tzs.length === 0) {
        setTz(currentZone);
      } else {
        let _tz = _tzs[_tzs.length - 1];
        let zone = moment.parseZone(_tz).utcOffset() / 60;
        setTz(currentZone - zone);
      }
      // _tempActivityPoints = _tempActivityPoints.map((s) => {
      //   return {
      //     bpm: s.bpm_q > 0 ? s.bpm : null,
      //     bpm_q: s?.bpm_q,
      //     t: moment(s.date).valueOf(),
      //     slot: s.date,
      //     slot_with_tz: s.date,
      //     spo2: s.spo2_q > 0 ? s.spo2 : null,
      //     spo2_q: s?.spo2_q,
      //   };
      // });

      // setTempActivityPoints(_tempActivityPoints);
      // setTempActivityPointsLoading(false);
    });

    DoctorAPI.getActivityPoints(id, rrDataFrom, rrDataTo).then((d) => {
      if (!d || d.length === 0) {
        return;
      }

      let _ActivityPoints = d;
      console.log("let _ActivityPoints", d);

      _ActivityPoints = _ActivityPoints.map((s) => {
        return {
          ...s,
          t: moment(s.timestamp).valueOf(),
          slot: moment(s.timestamp).valueOf(),
          date: moment(s.timestamp).format("HH:mm:ss"),
          respiration_rate: s.resp_q > 0 ? s.respiration_rate : null,
          bpm: s.bpm_q > 0 ? s.bpm : null,
          spo2: s.spo2_q > 0 ? s.spo2 : null,
        };
      });

      setActivityPoints(_ActivityPoints);
      setActivityPointsLoading(false);
    });

    DoctorAPI.getAggregatedPoints(
      // DoctorAPI.getAggregatedPointsWithAbsoluteTime(
      id,
      "temperature",
      ["temp_sk1", "temp_sk2"],
      10,
      dataFrom,
      dataTo
    ).then((d) => {
      if (!d || d.length === 0) {
        return;
      }

      // console.log("DoctorAPI.getAggregatedPoints.temperature", d);
      let _Temperature = d;
      _Temperature = _Temperature.map((s) => {
        return {
          temp_sk1: s?.temp_sk1 ? s.temp_sk1.toFixed(1) : null,
          slot: s.slot,
          t: moment(s.slot).valueOf(),
        };
      });
      setTempPoints(_Temperature);
      setTempPointsLoading(false);
    });
  }, [date, id]);

  let seltT = +moment(date);

  let expUserId = user == undefined ? undefined : user.uuid;

  let newTempPoints = getTemperaturePoints(summary);

  // console.log("render: canShowPhilipsSpo2 = ", canShowPhilipsSpo2);

  return (
    <Wrapper
      className="statistics-tab-wrapper"
      style={{
        opacity: loading == true ? 0.5 : 1,
      }}
    >
      {hasTopTabs == false ? null : (
        <TopPlaceholder>
          <TabNamesPlaceholder>
            {TABS.filter((a) => isDemo == false || a.value != "hrv")
              .filter((a) => !(hasSleepTab == false && a.value == "sleep"))
              .map((a, i) => {
                let isSelected = a.value == selectedTab;
                return (
                  <TabNameItem
                    className="statistics-tab-name"
                    theme={theme}
                    key={i}
                    selected={isSelected}
                    onClick={() => {
                      setSelectedTab(a.value);
                    }}
                  >
                    {a.label}
                  </TabNameItem>
                );
              })}
          </TabNamesPlaceholder>
        </TopPlaceholder>
      )}

      {isAFMode == false ? null : (
        <AfNsrTool uuid={id} t={+moment(date).startOf("day")} />
      )}

      {hasTopTabs == false ? null : (
        <ContentPlaceholder
          key={`ssst_${selectedTab}`}
          className="patient-statistics-content"
        >
          {selectedTab != "overview" ? null : (
            <div>
              {summary == undefined || goals == undefined ? (
                <SimpleNoDataMessageTool
                  loading={loading}
                  message={"Sorry, there is no data this day."}
                />
              ) : (
                <OverviewTool
                  summary={summary}
                  goals={goals}
                  isDemo={isDemo}
                  // min={+moment(seltT).startOf("day")}
                  // max={+moment(seltT).endOf("day")}
                  loading={loading}
                  seltT={seltT}
                  workouts={workouts}
                  min={+moment(date).startOf("day")}
                  max={+moment(date).startOf("day").add(1, "day").valueOf()}
                />
              )}

              {/*{isCorsanoMode() ? null :*/}
              {/*    <UserWeeklyRecoveryPanel*/}
              {/*        uuid={id}*/}
              {/*        t={seltT}*/}
              {/*    />*/}
              {/*}*/}

              <div>
                <ComplianceHeading className="patient-statistics-heading">
                  Trends
                </ComplianceHeading>
                <TrendsSectionTool uuid={id} />
              </div>

              {isEmbedMode == true ? null : (
                <NotesPlaceholder>
                  <ComplianceHeading className="patient-statistics-heading">
                    Notes
                  </ComplianceHeading>
                  <PatientNotesPanel
                    id={id}
                    isDemo={isDemo}
                    dayTimestamp={seltT}
                  />
                </NotesPlaceholder>
              )}

              {isDebugMode() == false ? null : (
                <NotesPlaceholder>
                  {appAndDeviceInfo == undefined ? null : (
                    <div>
                      <div style={{ marginTop: 20 }} className="notes-app">
                        {`App: ${appAndDeviceInfo?.appInfo?.app_name} ${appAndDeviceInfo?.appInfo?.app_version} (${appAndDeviceInfo?.appInfo?.os_name})`}
                      </div>
                      <div
                        style={{ marginTop: 20, marginBottom: 20 }}
                        className="notes-app"
                      >
                        {`Bracelet: ${appAndDeviceInfo?.deviceInfo?.device_type} ${appAndDeviceInfo?.deviceInfo?.firmware_version} (${appAndDeviceInfo?.deviceInfo?.serial_number})`}
                      </div>
                    </div>
                  )}

                  <ComplianceHeading className="patient-statistics-heading">
                    App logs
                  </ComplianceHeading>
                  <AppLogsTool
                    uuid={id}
                    from={+moment(seltT).startOf("day")}
                    to={+moment(seltT).endOf("day")}
                  />
                </NotesPlaceholder>
              )}

              {getLabelsInfoData().sessions_enabled == false ||
              isEmbedMode == true ? null : (
                <>
                  <SessionsPlaceholder>
                    <ComplianceHeading className="patient-statistics-heading">
                      Sessions
                    </ComplianceHeading>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        marginLeft: 50,
                        fontSize: "14px",
                        alignItems: "center",
                        marginBottom: "30px",
                      }}
                    >
                      <strong className="normal-text">Mode:</strong>
                      <ModeSwitcherItem
                        className="normal-text"
                        selected={sessionMode == "all"}
                        onClick={() => {
                          setSessionMode("all");
                        }}
                      >
                        All
                      </ModeSwitcherItem>
                      <ModeSwitcherItem
                        selected={sessionMode == "current-day"}
                        className="normal-text"
                        onClick={() => {
                          setSessionMode("current-day");
                        }}
                      >
                        Current Day
                      </ModeSwitcherItem>
                    </div>
                  </SessionsPlaceholder>

                  <PatientSessionsPanel
                    id={id}
                    isDemo={isDemo}
                    theme={theme}
                    sessionMode={sessionMode}
                    dayTimestamp={seltT}
                  />
                </>
              )}

              {window.location.href.indexOf("portal") > -1 ||
              isEmbedMode == true ? null : (
                <div style={{ marginTop: 40 }}>
                  <span
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      opacity: 0.6,
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      CommonHelper.downloadFile(
                        `summary_${date}.json`,
                        JSON.stringify(summary)
                      );
                    }}
                  >
                    {`Export summary_${date}.json`}
                  </span>
                </div>
              )}
            </div>
          )}
          {selectedTab != "hrv" ? null : (
            <div key={`zz_${seltT}`}>
              <DayHrvAnalysisPanel
                dayTimestamp={seltT}
                uuid={id}
                theme={theme}
              />
            </div>
          )}

          {selectedTab != "af" ? null : (
            <div>
              <AfNsrTool uuid={id} t={+moment(date).startOf("day")} />
            </div>
          )}

          {selectedTab != "heart_rate" ? null : (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      marginTop: -12,
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <InfoToolBadge type={"HEART_RATE"} />
                  </div>
                  {/*<div>*/}
                  {/*    <ExportDiv*/}
                  {/*        name={'Export RR intervals'}*/}
                  {/*        onExport={() => {*/}
                  {/*            if (isDemo == true) {*/}
                  {/*                return window.alert(DEMO_MESSAGE);*/}
                  {/*            }*/}
                  {/*            // window.open(DoctorAPI.getRrsFromToExportCsvLink(id, +moment(date).startOf('day'), +moment(date).endOf('day')), '_blank');*/}
                  {/*            setExportToolData({*/}
                  {/*                types: ['rr'],*/}
                  {/*                from: +moment(date).startOf('day'),*/}
                  {/*                to: +moment(date).endOf('day'),*/}
                  {/*                users: [user],*/}
                  {/*                name: `rr_${moment(date).startOf('day').format('YYYY-MM-DD-HH-mm-ss')}_${moment(date).endOf('day').format('YYYY-MM-DD-HH-mm-ss')}_${expUserId}.zip`,*/}
                  {/*                expUserId: expUserId*/}
                  {/*            });*/}
                  {/*        }}/>*/}
                  {/*</div>*/}
                  {isDebugMode() == false ? null : (
                    <div style={{ marginTop: -12, marginLeft: 15 }}>
                      <ExportDiv
                        name={"Export PPG"}
                        onExport={() => {
                          if (isDemo == true) {
                            return window.alert(DEMO_MESSAGE);
                          }
                          setExportPpg2Visible(true);
                        }}
                      />
                    </div>
                  )}
                </div>

                {/*<div style={{marginLeft: 15}}>*/}
                {/*    <ExportDiv*/}
                {/*        name={'Export BIOZ'}*/}
                {/*        onExport={() => {*/}
                {/*            if (isDemo == true) {*/}
                {/*                return window.alert(DEMO_MESSAGE);*/}
                {/*            }*/}
                {/*            setExportBiozVisible(true);*/}
                {/*        }}/>*/}
                {/*</div>*/}

                {/*<div style={{marginTop: -12}}>*/}
                {/*    <PpgFromToExportTool*/}
                {/*        patientName={patientName}*/}
                {/*        uuid={id} from={+moment(date).startOf('day')} to={+moment(date).endOf('day')}/>*/}
                {/*</div>*/}
              </div>

              <div style={{ width: "100%", height: 300 }}>
                {summary == undefined ||
                summary?.heart_rate == undefined ||
                summary?.heart_rate.slots == undefined ? (
                  <SimpleNoDataMessageTool
                    loading={loading}
                    message={"Sorry, there is no heart rate data for this day."}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: 400,
                      marginBottom: "1rem",
                      top: "-40px",
                      position: "relative",
                    }}
                  >
                    <div>
                      <ComplianceHeading className="patient-statistics-heading">
                        Pulse Rate
                      </ComplianceHeading>
                    </div>
                    <PatientDayPulseRateBaselineTool
                      baselineWindow={baselineWindow}
                      tempActivityPoints={activityPoints}
                      loading={activityPointsLoading}
                      uuid={id}
                      date={date}
                      theme={theme}
                      hasNoData={
                        summary == undefined ||
                        summary?.heart_rate == undefined ||
                        summary?.heart_rate.slots == undefined
                      }
                    />
                  </div>
                )}
              </div>

              {isDebugMode() ? (
                <div style={{ width: "100%", height: 300, marginTop: "5rem" }}>
                  <SimpleBpmSlotsChart
                    // tz={tz}
                    slots={
                      summary?.heart_rate?.slots
                        ? summary?.heart_rate?.slots.map((x) => ({
                            ...x,
                            t: x.timestamp,
                          }))
                        : []
                    }
                    min={+moment(date).startOf("day")}
                    max={+moment(date).startOf("day").add(1, "day").valueOf()}
                  />
                </div>
              ) : null}

              {isDebugMode() == false ? null : (
                <>
                  <ActivityHeartRateChart
                    uuid={id}
                    dayTimestamp={+moment(date).startOf("day")}
                  />

                  <ComplianceHeading className="patient-statistics-heading">
                    Arrhythmias
                  </ComplianceHeading>
                  <AfibTool
                    uuid={id}
                    from={+moment(date).startOf("day")}
                    to={+moment(date).endOf("day")}
                  />

                  <ComplianceHeading className="patient-statistics-heading">
                    AF
                  </ComplianceHeading>

                  <HappitechChart
                    uuid={id}
                    from={+moment(date).startOf("day")}
                    to={+moment(date).endOf("day")}
                  />
                  <HappitechTool
                    uuid={id}
                    from={+moment(date).startOf("day")}
                    to={+moment(date).endOf("day")}
                  />
                </>
              )}

              <VivalinkItemsTool
                uuid={id}
                from={+moment(seltT).startOf("day")}
                to={+moment(seltT).endOf("day")}
              />

              <ComplianceHeading
                style={{ marginTop: "2rem" }}
                className="patient-statistics-heading"
              >
                ECG
              </ComplianceHeading>

              {/*<UserECGRecordsPanel*/}
              {/*    uuid={id}*/}
              {/*/>*/}

              {/*<div style={{width: '100%', height: 300, maxHeight: '300px', overflowY: 'auto'}}>*/}
              {/*    */}
              {/*</div>*/}

              <div
                style={{
                  width: "100%",
                  height: 300,
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                <BinaryMeasurementsPanel
                  uuid={id}
                  filterType={"ecg"}
                  showDescription={false}
                  isDemo={isDemo}
                  user={user}
                />

                {/*<SimpleNoDataMessageTool*/}
                {/*    loading={loading}*/}
                {/*    message={'Sorry, there is no ECG data for this day.'}/>*/}
              </div>
            </div>
          )}

          {selectedTab != "bioz" ? null : (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{ marginTop: -12, marginRight: 10, marginBottom: 10 }}
                >
                  <InfoToolBadge type={"EMOGRAPHY"} />
                </div>
              </div>

              <PatientEmographyPanel
                from={+moment(date).startOf("day")}
                to={+moment(date).endOf("day")}
                uuid={id}
              />

              {isDebugMode() == false &&
              window.location.href.indexOf("wiff") == -1 ? null : (
                <div>
                  <ContinuousMeasurementsPanel
                    type={"bioz"}
                    userId={id}
                    from={+moment(date).startOf("day")}
                    to={+moment(date).endOf("day")}
                  />
                </div>
              )}
            </div>
          )}

          {selectedTab != "activity" ? null : (
            <div>
              <div style={{ width: "100%" }}>
                {summary == undefined ||
                summary.activity == undefined ||
                summary.activity.slots == undefined ? (
                  <SimpleNoDataMessageTool
                    loading={loading}
                    message={"Sorry, there is no activity data for this day."}
                  />
                ) : (
                  <>
                    <div style={{ width: "100%", height: "300px" }}>
                      <SabirBarChart
                        shouldChangeColorOfSelected={false}
                        barColor={"#FF962A"}
                        items={TarnsformerHelper.get24HoursStepsArray(
                          summary.activity.slots
                        )}
                        renderItem={(it) => (
                          <span
                            style={{
                              fontWeight: "normal",
                              color: "grey",
                              fontSize: 10,
                            }}
                          >
                            {it.time}
                          </span>
                        )}
                      />
                    </div>

                    {isDebugMode() == false ? null : (
                      <div style={{ marginTop: 20 }}>
                        <TabHeading className="patient-statistics-heading">
                          Activity Temp
                        </TabHeading>

                        <TempStepsTool
                          uuid={id}
                          from={+moment(date).startOf("day")}
                          to={+moment(date).endOf("day")}
                        />
                      </div>
                    )}

                    {summary?.activity == undefined ? null : (
                      <div style={{ marginTop: 20 }}>
                        <TabHeading className="patient-statistics-heading">
                          Gait
                        </TabHeading>
                        <SummaryActivityGaitViewTool
                          {...(summary?.activity || {})}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>

              {isDebugMode() == false &&
              window.location.href.indexOf("wiff") == -1 ? null : (
                <ContinuousMeasurementsPanel
                  type={"acc"}
                  userId={id}
                  from={+moment(date).startOf("day")}
                  to={+moment(date).endOf("day")}
                />
              )}
            </div>
          )}

          {selectedTab != "sleep" ? null : (
            <div>
              <div style={{ width: "100%", height: 300 }}>
                {summary == undefined ||
                summary.sleep == undefined ||
                summary.sleep.slots == undefined ? (
                  <SimpleNoDataMessageTool
                    loading={loading}
                    message={"Sorry, there is no sleep data for this day."}
                  />
                ) : (
                  <SleepViewTool
                    date={date}
                    slots={summary.sleep.slots}
                    displayFullDay={displayFullDay}
                    dd={"philips"}
                    uuid={id}
                  />
                )}
              </div>

              {(isDebugMode() == false && isSleepDebugMode() == false) ||
              ntrainSleepStages == undefined ||
              summary == undefined ||
              summary.sleep == undefined ||
              summary.sleep.slots == undefined ? null : (
                <>
                  {/* <h2 className="heading">Night Train ({-diff} hrs)</h2> */}
                  <h2 className="heading">Night Train</h2>
                  <div style={{ width: "100%", height: 300, marginTop: 20 }}>
                    {aiSleepLoading == true ? (
                      "AI Sleep is loading..."
                    ) : (
                      <SleepViewTool
                        displayFullDay={displayFullDay}
                        date={date}
                        slots={DrawHelper.transformAiSleepStages2(
                          ntrainSleepStages
                        )}
                        uuid={id}
                        dd={"nt"}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer", marginTop: "1rem" }}
                      onClick={async () => {
                        setRrsDownloading(true);
                        let pld = await CalcAPI.getNTrainIo(id, date);
                        console.log("ntrain data = ", pld);
                        CommonHelper.downloadFile(
                          `ntrain_${date}.json`,
                          JSON.stringify(pld)
                        );
                        setRrsDownloading(false);
                      }}
                    >
                      {rrsDownloading == true
                        ? "loading..."
                        : "download NTrain input/Output data"}
                    </span>
                    {ntrainId == undefined ? null : (
                      <div
                        style={{
                          fontSize: "12px",
                          fontStyle: "italic",
                          marginTop: "1rem",
                        }}
                      >
                        {"NTrain ID: " + ntrainId}
                      </div>
                    )}
                  </div>
                </>
              )}
              {isDebugMode() == false ? null : (
                <>
                  <h2 className="heading">Binary Sleep</h2>
                  {/* <h2 className="heading">Binary Sleep ({-diff} hrs)</h2> */}
                  <div style={{ width: "100%", height: 200 }}>
                    <AiSleepViewTool date={date} uuid={id} />
                  </div>
                </>
              )}

              {isDebugMode() == false ||
              summary == undefined ||
              summary.sleep == undefined ||
              summary.sleep.slots == undefined ? null : (
                <>
                  <h2>Dmytro</h2>

                  <div style={{ width: "100%", height: 300, marginTop: 20 }}>
                    <AiSleepTool date={date} uuid={id} />
                  </div>

                  {/*<div style={{width: '100%', height: 300, marginTop: 20}}>*/}
                  {/*    {aiSleepLoading == true ? 'AI Sleep is loading...' :*/}
                  {/*        <SleepViewTool slots={DrawHelper.transformAiSleepStages2(aiSleepStages2)}/>*/}
                  {/*    }*/}
                  {/*</div>*/}
                  {/*<div style={{width: '100%', height: 300, marginTop: 20}}>*/}
                  {/*    {aiSleepLoading3 == true ? 'AI Sleep is loading...' :*/}
                  {/*        <SleepViewTool slots={DrawHelper.transformAiSleepStages2(aiSleepStages3)}/>*/}
                  {/*    }*/}
                  {/*</div>*/}
                </>
              )}

              {isDebugMode() == false ? null : (
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={async () => {
                      setRrsDownloading(true);
                      let pld = await DoctorAPI.getSleepRRIntervals(id, date);
                      console.log("sleep rr intervals = ", pld);
                      CommonHelper.downloadFile(
                        `rr_intervals_${date}.json`,
                        JSON.stringify(pld)
                      );
                      setRrsDownloading(false);
                    }}
                  >
                    {rrsDownloading == true
                      ? "loading..."
                      : "download RR-intervals data"}
                  </span>
                </div>
              )}
            </div>
          )}

          {selectedTab != "temperature" ? null : (
            <div>
              {/*<ExportDiv*/}
              {/*    name={'Export temperature'}*/}
              {/*    onExport={() => {*/}
              {/*        if (isDemo == true) {*/}
              {/*            return window.alert(DEMO_MESSAGE);*/}
              {/*        }*/}
              {/*        setExportToolData({*/}
              {/*            types: ['temperature'],*/}
              {/*            from: +moment(date).startOf('day'),*/}
              {/*            to: +moment(date).endOf('day'),*/}
              {/*            users: [user],*/}
              {/*            name: `temperature_${moment(date).startOf('day').format('YYYY-MM-DD-HH-mm-ss')}_${moment(date).endOf('day').format('YYYY-MM-DD-HH-mm-ss')}_${expUserId}.zip`,*/}
              {/*            expUserId: expUserId*/}
              {/*        });*/}
              {/*        // window.open(DoctorAPI.getTemperatureFromToExportCsvLink(id, +moment(date).startOf('day'), +moment(date).endOf('day')), '_blank');*/}
              {/*    }}/>*/}
              <div style={{ width: "100%" }}>
                {summary == undefined ||
                summary.temperature == undefined ||
                summary.temperature.slots == undefined ? (
                  <SimpleNoDataMessageTool
                    loading={loading}
                    message={
                      "Sorry, there is no temperature data for this day."
                    }
                  />
                ) : (
                  <div>
                    <TabHeading className="patient-statistics-heading">
                      {/*Core body temperature*/}
                      Temperature
                    </TabHeading>

                    <div
                      style={{
                        width: "100%",
                        height: 320,
                        marginBottom: 20,
                        position: "relative",
                      }}
                    >
                      {summary == undefined ||
                      summary.temperature == undefined ||
                      summary.temperature.slots == undefined ? (
                        <SimpleNoDataMessageTool
                          loading={loading}
                          message={
                            "Sorry, there is no temperature data for this day."
                          }
                        />
                      ) : (
                        <div style={{ height: "400px", position: "relative" }}>
                          <DimsPlaceholder className="celsius-char">
                            {unit === "F" ? "F" : "°C"}
                          </DimsPlaceholder>
                          {/* <ComplianceHeading className="patient-statistics-heading">
                            Temperature
                          </ComplianceHeading> */}
                          <PatientDayTemperatureBaselineTool
                            baselineWindow={baselineWindow}
                            uuid={id}
                            date={date}
                            theme={theme}
                            unit={unit}
                            // tz={tz}
                            tempPoints={tempPoints}
                            loading={tempPointsLoading}
                            hasNoData={
                              summary == undefined ||
                              summary.temperature == undefined ||
                              summary.temperature.slots == undefined
                            }
                          />
                        </div>
                      )}
                    </div>

                    {isDebugMode() == false ||
                    summary == undefined ||
                    summary.temperature == undefined ||
                    summary.temperature.slots == undefined ? null : (
                      <div
                        style={{
                          height: 300,
                          marginTop: "2rem",
                          position: "relative",
                        }}
                      >
                        <DimsPlaceholder className="celsius-char">
                          {unit === "F" ? "F" : "°C"}
                        </DimsPlaceholder>
                        <SimpleBpmSlotsChart
                          yDoman={[33, 42]}
                          hasTooltip={true}
                          tz={tz}
                          slots={newTempPoints
                            .map((x) => ({
                              ...x,
                              t: x.timestamp,
                            }))
                            .map((x) => ({
                              ...x,
                              temperature:
                                x.temperature == 0 || x.temperature == undefined
                                  ? null
                                  : (+x.temperature).toFixed(1),
                            }))}
                          dataKey={"temperature"}
                          min={+moment(date).startOf("day")}
                          max={
                            +moment(date).startOf("day").add(1, "day").valueOf()
                          }
                        />
                      </div>
                    )}

                    {isDemo == true || isDebugMode() == false ? null : (
                      <div style={{ marginTop: "1rem" }}>
                        <TabHeading className="patient-statistics-heading">
                          Temperature Deviation
                        </TabHeading>

                        <div
                          style={{
                            width: "100%",
                            height: 260,
                            position: "relative",
                          }}
                        >
                          <DimsPlaceholder>
                            {unit === "F" ? "F" : "°C"}
                          </DimsPlaceholder>
                          <SimpleTemperatureDeviationSlotsChart
                            {...summary.temperature}
                            slots={summary.temperature.slots.map((x) => ({
                              ...x,
                              t: x.timestamp,
                            }))}
                            dataKey={"temp_sk1"}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {selectedTab != "spo2" ? null : (
            <div>
              {/*<ExportDiv*/}
              {/*    name={'Export'}*/}
              {/*    onExport={() => {*/}
              {/*        if (isDemo == true) {*/}
              {/*            return window.alert(DEMO_MESSAGE);*/}
              {/*        }*/}
              {/*        let slots = summary.spo2.slots.map(x => ({...x, t: x.timestamp})).map(x => ({*/}
              {/*            ...x,*/}
              {/*            spo2: (x.spo2 == 0 ? null : x.spo2)*/}
              {/*        }));*/}
              {/*        let csv = `t;spo2\n${slots.map(xx => `${xx.t};${xx.spo2}`).join('\n')}`;*/}
              {/*        CommonHelper.downloadFile(`${date}_spo2.csv`, csv);*/}
              {/*        // window.open(DoctorAPI.getSpo2FromToExportCsvLink(id, +moment(date).startOf('day'), +moment(date).endOf('day')), '_blank');*/}
              {/*    }}/>*/}
              <div
                style={{
                  width: "100%",
                  // maxHeight: "800px",
                  height: isDebugMode() ? "700px" : "",
                }}
              >
                <div>
                  {summary === undefined ||
                  summary?.spo2 === undefined ||
                  summary?.spo2.slots === undefined ? (
                    <SimpleNoDataMessageTool
                      loading={loading}
                      message={"Sorry, there is no SpO2 data for this day."}
                    />
                  ) : (
                    <div style={{ height: 400 }}>
                      <ComplianceHeading className="patient-statistics-heading">
                        Spo2
                      </ComplianceHeading>
                      <PatientDaySpo2BaselineTool
                        uuid={id}
                        date={date}
                        theme={theme}
                        baselineWindow={baselineWindow}
                        loading={activityPointsLoading}
                        tempActivityPoints={activityPoints}
                        hasNoData={
                          summary == undefined ||
                          summary?.spo2 == undefined ||
                          summary?.spo2.slots == undefined
                        }
                      />
                    </div>
                  )}

                  {isDebugMode() ? (
                    <div style={{ height: 300, marginTop: "0rem" }}>
                      <SimpleBpmSlotsChart
                        yDoman={[60, 100]}
                        slots={
                          summary?.spo2?.slots
                            ? summary?.spo2?.slots
                                .map((x) => ({ ...x, t: x.timestamp }))
                                .map((x) => ({
                                  ...x,
                                  spo2: x.spo2 == 0 ? null : x.spo2,
                                }))
                            : []
                        }
                        dataKey={"spo2"}
                        min={+moment(date).startOf("day")}
                        max={
                          +moment(date).startOf("day").add(1, "day").valueOf()
                        }
                      />
                    </div>
                  ) : null}
                  {/*{isDebugMode() == false ? null :*/}
                  {/*    <PatientDaySpo2Tool*/}
                  {/*        dayTimestamp={+moment(date).startOf('day')}*/}
                  {/*        uuid={id}*/}
                  {/*        onStartLoading={() => {*/}
                  {/*            setCanShowPhilipsSpo2(false);*/}
                  {/*        }}*/}
                  {/*        onEndLoading={hasData => {*/}
                  {/*            console.log('!!! onEndLoading: hasData = ', hasData);*/}
                  {/*            setCanShowPhilipsSpo2(hasData == false || isDebug == true);*/}
                  {/*        }}*/}
                  {/*    />*/}
                  {/*}*/}

                  {/*{(summary == undefined || canShowPhilipsSpo2 == false || summary.spo2 == undefined || summary.spo2.slots == undefined) ? null :*/}
                </div>
              </div>
            </div>
          )}

          {selectedTab != "respiration" ? null : (
            <div style={{ height: isDebugMode() ? "1400px" : "400px" }}>
              <div style={{ width: "100%", height: 300 }}>
                {summary == undefined ||
                summary?.respiration_rate == undefined ||
                summary?.respiration_rate.slots == undefined ? (
                  <SimpleNoDataMessageTool
                    loading={loading}
                    message={
                      "Sorry, there is no respiration data for this day."
                    }
                  />
                ) : (
                  <div style={{ height: "400px" }}>
                    <ComplianceHeading className="patient-statistics-heading">
                      Respiration Rate
                    </ComplianceHeading>
                    <PatientDayBRPMBaselineTool
                      uuid={id}
                      date={date}
                      theme={theme}
                      baselineWindow={baselineWindow}
                      activityPoints={activityPoints}
                      loading={activityPointsLoading}
                      hasNoData={
                        summary == undefined ||
                        summary?.respiration_rate == undefined ||
                        summary?.respiration_rate.slots == undefined
                      }
                    />
                  </div>
                )}
              </div>

              {isDebugMode() ? (
                <>
                  {summary == undefined ||
                  summary?.respiration_rate == undefined ||
                  summary?.respiration_rate.slots == undefined ? null : (
                    <div style={{ height: 300, marginTop: "6rem" }}>
                      <SimpleBpmSlotsChart
                        slots={summary?.respiration_rate.slots
                          .map((x) => ({
                            ...x,
                            t: x.timestamp,
                          }))
                          .map((x) => ({
                            ...x,
                            respiration_rate:
                              x.respiration_rate == 0
                                ? null
                                : x.respiration_rate,
                          }))}
                        dataKey={"respiration_rate"}
                        min={+moment(date).startOf("day")}
                        max={
                          +moment(date).startOf("day").add(1, "day").valueOf()
                        }
                      />
                    </div>
                  )}
                </>
              ) : null}

              {isDebugMode() === false ? null : (
                <>
                  <div
                    style={{ width: "100%", height: 300, marginTop: "1rem" }}
                  >
                    <TempRespTool
                      downsampleMode={true}
                      uuid={id}
                      from={+moment(date).startOf("day")}
                      to={+moment(date).endOf("day")}
                      min={+moment(date).startOf("day")}
                      max={+moment(date).startOf("day").add(1, "day").valueOf()}
                    />
                  </div>
                </>
              )}

              {isDebugMode() == false ? null : (
                <>
                  <div style={{ width: "100%", height: 300 }}>
                    <TempRespTool
                      downsampleMode={true}
                      isActivityRaw={true}
                      uuid={id}
                      from={+moment(date).startOf("day")}
                      to={+moment(date).endOf("day")}
                      min={+moment(date).startOf("day")}
                      max={+moment(date).startOf("day").add(1, "day").valueOf()}
                    />
                  </div>
                </>
              )}

              {/*<div>*/}
              {/*    Philips*/}
              {/*</div>*/}

              {/*<ExportDiv*/}
              {/*    name={'Export respiration rate'}*/}
              {/*    onExport={() => {*/}
              {/*        if (isDemo == true) {*/}
              {/*            return window.alert(DEMO_MESSAGE);*/}
              {/*        }*/}
              {/*        setExportToolData({*/}
              {/*            types: ['respiration'],*/}
              {/*            from: +moment(date).startOf('day'),*/}
              {/*            to: +moment(date).endOf('day'),*/}
              {/*            users: [user],*/}
              {/*            name: `respiration_${moment(date).startOf('day').format('YYYY-MM-DD-HH-mm-ss')}_${moment(date).endOf('day').format('YYYY-MM-DD-HH-mm-ss')}_${expUserId}.zip`,*/}
              {/*            expUserId: expUserId*/}
              {/*        });*/}
              {/*        // window.open(DoctorAPI.getTemperatureFromToExportCsvLink(id, +moment(date).startOf('day'), +moment(date).endOf('day')), '_blank');*/}
              {/*    }}/>*/}

              {/*<div style={{width: '100%', height: 300}}>*/}
              {/*    <TempRespTool*/}
              {/*        downsampleMode={true}*/}
              {/*        uuid={id}*/}
              {/*        from={+moment(date).startOf('day')}*/}
              {/*        to={+moment(date).endOf('day')}/>*/}
              {/*</div>*/}

              {/*{isDebugMode() == false ? null :*/}
              {/*    */}
              {/*}*/}

              {/*<PatientDayRespirationTool*/}
              {/*    dayTimestamp={+moment(date).startOf('day')}*/}
              {/*    uuid={id}*/}
              {/*    onStartLoading={() => {*/}
              {/*        // setCanShowPhilipsSpo2(false);*/}
              {/*    }}*/}
              {/*    onEndLoading={hasData => {*/}
              {/*        console.log('!!! onEndLoading: hasData = ', hasData);*/}
              {/*        // setCanShowPhilipsSpo2(hasData == false || isDebug == true);*/}
              {/*    }}*/}
              {/*/>*/}
            </div>
          )}

          {selectedTab != "weight" ? null : (
            <div>
              <TabHeading className="patient-statistics-heading">
                Weight
              </TabHeading>

              <div style={{ width: "100%" }}>
                {/*<EmptyChartTool/>*/}
                <WeightTool uuid={id} />
              </div>
            </div>
          )}

          {selectedTab != "mpi" ? null : (
            <div>
              <TabHeading className="patient-statistics-heading">
                RISK
              </TabHeading>

              <div style={{ width: "100%" }}>
                {/*<EmptyChartTool/>*/}
                {/* <PatientMPITool uuid={id} t={+moment(date).endOf("day")} /> */}
                <PatientMPIToolBaseline
                  uuid={id}
                  t={+moment(date).endOf("day")}
                />
              </div>
            </div>
          )}

          {selectedTab != "raw" ? null : (
            <div>
              <div style={{ width: "100%" }}>
                <BinaryMeasurementsPanel uuid={id} isDemo={isDemo} />
              </div>
            </div>
          )}

          {selectedTab != "blood_pressure" ? null : (
            <div>
              {isDebugMode() ? (
                <PatientDayBpTool
                  dayTimestamp={+moment(date).startOf("day")}
                  uuid={id}
                  min={+moment(date).startOf("day")}
                  max={+moment(date).startOf("day").add(1, "day").valueOf()}
                />
              ) : (
                <PatientDayBasicBpTool
                  dayTimestamp={+moment(date).startOf("day")}
                  uuid={id}
                  min={+moment(date).startOf("day")}
                  max={+moment(date).startOf("day").add(1, "day").valueOf()}
                />
              )}

              <TabHeading className="patient-statistics-heading">
                Spot Blood Pressure Cuff Measurement
              </TabHeading>

              <DayBpAnalysisPanel
                dayTimestamp={seltT}
                uuid={id}
                isDebugMode={isDebugMode}
              />
            </div>
          )}

          {selectedTab != "spiro" ? null : (
            <div>
              <TabHeading className="patient-statistics-heading">
                Spirometry
              </TabHeading>

              <SpirometerTool uuid={id} />
            </div>
          )}

          {selectedTab != "health_battery" ? null : (
            <div>
              <TabHeading className="patient-statistics-heading">
                Energy reserve
              </TabHeading>

              <HealthBatteryWidgetTool uuid={id} date={date} />

              {/*<HealthBatteryTool*/}
              {/*    uuid={id}*/}
              {/*    from={+moment(date).startOf('day')}*/}
              {/*    to={+moment(date).endOf('day')}*/}
              {/*/>*/}
            </div>
          )}

          {selectedTab != "surveys" ? null : (
            <div>
              <div style={{ width: "100%" }}>
                {/*<PatientSurveysPanel id={id}/>*/}
                <NewSurveysPanel
                  uuid={id}
                  from={+moment(date).startOf("day")}
                  to={+moment(date).endOf("day")}
                />
              </div>
            </div>
          )}

          <Sidebar
            visible={exportPpg2Visible && debugVisible}
            width={Math.min(720, window.innerWidth)}
            onCloserClick={() => {
              setDebugVisible(false);
            }}
          >
            {exportPpg2Visible == false ? null : (
              <div>
                <ContinuousMeasurementsPanel
                  type={"ppg2"}
                  userId={id}
                  from={+moment(date).startOf("day")}
                  to={+moment(date).endOf("day")}
                  canExportMergedWiff={true}
                />
              </div>
            )}
          </Sidebar>

          {exportPpg2Visible == false ? null : (
            <NiceModal
              onClose={() => {
                setExportPpg2Visible(false);
              }}
            >
              <h3 style={{ paddingLeft: 5 }}>Export PPG</h3>
              <PpgTypesSelector
                onExport={(types) => {
                  console.log("onExport: types = ", types);

                  setExportToolData({
                    types: types,
                    from: +moment(date).startOf("day"),
                    to: +moment(date).endOf("day"),
                    users: [user],
                    name: `${types.join("_")}_${moment(date)
                      .startOf("day")
                      .format("YYYY-MM-DD-HH-mm-ss")}_${moment(date)
                      .endOf("day")
                      .format("YYYY-MM-DD-HH-mm-ss")}_${expUserId}.zip`,
                    expUserId: expUserId,
                  });

                  // let from = +moment(date).startOf('day');
                  // let to = +moment(date).endOf('day');
                  // let userId = id;
                  // let type = 'ppg2';
                  // let url = BinaryMeasurementsAPI.getInstance().getContinuousMeasurementsPPG2FileUrl(userId, type, from, to, types);
                  // console.log('--->>> url = ', url);
                  // window.open(url, '_blank').focus();
                }}
              />

              <div style={{ marginTop: 20, opacity: 0.5, textAlign: "center" }}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setDebugVisible(true);
                  }}
                >
                  show debug info
                </span>
              </div>
            </NiceModal>
          )}

          {/*<Sidebar visible={exportBiozVisible} onCloserClick={() => {*/}
          {/*    setExportBiozVisible(false);*/}
          {/*}}>*/}
          {/*    {exportBiozVisible == false ? null :*/}
          {/*        <div>*/}
          {/*            <ContinuousMeasurementsPanel*/}
          {/*                type={'bioz'}*/}
          {/*                userId={id}*/}
          {/*                from={+moment(date).startOf('day')}*/}
          {/*                to={+moment(date).endOf('day')}*/}
          {/*            />*/}
          {/*        </div>*/}
          {/*    }*/}
          {/*</Sidebar>*/}
        </ContentPlaceholder>
      )}

      {exportToolData == undefined ? null : (
        <NiceModal
          onClose={() => {
            setExportToolData(undefined);
          }}
        >
          <LogsInnerPlaceholder>
            <ExportServiceTool {...exportToolData} />
          </LogsInnerPlaceholder>
        </NiceModal>
      )}

      {newExportToolData == undefined ? null : (
        <NiceModal
          onClose={() => {
            setNewExportToolData(undefined);
          }}
        >
          <LogsInnerPlaceholder>
            <NewExportServiceTool {...newExportToolData} />
          </LogsInnerPlaceholder>
        </NiceModal>
      )}
    </Wrapper>
  );
}

const ComplianceHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 30px;
`;

const DimsPlaceholder = styled.div`
  position: absolute;
  top: -5px;
  left: 0px;
  opacity: 0.7;
  font-size: 12px;
`;

const LogsInnerPlaceholder = styled.div`
  width: 560px;
  box-sizing: border-box;
`;

const NotesPlaceholder = styled.div`
  margin-top: 40px;
`;

const SessionsPlaceholder = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const TabHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 30px;
`;

const ContentPlaceholder = styled.div`
  padding: 20px;
  background: white;
  border: 2px solid rgba(135, 152, 173, 0.1);
`;

const Wrapper = styled.div``;

const TabNamesPlaceholder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TabNameItem = styled.div`
  flex: 1;
  text-align: center;
  color: ${(props) =>
    props.selected == true
      ? props.theme === "dark"
        ? "black"
        : "white"
      : props.theme === "dark"
      ? "white"
      : "gray"};
  background-color: ${(props) =>
    props.selected == true
      ? props.theme === "dark"
        ? "#f3f3f3"
        : "#000F4B;"
      : "transparent"};
  cursor: ${(props) => (props.selected == true ? "default" : "pointer")};
  font-weight: ${(props) => (props.selected == true ? "bold" : "bold")};

  font-size: 18px;
  line-height: 18px;

  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    font-size: 14px;
  }

  @media (max-width: 720px) {
    font-size: 12px;
  }
`;

const SquareForImg = styled.div`
  border: 1px solid rgba(135, 152, 173, 0.1);
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
  border-radius: 8px;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  margin-right: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TopPlaceholder = styled.div``;

const ModeSwitcherItem = styled.div`
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  cursor: ${(props) => (props.selected ? "default" : "pointer")};
  text-decoration: ${(props) => (props.selected ? "underline" : "none")};
  margin-left: 5px;
  margin-right: 5px;
`;
