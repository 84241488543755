import axios from 'axios'
import env from "react-dotenv";
import ls from 'local-storage'

const {DOCTORS_API_ENDPOINT = 'https://api.study-integration.corsano.com'} = env;

const BraceletsAPI = {

    async getDoctorBracelets(uuid) {
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/doctors/${uuid}/bracelets`,)).data;
        return pld;
    },

    async getDoctorBraceletsOnlineMap(uuid) {
        let pld = (await axios.get(`${DOCTORS_API_ENDPOINT}/doctors/${uuid}/bracelets-seen-map`)).data;
        return pld;
    },

    async updateBracelet(doctorUUID, data, userCode) {
        console.log('updateBracelet: doctorUUID, data, userCode = ', doctorUUID, data, userCode);
        let pld = (await axios.put(`${DOCTORS_API_ENDPOINT}/doctors/${doctorUUID}/bracelets/${data.id}`, {
            ...data
        }, {
            params: {
                userCode: userCode
            }
        })).data;
        return pld;
    },

    async createBracelet(doctorUUID, data) {
        let pld = (await axios.post(`${DOCTORS_API_ENDPOINT}/doctors/${doctorUUID}/bracelets`, {
            ...data
        })).data;
        return pld;
    },


    async deleteBracelet(doctorUUID, id) {
        await axios.delete(`${DOCTORS_API_ENDPOINT}/doctors/${doctorUUID}/bracelets/${id}`);
    }

}

export default BraceletsAPI;
