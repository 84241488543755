import moment from "moment";
import styled from "styled-components";
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function generatePoints() {
  let arr = Array.from({ length: 24 }).map(
    (a, i) => +moment().startOf("day") + i * 3600000
  );
  return arr.map((x) => ({ t: x }));
}

export default function EmptyChartTool(props) {
  // Legacy
  let data = generatePoints();

  return (
    <Wrapper>
      <ChartPlaceholder>
        <ResponsiveContainer height={320}>
          <ComposedChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="t"
              tickFormatter={(a) => {
                return moment(a).format("HH:mm");
              }}
            />
            <Tooltip labelFormatter={(t) => moment(t).format("HH:mm")} />
            <YAxis />
            <Legend />
            <Line type="weight" dataKey="weight" stroke="#ff7300" />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartPlaceholder>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

  .sidebar__inner {
    background: white;
  }
`;

const ChartPlaceholder = styled.div`
  height: 320px;
  width: 100%;
`;

function download(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
